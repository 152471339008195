import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { withStyles, createStyles, WithStyles, ButtonBase, CircularProgress } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

import { useAppDispatch, useAppSelector } from 'store';
import { withConfig, Theme } from 'config/';

import KreiseSubscriber from './KreiseSubscriber';
import { getKreiseSubscribers, clearKreiseSubscribers } from 'store/reducers/kreise';
import { useLanguage } from 'languages/languageContext';

const styles = createStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(97, 97, 97, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 15,
    overflowX: 'hidden', //horizontal
    overflowY: 'hidden', //horizontal
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
  },
  modalWrapper: {
    position: 'relative',
    width: 520,
    paddingBottom: 10,
    borderRadius: 10,
    zIndex: 20,
    overflowX: 'hidden', //horizontal
    overflowY: 'hidden', //horizontal
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
    '@media(max-width: 650px)': {
      width: '90%',
    },
  },
  descriptionWrapper: {
    padding: '10px 20px',
  },
  desc: {
    fontSize: 16,
    fontFamily: 'Roboto',
    lineHeight: '18px',
  },
  heading: {
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    position: 'relative',
    padding: '20px 0 20px 0',
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'Roboto',
    margin: 0,
    letterSpacing: '0.1',
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  scrollWrapper: {
    height: 400,
    width: '100%',
    overflow: 'scroll',
    // paddingTop: 20
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 5,
    outline: 'none',
    border: 'none',
  },
  progressWrapper: {
    width: '100%',
    padding: '100px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  modalHandler: () => void;
  subscribersSum: number;
  kreiseName: string;
  showModal: boolean;
  kreiseDesc: string;
}

const KreiseInfoModal: React.FC<Props> = ({
  classes,
  theme,
  modalHandler,
  showModal,
  subscribersSum,
  kreiseName,
  kreiseDesc,
}) => {
  const dispatch = useAppDispatch();

  const { kreisePostScreen } = useLanguage();

  const { kreiseSubscribers, kreiseSubscribersAmount, kreiseSubscribersIsLoading } = useAppSelector(
    (state) => state.kreise,
  );

  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    dispatch(getKreiseSubscribers({ kreiseName, page: 1, limit: 10 }));
  }, [showModal]);

  const getSubscribersHandler = () => {
    if (!kreiseSubscribersIsLoading) {
      const nextPage = pageNumber + 1;
      setPageNumber(nextPage);

      if (pageNumber > 1 && !kreiseSubscribersIsLoading) {
        dispatch(getKreiseSubscribers({ kreiseName, page: pageNumber, limit: 10 }));
      }
    }
  };

  const onClose = () => {
    dispatch(clearKreiseSubscribers());
    modalHandler();
  };

  return (
    <div className={classes.container}>
      <div className={classes.modalWrapper} style={{ background: theme.BACKGROUND }}>
        <div className={classes.heading} style={{ background: theme.BACKGROUND_SECONDARY }}>
          <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
            {kreisePostScreen.infoParticipants(subscribersSum)}
          </h3>
          <div className={classes.buttonWrapper}>
            <ButtonBase onClick={onClose} style={{ borderRadius: 50 }}>
              <Close style={{ color: theme.BUTTON_SECONDARY }} />
            </ButtonBase>
          </div>
        </div>
        <div className={classes.scrollWrapper} style={{ background: theme.BACKGROUND }}>
          {kreiseSubscribers.length >= kreiseSubscribersAmount && kreiseSubscribersIsLoading ? (
            <div className={classes.progressWrapper}>
              <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={35} />
            </div>
          ) : (
            <InfiniteScroll
              threshold={50}
              initialLoad={false}
              pageStart={0}
              loadMore={getSubscribersHandler}
              hasMore={!(kreiseSubscribers.length >= kreiseSubscribersAmount)}
              loader={
                <div style={{ textAlign: 'center' }} key={50}>
                  <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
                </div>
              }
              useWindow={false}
            >
              <div className={classes.descriptionWrapper}>
                <p className={classes.desc} style={{ color: theme.TEXT_SECONDARY }}>
                  {kreiseDesc}
                </p>
              </div>
              {kreiseSubscribers &&
                kreiseSubscribers.length > 0 &&
                kreiseSubscribers.map((subscriber: any, i: number) => (
                  <KreiseSubscriber key={subscriber._id} subscriber={subscriber.id} />
                ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
      <div className={classes.backdrop} onClick={onClose}></div>
    </div>
  );
};

export default withStyles(styles)(withConfig(KreiseInfoModal));
