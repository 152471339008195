import { Box } from '@material-ui/core';
import { MultipleSelectModal } from 'components/Shared/MultipleSelectModal';
import SelectModal from 'components/Shared/SelectModal';
import Button from 'components/UI/Button';
import React, { FC, useEffect, useState } from 'react';
import { retry } from 'redux-saga/effects';
import { mentoringFindMentor, mentoringGetData } from '../../api/mentoring';
import EmptyList from '../../components/Shared/EmptyList';
import { getConfig } from '../../config/config';
import { useLanguage } from '../../languages/languageContext';
import { useAppSelector } from '../../store';
import { SelectMentoringState } from '../../store/reducers/mentoring';
import { EmptyProfile } from './components/EmptyProfile';
import { MentorBranches } from './components/MentorBranches';
import { MentorSearchItem } from './components/MentorSearchItem';
import { selectCommonState } from 'store/reducers/common';

type modalType = 'isRegionsModalOpen' | 'isDepartmentModalOpen' | 'isTopicsModalOpen';
export type itemTypes = 'mentoringRegions' | 'topics' | 'departments';

export const MentoringSearchScreen: FC = () => {
  const { isMentor = false } = useAppSelector((state) => state.users.user);
  const {
    mentoring: { profile, search },
    mentoringScreen,
  } = useLanguage();

  const [searchKeys, setSearchKeys] = useState({
    mentoringRegions: [],
    topics: [],
    departments: [],
  });

  const [modalState, setModalState] = useState({
    isRegionsModalOpen: false,
    isTopicsModalOpen: false,
    isDepartmentModalOpen: false,
  });

  const [data, setData] = useState({
    list: [],
    pageNumber: 1,
    isLoaded: false,
  });

  const {
    topics: topicsData,
    departments: departmentsData,
    mentoringRegions: regionsData,
  } = useAppSelector(SelectMentoringState).data;

  const { show_all_mentors } = useAppSelector(selectCommonState);

  useEffect(() => {
    setData({
      list: [],
      pageNumber: 1,
      isLoaded: false,
    });
  }, [searchKeys]);

  useEffect(() => {
    if (!searchKeys.departments.length && !searchKeys.topics.length && !searchKeys.mentoringRegions.length)
      searchAllMentors();
  }, [searchKeys]);

  const deleteItemHandler = (value: string, type: itemTypes) => {
    let newItem;
    if (value === mentoringScreen.all) newItem = [];
    if (value !== mentoringScreen.all) newItem = searchKeys[type].filter((item) => item !== value);
    setSearchKeys({ ...searchKeys, [type]: newItem });
  };
  function modalHandler(modal: modalType) {
    setModalState({ ...modalState, [modal]: !modalState[modal] });
  }
  const modalSelectItemHandler = (value: string, type: itemTypes) => {
    let newItem = [] as string[];
    if (value === mentoringScreen.all) {
      if (type === 'departments') newItem = departmentsData;
      if (type === 'topics') newItem = topicsData;
      if (type === 'mentoringRegions') newItem = regionsData;
    }
    if (value !== mentoringScreen.all) newItem = [...searchKeys[type], value];
    setSearchKeys({ ...searchKeys, [type]: newItem });
  };

  async function searchMentors() {
    const res: any = await mentoringFindMentor(searchKeys);
    setData(res);
  }

  async function searchAllMentors() {
    if (!show_all_mentors) return;
    const payload = {
      mentoringRegions: regionsData,
      topics: topicsData,
      departments: departmentsData,
    };
    const res: any = await mentoringFindMentor(payload);
    setData(res);
  }

  const EmptyListComponent = () => {
    // @ts-ignore
    if (data.total === 0) return <EmptyList />;
    return <></>;
  };

  return (
    <>
      <EmptyProfile>
        <>
          <Box style={styles.container}>
            <span style={{ ...styles.descriptionText, fontWeight: 'bolder' }}>{search.description}</span>
            <Box style={{ height: 50 }} />
            <MentorBranches
              type="mentoringRegions"
              deleteItemHandler={deleteItemHandler}
              state={searchKeys}
              modalHandler={modalHandler}
              isMentor={isMentor}
            />
            {config.KAS_MENTORING && (
              <MentorBranches
                type="topics"
                deleteItemHandler={deleteItemHandler}
                state={searchKeys}
                modalHandler={modalHandler}
                isMentor={isMentor}
              />
            )}
            <MentorBranches
              type="departments"
              deleteItemHandler={deleteItemHandler}
              state={searchKeys}
              modalHandler={modalHandler}
              isMentor={isMentor}
            />

            <Button
              label={search.searchBtn}
              onClick={searchMentors}
              disabled={searchKeys.mentoringRegions.length && (config.KAS_MENTORING ? searchKeys.topics.length : true)}
            />
            <Box style={{ marginTop: 30, flex: 1, display: 'flex', flexDirection: 'column' }}>
              {data.list?.length ? (
                data?.list.map((item) => <MentorSearchItem user={item} searchEntries={searchKeys} />)
              ) : (
                <EmptyListComponent />
              )}
            </Box>
          </Box>
          <MultipleSelectModal
            showModal={modalState.isRegionsModalOpen}
            modalTitle={profile.mentoringRegions}
            modalHandler={() => modalHandler('isRegionsModalOpen')}
            selectItemHandler={(value: string) => modalSelectItemHandler(value, 'mentoringRegions')}
            deleteItemHandler={(value: string) => deleteItemHandler(value, 'mentoringRegions')}
            data={regionsData}
            values={searchKeys.mentoringRegions}
            disabled={searchKeys.mentoringRegions.length >= 1}
          />
          <MultipleSelectModal
            showModal={modalState.isTopicsModalOpen}
            modalTitle={profile.topics}
            modalHandler={() => modalHandler('isTopicsModalOpen')}
            selectItemHandler={(value: string) => modalSelectItemHandler(value, 'topics')}
            deleteItemHandler={(value: string) => deleteItemHandler(value, 'topics')}
            data={topicsData}
            values={searchKeys.topics}
            disabled={searchKeys.topics.length >= 3}
          />
          <MultipleSelectModal
            showModal={modalState.isDepartmentModalOpen}
            modalTitle={profile.departments}
            modalHandler={() => modalHandler('isDepartmentModalOpen')}
            selectItemHandler={(value: string) => modalSelectItemHandler(value, 'departments')}
            deleteItemHandler={(value: string) => deleteItemHandler(value, 'departments')}
            data={[mentoringScreen.all, ...departmentsData]}
            values={searchKeys.departments}
          />
        </>
      </EmptyProfile>
    </>
  );
};

const { theme, config } = getConfig();
const styles = {
  container: {
    flex: 1,
    marginHorizontal: 2,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: theme.BACKGROUND,
    padding: 15,
    paddingBottom: 100,
  },
  descriptionText: {
    color: theme.TEXT_PRIMARY,
    fontSize: 14,
    marginBottom: 50,
    // lineHeight: 20,
  },
  switchContainer: {
    flexDirection: 'row',
    marginVertical: 15,
  },
  switchText: {
    marginLeft: 12,
  },
  inputContainer: {
    marginBottom: 20,
  },
  inputWrapper: {
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
  },
  deleteWrapper: {
    backgroundColor: theme.BACKGROUND_PRIMARY,
    borderColor: theme.BACKGROUND_SECONDARY,
    borderWidth: 1,
    padding: 8,
    borderRadius: 20,
  },
  itemWrapper: {
    backgroundColor: theme.BACKGROUND_PRIMARY,
    borderRadius: 20,
    borderColor: theme.BACKGROUND_SECONDARY,
    borderWidth: 1,
    margin: 5,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  itemContainer: { flexDirection: 'row', alignItems: 'center', paddingHorizontal: 5 },
};
