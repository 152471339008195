import { CorpType } from 'store/types/newsTypes';
import { API } from '../constants/';
import { client } from './client';
import { handleResponse, authHeader, handleConnectionError } from './core';

// GET CORPS NAMES
export const getCorpsNames = (name: string | undefined, guest?: boolean) => {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const nameParam = name ? `&name=${name}` : '';
  // return client.get(`/corps-names?guest=${guest}${nameParam}`);
  return fetch(`${API}/corps-names?guest=${guest}${nameParam}`, options)
    .then(handleResponse)
    .then((res) => res)
    .catch(handleConnectionError);
};

// GET CORPS SUBSCRIPTIONS
export const getCorpsSubs = () => {
  const options: any = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${API}/corps`, options)
    .then((res) => res.text())
    .then((res) => JSON.parse(res));
};

export const getCorpsToEnter = (): Promise<{ _id: string; corpsname: string; functionSet: {title: string; functions: string[]; _id: string}[] }[]> => client.get('/request-corps');

export const getCorps = (id: string): Promise<CorpType> => client.get(`/corps/${id}`);

export const editCorps = (id: string, payload: Partial<CorpType>): Promise<CorpType> =>
  client.patch(`/corps/${id}`, payload);
