import * as React from 'react';
import { CalendarIcon } from '@radix-ui/react-icons';
import { format } from 'date-fns';
import { isMoment, Moment } from 'moment';

import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { Button } from './button';
import { cn } from 'utilities/utils';
import { Calendar } from './calendar';
import { Label } from './label';
import { useLanguage } from 'languages/languageContext';
import { locales } from 'constants/date-fns-locales';

type Props = {
  name: string;
  placeholder?: string;
  date: Date | Moment;
  setDate: (c: Date) => any;
  touched?: boolean;
  error?: string;
  fromYear?: number;
  toYear?: number;
};

export const NewDatePicker: React.FC<Props> = ({
  placeholder,
  name,
  date,
  setDate,
  touched,
  error,
  fromYear = 1940,
  toYear = new Date().getFullYear(),
}) => {
  const { datePicker, key2 } = useLanguage();

  const locale = locales[key2] || locales.de;

  const normalizedDate = isMoment(date) ? date.toDate() : date;

  return (
    <Popover>
      <Label className="ml-3" htmlFor={name}>
        {placeholder}
      </Label>
      <PopoverTrigger asChild>
        <Button id={name} variant={'input'} size={'input'}>
          <CalendarIcon className="mr-2 h-4 w-4" />
          {normalizedDate ? (
            format(normalizedDate, 'dd. MMMM yyyy', { locale })
          ) : (
            <span className={cn(normalizedDate ? '' : 'text-muted-foreground')}>{datePicker.selectDateLabel}</span>
          )}
        </Button>
      </PopoverTrigger>
      <div className={cn('min-h-4 w-full ml-3 text-left text-xs text-red-500')}>{error}</div>
      <PopoverContent align="start" className="w-auto p-0">
        <Calendar
          mode="single"
          captionLayout="dropdown-buttons"
          selected={normalizedDate}
          onSelect={setDate}
          fromYear={fromYear}
          toYear={toYear}
          locale={locale}
        />
      </PopoverContent>
    </Popover>
  );
};
