export const extractHrefAttributes = (htmlString: string): string[] => {
  try {
    if (typeof htmlString !== 'string') {
      throw new Error('Invalid input: htmlString must be a string');
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    if (!doc) {
      throw new Error('Failed to parse HTML');
    }

    const links = doc.querySelectorAll('[href]');
    return Array.from(links)
      .map((link) => link.getAttribute('href'))
      .filter((href): href is string => href !== null);
  } catch (error) {
    console.error(`extractHrefAttributes error: ${error.message}`);
    return [];
  }
};
