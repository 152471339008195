import { Input } from 'components/shadcn/input';
import { Label } from 'components/shadcn/label';
import { FC, ComponentPropsWithoutRef, useState, useEffect } from 'react';
import { cn } from 'utilities/utils';
import { IonIcon } from './IonIcon';
import { Textarea } from 'components/shadcn/textArea';
import { ErrorLabel } from './ErrorLabel';
import { FormikTouched } from 'formik';
import { TunverifiedField } from 'store/types/usersTypes';
import { useLanguage } from 'languages/languageContext';
import { hideConfirmation } from 'api/users';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  touched?: FormikTouched<any> | boolean;
  inputType?: 'input';
};
type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  error?: string;
  touched?: FormikTouched<any> | boolean;
  inputType?: 'textarea';
};

type Props = InputProps | TextareaProps;

export const FormInput: FC<
  Props & {
    unverifiedFields?: TunverifiedField;
  }
> = ({
  inputType = 'input',
  placeholder,
  name,
  value,
  error,
  touched,
  onBlur,
  onChange,
  unverifiedFields,
  ...rest
}) => {
  const { profileDetails, formInputComponent } = useLanguage();
  //@ts-ignore
  const [showPass, setShowPass] = useState<ComponentPropsWithoutRef<'input'>['type']>(rest?.type);

  const [hiddenInfo, setHiddenInfo] = useState(unverifiedFields?.hidden ?? true);

  const togglePasswordVisibility = () => {
    showPass === 'password' ? setShowPass('text') : setShowPass('password');
  };

  useEffect(() => {
    setHiddenInfo(unverifiedFields?.hidden ?? true);
  }, [unverifiedFields?.hidden]);

  return (
    <>
      <Label className="ml-3 mb-1" htmlFor={name}>
        {placeholder}
      </Label>
      <div className={cn('h-10 w-full', inputType === 'textarea' && 'h-auto')}>
        {inputType === 'input' && (
          <Input
            name={name}
            placeholder={placeholder}
            id={name}
            value={value}
            //@ts-ignore
            onChange={onChange}
            //@ts-ignore
            onBlur={onBlur}
            {...rest}
            // @ts-ignore
            onKeyDown={(e) => {
              // @ts-ignore
              rest?.onKeyDown?.(e);
              if (name === 'email' || name === 'password' || name === 'emailRepeat')
                if (e.key === ' ' || e.key === 'Space') e.preventDefault();
            }}
            // @ts-ignore
            type={showPass || rest.type}
          />
        )}
        {inputType === 'textarea' && (
          <Textarea
            name={name}
            placeholder={placeholder}
            id={name}
            value={value}
            //@ts-ignore
            onChange={onChange}
            //@ts-ignore
            onBlur={onBlur}
            {...rest}
          />
        )}
        {/* @ts-ignore */}
        {rest?.type === 'password' && (
          <button onClick={togglePasswordVisibility} className="relative -top-[28px] left-[90%]">
            <IonIcon name={showPass === 'password' ? 'eye-off' : 'eye'} size={18} />
          </button>
        )}
      </div>
      {!hiddenInfo && (
        <>
          <div className="flex flex-row justify-between relative mt-[1px] w-full bg-gray-200 rounded-xl rounded-tr-none rounded-tl-none">
            <div className="h-6 -top-5 w-full bg-gray-200 absolute -z-10" />
            <span className="pb-1 px-3 text-txt-secondary text-xs">{profileDetails.inReview}</span>
            <button
              className="pb-1 px-3 text-txt-secondary text-xs text-end underline cursor-pointer duration-75 hover:scale-[1.01]"
              onClick={async () => {
                const res = await hideConfirmation(unverifiedFields.field);

                if (res === 'OK') setHiddenInfo(true);
              }}
            >
              {formInputComponent.cancelConfirmation}
            </button>
          </div>
        </>
      )}
      <ErrorLabel>{touched && error}</ErrorLabel>
    </>
  );
};
