import React, { useState, useEffect, memo } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { WithStyles, withStyles, createStyles } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';

import { useAppDispatch, useAppSelector } from 'store';
import { withConfig, withAuth, Theme } from 'config/';
import PostsToolbar from 'components/Shared/PostsToolbar';
import Layout from 'components/UI/Layout';

import Post from '../Post/';
import KreiseHeader from './components/KreiseHeader';
import EmptyList from 'components/Shared/EmptyList';
import KreiseInfoModal from './components/KreiseInfoModal';
import Button from 'components/UI/Button';
import DefaultModal from 'components/Shared/DefaultModal';
import { useLanguage } from 'languages/languageContext';
import { getKreiseById, kreise, setPreviousKriese, subscribeKreise } from 'store/reducers/kreise';
import { clearKreiseNews, getNewsListById, getPinnedPosts, setKreiseNewsPageNumber } from 'store/reducers/news';
import { ScrollToTopButton } from 'components/UI/ScrollToTop/ScrollToTopButton';
import { LockedPostToolbar } from 'components/Shared/LockedPostToolbar';
import { CheckboxUI } from 'components/UI/CheckboxUI';

const styles = createStyles({
  container: {
    width: '100%',
    overflow: 'hidden',
    marginBottom: 40,
  },
  progressWrapper: {
    width: '100%',
    padding: '100px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    right: 0,
    // padding: '30px 0',
    width: '50%',
    zIndex: 20,
    // marginLeft: 30
  },
  header: {
    fontFamily: 'Poppins',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  params?: { kreiseId?: string; mentoring?: boolean };
}

const KreisePosts: React.FC<Props> = (props) => {
  const { circles, btn, kreisePostScreen } = useLanguage();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.users);
  const { kreiseNews, newsIsLoading, kreiseNewsCount, kreiseNewsPageNumber, pinnedKreiseNewsFetched } = useAppSelector(
    (state) => state.news,
  );
  const { currentKreise, currentKreiseIsLoading, previousKreise } = useAppSelector((state) => state.kreise);
  const [showModal, setShowModal] = useState(false);
  const [showKreiseInfo, setShowKreiseInfo] = useState(false);
  const [isFistSubscribe, setIsFirstSubscribe] = useState(false);
  const [askUserToSub, setAskUserToSub] = useState(false);
  const { classes, theme, history, params } = props;

  const { kreiseId } = useParams<{ kreiseId?: string }>();

  const isAdmin = currentKreise?.admins.find((adm) => adm._id === user._id);
  const isSubscribedOnCorps = user?.corp?.some((corp) => corp.corpsname === currentKreise?.corpsname) || false;

  useEffect(() => {
    const kreiseIdArr = JSON.parse(localStorage.getItem('kreiseId'));

    if (
      currentKreise &&
      currentKreise.id === kreiseId &&
      !kreiseIdArr?.includes(kreiseId) &&
      !currentKreise?.is_subscribed
    ) {
      setIsFirstSubscribe(true);
    }
  }, [currentKreise]);

  const getPostsHandler = async () => {
    if (!newsIsLoading) {
      if (!pinnedKreiseNewsFetched) {
        await dispatch(getPinnedPosts({ id: kreiseId, type: 'kreiseNews' }));
      }
      dispatch(getNewsListById({ kreiseId, page: kreiseNewsPageNumber, limit: 5 }));
      dispatch(setKreiseNewsPageNumber(kreiseNewsPageNumber + 1));
    }
  };
  const onSubscribeHandler = () => {
    //@ts-ignore
    const isPrivate =
      currentKreise.is_private ||
      currentKreise.privacyStatus === 'private' ||
      currentKreise.privacyStatus === 'request';

    if (currentKreise.is_subscribed && isPrivate) {
      setShowModal(true);
      return;
    }
    subscibeOnKreise();
  };

  const subscibeOnKreise = () => {
    const userId = localStorage.getItem('userId');
    const kreiseIdArr = JSON.parse(localStorage.getItem('kreiseId'));

    const payload = {
      name: currentKreise.name,
      userid: userId,
      is_subscribed: !currentKreise.is_subscribed,
    };

    dispatch(subscribeKreise(payload));
    if (kreiseIdArr) {
      if (Array.isArray(kreiseIdArr)) {
        const filteredKreiseArr = kreiseIdArr.filter((id) => id !== currentKreise.id);
        localStorage.setItem('kreiseId', JSON.stringify(filteredKreiseArr));
      } else {
        localStorage.setItem('kreiseId', JSON.stringify(null));
      }
    }
    setShowModal(false);
  };

  const modalHandler = () => {
    let newShowKreiseInfo;
    setShowKreiseInfo((prev) => {
      newShowKreiseInfo = !prev;
      return newShowKreiseInfo;
    });

    if (!newShowKreiseInfo) {
      document.body.style.overflow = 'unset';
    }
  };

  useEffect(() => {
    if (kreiseId) {
      dispatch(getKreiseById(kreiseId));
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (currentKreise && currentKreise?.id !== kreiseId) {
      dispatch(clearKreiseNews());
      dispatch(setKreiseNewsPageNumber(1));
    }
  }, []);

  const cancelSubscribeKreise = () => {
    const kreiseIdArr = JSON.parse(localStorage.getItem('kreiseId'));

    if (
      currentKreise &&
      currentKreise.id === kreiseId &&
      !currentKreise.is_subscribed &&
      !kreiseIdArr?.includes(currentKreise.id) &&
      askUserToSub
    ) {
      if (kreiseIdArr) {
        let newKreiseArr = [];
        if (Array.isArray(kreiseIdArr)) {
          newKreiseArr = [...kreiseIdArr, currentKreise.id];
        } else {
          newKreiseArr = [kreiseIdArr, currentKreise.id];
        }
        localStorage.setItem('kreiseId', JSON.stringify(newKreiseArr));
      } else {
        localStorage.setItem('kreiseId', JSON.stringify(currentKreise.id));
      }
    }
    setIsFirstSubscribe(false);
    setAskUserToSub(false);
  };

  const mappedItems = (
    <>
      {kreiseNews.length > 0 ? (
        kreiseNews.length > 0 &&
        kreiseNews?.map((post: any, i: number) => {
          if (!post?._id) return null;
          return !post.clickCount && <Post post={post} key={post?._id + i} kreisePosts />;
        })
      ) : params?.mentoring ? (
        <div style={{ backgroundColor: theme.BACKGROUND }}>
          <EmptyList />
        </div>
      ) : (
        <EmptyList />
      )}
    </>
  );

  const RenderPage = () => (
    <>
      <KreiseHeader
        title={`${currentKreise?.name} (${currentKreise?.sm_num})`}
        checked={currentKreise?.is_subscribed}
        onChange={onSubscribeHandler}
        onClick={() => history.goBack()}
        isLoaded={!currentKreiseIsLoading}
        onShowInfo={() => history.push(`/kreise-info/${kreiseId}`)}
        canUnsubscribe={!isSubscribedOnCorps && currentKreise?.allowUnsubscribe}
        photo={currentKreise?.photo}
      />
      <div className={classes.container}>
        {kreiseNews.length >= kreiseNewsCount && newsIsLoading ? (
          <div className={classes.progressWrapper}>
            <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={35} />
          </div>
        ) : (
          <InfiniteScroll
            threshold={50}
            initialLoad={true}
            pageStart={1}
            loadMore={getPostsHandler}
            hasMore={kreiseNewsCount === null ? true : kreiseNews.length < kreiseNewsCount}
            loader={
              <div style={{ textAlign: 'center' }} key={52}>
                <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={25} />
              </div>
            }
            useWindow={true}
          >
            {mappedItems}
          </InfiniteScroll>
        )}
        <div>
          {!currentKreise?.onlyAdminsPost || isAdmin ? (
            <PostsToolbar kreiseId={kreiseId} toolbarType="post" kreiseName={currentKreise?.name} />
          ) : (
            <LockedPostToolbar message={circles.readOnlyKreise} />
          )}
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );

  return (
    <React.Fragment>
      {params?.mentoring ? (
        RenderPage()
      ) : (
        <Layout
          headerPadding={50}
          backgroundColor={kreiseNews.length > 0 ? theme.BACKGROUND_SECONDARY : theme.BACKGROUND}
        >
          {RenderPage()}
        </Layout>
      )}
      {showKreiseInfo && (
        <KreiseInfoModal
          modalHandler={modalHandler}
          showModal={showKreiseInfo}
          subscribersSum={currentKreise.sm_num}
          kreiseName={currentKreise.name}
          kreiseDesc={currentKreise.description}
        />
      )}
      <DefaultModal
        theme={theme}
        showModal={isFistSubscribe}
        onClose={() => setIsFirstSubscribe(false)}
        modalTitle={kreisePostScreen.subscribeTitle}
        message={kreisePostScreen.subscribeDesc}
      >
        <div style={{ height: 20 }} />
        <Button
          theme={theme}
          label={kreisePostScreen.yes}
          onClick={() => {
            subscibeOnKreise();
            setIsFirstSubscribe(false);
          }}
        />
        <Button
          theme={theme}
          label={kreisePostScreen.later}
          onClick={cancelSubscribeKreise}
          background={theme.BUTTON_SECONDARY}
        />
        <div style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
          <CheckboxUI
            isChecked={askUserToSub}
            setIsChecked={() => setAskUserToSub((prev) => !prev)}
            label={kreisePostScreen.dontAsk}
          />
        </div>
      </DefaultModal>
      <DefaultModal
        theme={theme}
        showModal={showModal}
        onClose={() => setShowModal(false)}
        modalName="uploadDocumentModal"
        modalTitle={circles.unsubscribeAlert}
      >
        <div style={{ height: 20 }} />
        <Button
          theme={theme}
          label={btn.declineReqBtn}
          background={theme.BUTTON_SECONDARY}
          onClick={() => setShowModal(false)}
        />
        <Button theme={theme} label={circles.continueAlert} onClick={subscibeOnKreise} />
      </DefaultModal>
    </React.Fragment>
  );
};

export default withStyles(styles)(withAuth(withConfig(memo(KreisePosts))));
