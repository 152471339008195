import { getConfig } from 'config/config';

const { theme } = getConfig();

interface IIconProps {
  size?: number;
  color?: string;
}
const SvgComponent = ({ color = theme.ACTIVE_INPUT, size = 24 }: IIconProps) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.92963 19.3877C2.49247 16.9506 2.49247 12.986 4.92963 10.5489L11.2641 4.21443C11.508 3.97048 11.904 3.97048 12.148 4.21443C12.3919 4.45838 12.3919 4.85436 12.148 5.09831L5.81351 11.4328C3.86361 13.3827 3.86361 16.5539 5.81351 18.5038C7.7634 20.4537 10.9347 20.4537 12.8846 18.5038L19.3664 12.022C20.6662 10.7222 20.6662 8.60784 19.3664 7.30802C18.0665 6.00809 15.9522 6.00809 14.6523 7.30791L8.75972 13.2005C8.10975 13.8505 8.10986 14.9076 8.75982 15.5575C9.40979 16.2075 10.4668 16.2076 11.1168 15.5576L16.8621 9.81239C17.106 9.56844 17.502 9.56844 17.7459 9.81239C17.9899 10.0563 17.9899 10.4523 17.7459 10.6963L12.0007 16.4415C10.8635 17.5787 9.01316 17.5786 7.87594 16.4414C6.73872 15.3042 6.73861 13.4539 7.87583 12.3167L13.7685 6.42403C15.555 4.63749 18.463 4.63684 20.2503 6.42414C22.0375 8.21132 22.0368 11.1193 20.2503 12.9059L13.7685 19.3877C11.3313 21.8249 7.36678 21.8249 4.92963 19.3877Z"
      fill={color}
    />
  </svg>
);

export default SvgComponent;
