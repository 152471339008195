import React from 'react';
import { CircularProgress } from '@material-ui/core';
import CloseIcon from 'assets/icons/CloseIcon';
import Loader from 'components/UI/Loader';
import { getConfig } from 'config/config';
import { useLinkInfo } from 'scenes/DialogPanel/useLinkInfo';

const { theme } = getConfig();

interface LinkPreviewProps {
  url: string;
  handleOpenLink: (url: string) => void;
  row?: boolean;
  handleCloseLink?: () => void;
}

const LinkPreview: React.FC<LinkPreviewProps> = React.memo(
  ({ url, handleOpenLink, row, handleCloseLink }) => {
    const { data, loading } = useLinkInfo(url);

    if (loading) {
      return (
        <div className="flex items-center justify-center">
          <CircularProgress style={{ color: theme.BACKGROUND_SECONDARY }} size={35} />
        </div>
      );
    }

    if (!data) return null;

    if (row) {
      return (
        <div
          style={{
            padding: '5px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            marginBottom: '5px',
            maxHeight: '320px',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'row',
            margin: '5px',
            backgroundColor: '#fff',
            position: 'relative',
          }}
        >
          {data.image && (
            <img
              src={data.image}
              style={{
                marginRight: '10px',
                borderRadius: '8px',
                objectFit: 'contain',
                width: '80px',
                height: '80px',
              }}
              alt="preview"
            />
          )}
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {data.title && (
              <p style={{ fontWeight: 'bold', fontSize: '12px', marginBottom: '5px' }} title={data.title}>
                {data.title}
              </p>
            )}
            <p style={{ fontSize: '12px', color: '#007aff' }}>{url}</p>
          </div>
          <button
            style={{
              background: 'none',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={handleCloseLink}
          >
            <CloseIcon />
          </button>
          <Loader showLoader={loading} />
        </div>
      );
    }

    return (
      <div
        onClick={() => handleOpenLink(data?.url || url)}
        style={{
          padding: '5px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          marginBottom: '5px',
          maxHeight: '320px',
          backgroundColor: 'white',
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        {data.image && (
          <div style={{ backgroundColor: 'white' }}>
            <img
              src={data.image}
              style={{
                width: '100%',
                height: '150px',
                marginRight: '10px',
                borderRadius: '8px',
                objectFit: 'contain',
              }}
              alt="preview"
            />
          </div>
        )}
        <div style={{ justifyContent: 'center' }}>
          {data.title && <p style={{ fontWeight: 'bold', fontSize: '12px', marginBottom: '5px' }}>{data.title}</p>}
          {data.description && <p style={{ fontSize: '10px', color: '#555' }}>{data.description}</p>}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.url === nextProps.url && prevProps.row === nextProps.row,
);

export default LinkPreview;
