import {
  GET_CHAT_USERS_REQUEST,
  GET_CHAT_USERS_SUCCESS,
  GET_CHAT_USERS_FAILURE,
  CLEAR_CHAT_USERS,
  MARK_MESSAGE_AS_READ_REQUEST,
  MARK_MESSAGE_AS_READ_SUCCESS,
  MARK_MESSAGE_AS_READ_FAILURE,
  GET_DIALOG_REQUEST,
  GET_DIALOG_SUCCESS,
  GET_DIALOG_FAILURE,
  CLEAR_DIALOG,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  UNREAD_MESSAGES_REQUEST,
  UNREAD_MESSAGES_SUCCESS,
  UNREAD_MESSAGES_FAILURE,
  CLEAR_UNREAD_MESSAGES,
} from '../../constants/';

// GET CHAT USERS
export const getChatUsersRequest = (open: boolean) => ({
  type: GET_CHAT_USERS_REQUEST,
  open,
});

export const getChatUsersSuccess = (users: Array<any>) => ({
  type: GET_CHAT_USERS_SUCCESS,
  users,
});

export const getChatUsersFailure = (error: object) => ({
  type: GET_CHAT_USERS_FAILURE,
  error,
});

// CLEAR CHAT USERS
export const clearChatUsers = () => ({
  type: CLEAR_CHAT_USERS,
});

// MARK AS READ
export const markAsReadRequest = (receiverUID: string, unreadMessages: any[]) => ({
  type: MARK_MESSAGE_AS_READ_REQUEST,
  unreadMessages,
  receiverUID,
});

export const markAsReadSuccess = () => ({
  type: MARK_MESSAGE_AS_READ_SUCCESS,
});

export const markAsReadFailure = () => ({
  type: MARK_MESSAGE_AS_READ_FAILURE,
});

// GET DIALOG
export const getDialogRequest = (dialogId: string, mode: string) => ({
  type: GET_DIALOG_REQUEST,
  dialogId,
  mode,
});

export const getDialogSuccess = (dialog: object) => ({
  type: GET_DIALOG_SUCCESS,
  dialog,
});

export const getDialogFailure = (error: object) => ({
  type: GET_DIALOG_FAILURE,
  error,
});

// CLEAR DIALOG
export const clearDialog = () => ({
  type: CLEAR_DIALOG,
});

// SEND MESSAGE
export const sendMessageRequest = (
  receiverId: string,
  message: string,
  notification: object,
  attachments: any,
  isDisabledLinkPreview?: boolean,
) => ({
  type: SEND_MESSAGE_REQUEST,
  receiverId,
  message,
  notification,
  attachments,
  isDisabledLinkPreview,
});

export const sendMessageSuccess = () => ({
  type: SEND_MESSAGE_SUCCESS,
});

export const sendMessageFailure = () => ({
  type: SEND_MESSAGE_FAILURE,
});

// UNREAD MESSAGES
export const unreadMessagesRequest = () => ({
  type: UNREAD_MESSAGES_REQUEST,
});

export const unreadMessagesSuccess = (messages: Array<any>, messageKey: string) => ({
  type: UNREAD_MESSAGES_SUCCESS,
  messages,
  messageKey,
});

export const unreadMessagesFailure = () => ({
  type: UNREAD_MESSAGES_FAILURE,
});

export const clearUnreadMessages = () => ({
  type: CLEAR_UNREAD_MESSAGES,
});
