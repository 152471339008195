import Layout from 'components/UI/Layout';
import Loader from 'components/UI/Loader';
import { getConfig } from 'config/config';
import { CircularProgress } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { useHistory } from 'react-router';
import { checkServerConnectivity } from 'store/reducers/server';
import { useLanguage } from 'languages/languageContext';

const { theme } = getConfig();

export const ServerIsDown: FC = () => {
  const dispatch = useAppDispatch();
  const { isAlive, loading } = useAppSelector((state) => state.server);
  const history = useHistory();
  const { serverIsDownScreen } = useLanguage();

  useEffect(() => {
    let sInterval = 5000;

    const fetchData = () => {
      dispatch(checkServerConnectivity());
    };

    fetchData();

    // Set up an interval to fetch data every sInterval seconds
    const interval = setInterval(fetchData, sInterval);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    console.log('🚀 ~ file: ServerIsDown.tsx:36 ~ useEffect ~ isAlive:', isAlive);
    if (isAlive) {
      history.push('/posts');
    }
  }, [isAlive]);

  return (
    <Layout>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <h1>{serverIsDownScreen.serverMaintenance}</h1>
        <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={35} />
      </div>
    </Layout>
  );
};
