import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';

import {
  likeEventCommentRequest,
  unlikeEventCommentRequest,
  clearEventCommentLikes,
  getEventLikesRequest,
  getEventCommentLikesRequest,
} from 'store/actions/likes';
import { withConfig, Theme, Config } from 'config';
import { imagePath, timeAgo } from 'utilities';
import defaultImage from 'assets/default_avatar.png';
import { ReactComponent as DeleteIcon } from 'assets/delete_button_blue.svg';
import LikesModal from 'components/Shared/DataModal';
import CommentsGallery from 'components/Shared/PostGallery';
import DefaultModal from 'components/Shared/DefaultModal';
import Button from 'components/UI/Button';

import CommentsLikeButton from '../../Comments/components/CommentsLikeButton';
import { useLanguage } from 'languages/languageContext';
import { AttachmentsGallery } from 'components/Shared/AttachmentsGallery';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { useCustomHistory } from 'hooks/useCustomHistory';
import { getLocalStorage } from 'utilities/localStorageHandler';
import { deleteEventComment } from 'store/reducers/commentsSlice';
import Content from 'components/UI/Content';

const styles = createStyles({
  container: {
    position: 'relative',
    width: '100%',
    padding: '20px 10px 40px',
    marginBottom: 10,
    borderBottom: '1px solid',
    boxSizing: 'border-box',
  },
  senderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    borderRadius: 30,
    border: '2px solid',
    overflow: 'hidden',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
  },
  heading: {
    marginLeft: 20,
    marginBottom: 5,
    cursor: 'pointer',
  },
  name: {
    display: 'flex',
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginRight: 5,
  },
  prefix: {
    display: 'block',
    fontFamily: 'Poppins',
    fontWeight: 600,
  },
  commentWrapper: {
    width: '100%',
  },
  comment: {
    fontFamily: 'Roboto',
    fontSize: 15,
    margin: 0,
    padding: '0 10px 20px',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    maxWidth: 500,
  },
  kreise: {
    fontFamily: 'Poppins',
    marginLeft: 5,
  },
  date: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 14,
  },

  likeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  leftButton: {
    boxSizing: 'border-box',
    width: 50,
    marginRight: 2,
    borderRadius: '50px 0 0 50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightButton: {
    padding: '12px 0',
    width: 50,
    borderRadius: '0 50px 50px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  commentsButton: {
    padding: '13px 30px',
    borderRadius: 50,
  },
  commentsLabel: {
    fontFamily: 'Roboto',
    fontSize: 15,
  },
  likeLabel: {
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: '21px',
    display: 'block',
  },
  deleteButton: {
    position: 'absolute',
    top: 25,
    right: 10,
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@media(max-width: 650px)': {
    name: {
      lineHeight: '16px',
    },
    comment: {
      maxWidth: 320,
    },
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  commenter: any;
  date: string;
  content: string;
  likedUsers: Array<string>;
  comment: any;
  event: any;
  config: Config;
}

const Comment: React.FC<Props> = ({ theme, classes, commenter, date, content, likedUsers, comment, event, config }) => {
  const { eventLabels, btn, global } = useLanguage();
  const [showModal, setShowModal] = useState<boolean>(false);
  const { customPush } = useCustomHistory();
  let image,
    newsDate,
    likedPost = false;
  const userId = getLocalStorage('userId');
  // const owner = commenter._id === userId;
  const owner = userId === commenter._id || userId === comment.commenter;
  const history = useHistory();
  const commenterName = `${commenter.name} ${commenter.lastname}`;

  const likes = useSelector((state: RootState) => state.likes);

  const dispatch = useDispatch();

  // STATE
  const [showLikes, setShowLikes] = useState(false);
  const [currentLikes, setCurrentLikes] = useState<Array<any> | null>(null);

  // INIT COMPONENT STATE
  useEffect(() => {
    setCurrentLikes(comment.liked_users);
  }, []);

  if (userId && currentLikes) {
    likedPost = currentLikes.includes(userId);
  }

  if (commenter) {
    image = imagePath(null, commenter.photo);
    newsDate = timeAgo(date);
  }

  const toProfileHandler = () => {
    customPush(`/profile-details/${commenter._id}`, { userId: commenter._id });
  };

  // LIKES HANDLER
  const likeCommentHandler = () => {
    const userId = getLocalStorage('userId');

    if (userId && !likedPost) {
      const updatedLikes = [...currentLikes];
      updatedLikes.push(userId);
      setCurrentLikes(updatedLikes);
      dispatch(likeEventCommentRequest(event._id, userId, comment._id));
    } else if (userId && likedPost) {
      const updatedLikes = [...currentLikes];

      const likeIndex = updatedLikes.indexOf(userId);
      const filteredLikes = updatedLikes.filter((like, i) => i !== likeIndex);
      setCurrentLikes(filteredLikes);
      dispatch(unlikeEventCommentRequest(event._id, userId, comment._id));
    }
  };

  // MODAL HANDLER
  const onOpenLikesModal = () => {
    setShowLikes(true);
  };

  const onCloseModal = () => {
    setShowLikes(false);
    dispatch(clearEventCommentLikes());
  };

  const onDeleteComment = (commentId: string) => {
    dispatch(deleteEventComment({ eventId: event._id, commentId }));
    setShowModal(false);
  };

  return (
    <React.Fragment>
      {currentLikes ? (
        <div className={classes.container} style={{ borderBottomColor: theme.BACKGROUND_SECONDARY }}>
          {owner && (
            <ButtonBase
              className={classes.deleteButton}
              style={{ background: theme.BACKGROUND_SECONDARY }}
              onClick={() => setShowModal(true)}
            >
              <DeleteIcon />
            </ButtonBase>
          )}
          <div className={classes.senderWrapper}>
            <div
              className={classes.imageWrapper}
              style={{ borderColor: theme.BACKGROUND_SECONDARY }}
              onClick={toProfileHandler}
            >
              <img src={image || defaultImage} alt=" " className={classes.image} />
            </div>
            <div className={classes.heading}>
              <span onClick={toProfileHandler} className={classes.name} style={{ color: theme.TEXT_PRIMARY }}>
                {commenterName}
              </span>
              <span className={classes.date} style={{ color: theme.TEXT_PRIMARY }}>
                {newsDate}
              </span>
            </div>
          </div>
          <AttachmentsGallery documents={comment.documents} videos={comment.videos} images={comment.images} />
          <div className={classes.commentWrapper}>
            <p className={classes.comment} style={{ color: theme.TEXT_PRIMARY }}>
              <Content content={content} />
            </p>
          </div>
          <CommentsLikeButton
            likedUsers={currentLikes}
            likedPost={likedPost}
            showLikesHandler={onOpenLikesModal}
            likeHandler={likeCommentHandler}
          />
        </div>
      ) : null}
      {showLikes && currentLikes && (
        <LikesModal
          showModal={showLikes}
          modalHandler={onCloseModal}
          modalTitle={global.likesCount(currentLikes.length)}
          item={comment}
          getDataHandler={getEventCommentLikesRequest}
          items={likes.eventCommentLikes}
          itemsCount={likes.eventCommentLikesTotal}
          loader={likes.eventCommentLikesIsLoading}
          backRoute="Calendar"
        />
      )}
      <DefaultModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        modalTitle={config.APP_FULL_NAME}
        message={eventLabels.delCommentLabel}
      >
        <Button label={btn.cancelBtn} background={theme.BUTTON_SECONDARY} onClick={() => setShowModal(false)} />
        <Button label={global.ok} onClick={() => onDeleteComment(comment._id)} />
      </DefaultModal>
    </React.Fragment>
  );
};

export default withStyles(styles)(withConfig(Comment));
