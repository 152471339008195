import React from 'react';
import { connect } from 'react-redux';
import { ButtonBase } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { withStyles, WithStyles, createStyles } from '@material-ui/styles';
import { withConfig, Theme } from '../../../config';
import { updateUserPasswordRequest } from '../../../store/actions/users';
import Input from '../../../components/UI/Input';
import Button from '../../../components/UI/Button';
import { patterns, validateInput } from 'utilities';
import { translationsType, useLanguage, withLanguage } from 'languages/languageContext';

const styles = createStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(97, 97, 97, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 60,
    overflowX: 'hidden', //horizontal
    overflowY: 'hidden', //horizontal
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
  },
  modalWrapper: {
    position: 'relative',
    width: 520,
    paddingBottom: 15,
    borderRadius: 10,
    zIndex: 60,
    '@media(max-width: 650px)': {
      width: '90%',
    },
    '& > div::-webkit-scrollbar': {
      display: 'none',
    },
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    overflowY: 'hidden', //horizontal
    overflowX: 'hidden', //horizontal
  },
  heading: {
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    position: 'relative',
    padding: '20px 0 20px 0',
  },
  title: {
    textAlign: 'center',
    fontSize: 16,
    fontFamily: 'Roboto',
    margin: 0,
    letterSpacing: '0.1',
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  scrollWrapper: {
    width: '100%',
    overflow: 'scroll',
  },
  scroll: {
    padding: '15px 25px',
    margin: 0,
  },
  scrollItem: {
    width: '100%',
    display: 'block',
    textAlign: 'left',
    listStyleType: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    lineHeight: '25px',
    cursor: 'pointer',
    padding: '5px 0',
    borderRadius: 5,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 10,
    outline: 'none',
    border: 'none',
  },
  messageWrapper: {
    padding: '10px 20px',
  },
  message: {
    fontSize: 15,
    textAlign: 'center',
  },

  // INPUTS
  inputsWrapper: {
    padding: '30px 30px 10px',
  },
  buttonsWrapper: {
    padding: 10,
  },
});

interface Props extends WithStyles<typeof styles> {
  theme: Theme;
  showModal: boolean;
  onClose?: () => void;
  userPasswordIsLoading: boolean;
  modalTitle: string;
  updateUserPasswordRequest: (payload: object, callback: any) => void;
  language: translationsType;
}

class ChangePasswordModal extends React.Component<Props> {
  state = {
    currentPassword: {
      value: '',
      isFocused: false,
      isValid: false,
      error: '',
    },
    newPassword: {
      value: '',
      isValid: false,
      error: '',
      isFocused: false,
    },
    confirmedPassword: {
      value: '',
      isValid: false,
      error: '',
      isFocused: false,
    },
  };

  validateNewPassword = (value: string) => {
    const { global } = this.props.language;

    let isValid = true,
      error = '';

    if (value !== this.state.confirmedPassword.value) {
      isValid = false;
      error = global.passwordsMatchError2;
    }

    this.setState({
      newPassword: {
        ...this.state.currentPassword,
        isValid,
      },
      confirmedPassword: {
        ...this.state.confirmedPassword,
        isValid,
        error,
      },
    });
  };

  validateOldPassword = (value: string) => {
    const { global } = this.props.language;

    let isValid = true,
      error = '';

    if (value === this.state.newPassword.value) {
      isValid = false;
      error = global.passwordOldMatchNewError;
    }

    this.setState({
      currentPassword: {
        ...this.state.currentPassword,
        isValid,
      },
      newPassword: {
        ...this.state.newPassword,
        isValid,
        error,
      },
    });
  };

  configConfirmField = (value: string, name: string): object => {
    const { global } = this.props.language;
    let error, isValid;

    if (value.trim().length !== 0 && value !== this.state[name].value) {
      error = global.passwordsMatchError2;
      isValid = false;
    } else if (value === this.state[name].value) {
      isValid = true;
    }

    return {
      error,
      isValid,
    };
  };

  onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    let { value } = e.target;
    const { currentPassword } = this.state;
    const { invalidInputs, global } = this.props.language;
    value = value.replace(/(\s)/g, '');

    let isValid = false,
      error = '';

    if (name === 'currentPassword') {
      this.validateOldPassword(value);
      isValid = value.trim().length >= 6 ? true : false;
      error = isValid ? '' : global.passwordLenghtError;
    }
    if (name === 'newPassword') {
      isValid = validateInput(value, patterns.password);
      if (!isValid) error = invalidInputs.passwordChars;

      if (value.trim() === currentPassword.value) {
        error = global.passwordsMatchError;
      }
    }
    if (name === 'confirmedPassword') {
      const confirmConfig: { [name: string]: any } = this.configConfirmField(value, 'newPassword');
      error = confirmConfig.error;
      isValid = confirmConfig.isValid;
    }

    this.setState({
      [name]: {
        ...this.state[name],
        value,
        isValid,
        error,
      },
    });
  };

  focusHandler = (name: any): void => {
    this.setState({
      [name]: {
        ...this.state[name],
        isFocused: !this.state[name].isFocused,
      },
    });
  };

  updatePasswordHandler = () => {
    const { currentPassword, newPassword } = this.state;
    const { onClose } = this.props;

    const payload = {
      currentPassword: currentPassword.value,
      newPassword: newPassword.value,
    };

    this.props.updateUserPasswordRequest(payload, onClose);
  };

  render() {
    const {
      classes,
      theme,
      showModal,
      onClose,
      modalTitle,
      language: { global },
    } = this.props;
    const { currentPassword, newPassword, confirmedPassword } = this.state;

    return showModal ? (
      <div className={classes.container}>
        <div className={classes.modalWrapper} style={{ background: theme.BACKGROUND }}>
          <div className={classes.heading} style={{ background: theme.BACKGROUND_SECONDARY }}>
            <h3 className={classes.title} style={{ color: theme.TEXT_PRIMARY }}>
              {modalTitle}
            </h3>
            <div className={classes.buttonWrapper}>
              <ButtonBase onClick={onClose} style={{ borderRadius: 50 }}>
                <Close style={{ color: theme.BUTTON_SECONDARY }} />
              </ButtonBase>
            </div>
          </div>
          <div className={classes.scrollWrapper} style={{ background: theme.BACKGROUND }}>
            <div className={classes.inputsWrapper}>
              <Input
                security
                placeholder={global.currentPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'currentPassword')}
                isFocused={currentPassword.isFocused}
                onBlur={() => this.focusHandler('currentPassword')}
                onFocus={() => this.focusHandler('currentPassword')}
                error={currentPassword.error}
                value={currentPassword.value}
              />
              <Input
                security
                placeholder={global.newPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'newPassword')}
                isFocused={newPassword.isFocused}
                onBlur={() => this.focusHandler('newPassword')}
                onFocus={() => this.focusHandler('newPassword')}
                error={newPassword.error}
                value={newPassword.value}
              />
              <Input
                security
                placeholder={global.confirmPassword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHandler(e, 'confirmedPassword')}
                isFocused={confirmedPassword.isFocused}
                onBlur={() => this.focusHandler('confirmedPassword')}
                onFocus={() => this.focusHandler('confirmedPassword')}
                error={confirmedPassword.error}
                value={confirmedPassword.value}
              />
            </div>
            <div className={classes.buttonsWrapper}>
              <Button
                label={global.changePassword}
                onClick={this.updatePasswordHandler}
                disabled={currentPassword.isValid && newPassword.isValid && confirmedPassword.isValid}
              />
              <Button label={global.cancel} onClick={onClose} background={theme.BUTTON_SECONDARY} />
            </div>
          </div>
        </div>
        <div className={classes.backdrop} onClick={onClose}></div>
      </div>
    ) : null;
  }
}

const mapStateToProps = (state: any) => ({
  userPasswordIsLoading: state.users.userPasswordIsLoading,
});

const mapDispatchToProps = {
  updateUserPasswordRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLanguage(withStyles(styles)(withConfig(ChangePasswordModal))));
