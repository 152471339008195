import React from 'react';
import { withStyles, createStyles, WithStyles } from '@material-ui/styles';
import { ConnectedProps, connect } from 'react-redux';
import { resetPasswordRequest } from '../../store/actions/auth';
import { RouteComponentProps } from 'react-router-dom';
import { ResetPasswordState } from './model';
import { withConfig } from '../../config/';
import { validateInput, patterns } from '../../utilities/validation';
import Logo from '../../components/UI/TextLogo';
import IconUI from '../../components/UI/IconUI';
import Input from '../../components/UI/Input';
import Heading from '../../components/Shared/Heading';
import Button from '../../components/UI/Button';
import Loader from '../../components/UI/Loader';
import { withLanguage } from 'languages';
import { getConfig } from 'config/config';
import { RootState } from 'store';
import { translationsType } from 'languages/languageContext';
import Conditions from 'scenes/SignIn/components/Conditions';
import { Checkbox } from 'components/shadcn/checkbox';

const { theme } = getConfig();

const styles = createStyles({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '70px 0 70px 0',
  },
  wrapper: {
    width: 360,
  },
  inputsWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 40,
  },
  desc: {
    fontSize: 12,
    textAlign: 'center',
    color: theme.TEXT_SECONDARY,
  },
});

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any>, PropsFromRedux {
  language: translationsType;
}

class ResetPassword extends React.Component<Props, ResetPasswordState> {
  state = {
    email: {
      value: this.props.location.state ?? '',
      error: '',
      isValid: !!this.props.location.state,
      isFocused: false,
    },
    checkbox: false,
  };

  changeTextHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    let { value } = e.target;
    value = value.replace(/(\s)/g, '');
    const error = this.props.language.resetPasswordScreen.incorrectEmail;
    const isValid = validateInput(value, patterns.email);

    this.setState({
      email: {
        value,
        error: isValid ? '' : error,
        isValid,
        isFocused: true,
      },
    });
  };

  focusHandler = (): void => {
    this.setState({
      email: {
        ...this.state.email,
        isFocused: !this.state.email.isFocused,
      },
    });
  };

  toLoginHandler = (): void => {
    const { history } = this.props;

    history.push('/sign-in');
  };

  resetHandler = () => {
    const { resetPasswordRequest } = this.props;
    const { email } = this.state;

    resetPasswordRequest(email.value);
  };

  render() {
    const { classes, authIsLoading, language, accept_agb_at_passwort_reset } = this.props;
    const { email, checkbox } = this.state;

    const isChecked = accept_agb_at_passwort_reset ? checkbox : true;
    console.log('🚀 ~ ResetPassword ~ render ~ accept_agb_at_passwort_reset:', accept_agb_at_passwort_reset);
    console.log('🚀 ~ ResetPassword ~ render ~ isChecked:', isChecked);

    return (
      <React.Fragment>
        <div className={classes.container}>
          <div className={classes.wrapper}>
            <Logo />
            <IconUI />
            <Heading title={language.resetPassword.enterEmail} />
            <div className={classes.inputsWrapper}>
              <Input
                placeholder={language.resetPassword.inputPlaceholder}
                onChange={this.changeTextHandler}
                autoCapitalize="none"
                error={email.error}
                value={email.value}
                isFocused={email.isFocused}
                onBlur={this.focusHandler}
                onFocus={this.focusHandler}
              />
              <div className={classes.desc}>{language.resetPassword.desc}</div>

              {accept_agb_at_passwort_reset && (
                <div className="mt-2 flex flex-row">
                  {accept_agb_at_passwort_reset && (
                    <div className="self-center">
                      <Checkbox checked={checkbox} onCheckedChange={() => this.setState({ checkbox: !checkbox })} />
                    </div>
                  )}
                  <Conditions language={language} type="resetPassword" />
                </div>
              )}
            </div>
            <Button
              label={language.resetPassword.sendBtn}
              disabled={email.isValid && isChecked}
              onClick={this.resetHandler}
            />
            <Button
              label={language.resetPassword.cancelBtn}
              background={theme.BUTTON_SECONDARY}
              disabled={true}
              onClick={this.toLoginHandler}
            />
          </div>
        </div>
        <Loader showLoader={authIsLoading} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth, common }: RootState) => ({
  authIsLoading: auth.authIsLoading,
  accept_agb_at_passwort_reset: common.accept_agb_at_passwort_reset,
});

const mapDispatchToProps = {
  resetPasswordRequest,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(withStyles(styles)(withLanguage(withConfig(ResetPassword))));
