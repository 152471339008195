import { client } from 'api/client';
import { useEffect, useState } from 'react';

export interface PreviewData {
  title?: string;
  description?: string;
  image?: string;
  favicon?: string;
  url?: string;
}

export const useLinkInfo = (url: string) => {
  const [data, setData] = useState<PreviewData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!url) return;

    const fetchLinkInfo = async () => {
      setLoading(true);

      const isHttps = /^https?:\/\//i.test(url);
      const isHttp = /^http?:\/\//i.test(url);

      const normalizedUrl = isHttp || isHttps ? url : `http://${url}`;

      const fallbackTitle = isHttps ? url.replace(/^https?:\/\//, '') : url.replace(/^http?:\/\//, '');

      try {
        const response: PreviewData | null = await client.get(`/link-preview?target=${normalizedUrl}`);
        setData({
          ...response,
          url,
          title: response?.title ?? fallbackTitle,
          description: response?.description ?? fallbackTitle,
        });
      } catch (error) {
        setData({ url, title: fallbackTitle, description: fallbackTitle });
      } finally {
        setLoading(false);
      }
    };

    fetchLinkInfo();
  }, [url]);

  return { data, loading };
};
