import { MultipleSearchableInput } from 'components/Shared/MultipleSearchableInput';
import SearchableModal from 'components/Shared/SearchableModal';
import { useLanguage } from 'languages/languageContext';
import { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store';
import { searchRequest } from 'store/reducers/searchSlice';

type Props = {
  open: boolean;
  onClose: () => void;
  values: any[];
  onSelect: (c: any) => void;
  onDelete: (c: any) => void;
  title?: string;
};

export const AddOrganizersModal: FC<Props> = ({ open, onClose, values, onSelect, onDelete, title }) => {
  const dispatch = useAppDispatch();
  const { eventLabels, drawer } = useLanguage();
  const { data, count, isLoading } = useAppSelector((state) => state.search.name);

  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const latestSearchQueryRef = useRef('');

  const queryDebounceTime = 500;

  useEffect(() => {
    latestSearchQueryRef.current = searchQuery;
  }, [searchQuery]);

  const fetchUsers = (clear = false, isLoadMore = false) => {
    if (isLoading) return;

    const activeSearchQuery = latestSearchQueryRef.current;

    if (isLoadMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    }

    if ((currentPage === 1 || count > data.length) && !isLoading) {
      dispatch(
        searchRequest({
          page: currentPage,
          filter: 'name',
          searchkey: activeSearchQuery,
          clear,
        }),
      );
    }
  };

  const handleSearchQueryChange = (newQuery: string) => {
    setSearchQuery(newQuery);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setCurrentPage(1);
      fetchUsers(true, false);
    }, queryDebounceTime);
  };

  // Initial load for the first render
  useEffect(() => {
    if (open) {
      fetchUsers(true, false);
    }
  }, [open]); // Fetch only when modal opens

  // Clean up timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const loadMoreUsers = () => {
    fetchUsers(false, true);
  };

  return (
    <SearchableModal
      modalOpen={open}
      modalTitle={title || eventLabels.creatorLabel}
      onClick={onClose}
      setValueHandler={onClose}
      value={undefined}
    >
      <MultipleSearchableInput
        data={data}
        onSelect={onSelect}
        deleteItemHandler={onDelete}
        placeholder={drawer.search}
        onChange={(e) => handleSearchQueryChange(e.target.value)}
        value={searchQuery}
        values={values}
        paginated
        loadMore={loadMoreUsers}
        loading={isLoading}
        hasMore={data?.length < count}
      />
    </SearchableModal>
  );
};
