import { FC, useEffect, useRef } from 'react';

export const HtmlPost: FC<{
  html: string;
  checkHeight?: boolean;
  fontStyle?: { fontFamily: string; fontWeight: string };
}> = ({ html, fontStyle }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    const div = divRef.current;

    if (!iframe || !div) return;

    const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;

    const applyScaleAndHeight = () => {
      const containerWidth = iframe.parentElement?.offsetWidth ?? 0;
      const contentWidth = iframeDocument.documentElement.scrollWidth;
      const contentHeight = iframeDocument.documentElement.scrollHeight;
      const scale = containerWidth / contentWidth;

      iframe.style.transform = `scale(${scale})`;
      iframe.style.width = `${100 / scale}%`;
      iframe.style.height = `${contentHeight}px`;
      div.style.height = `${contentHeight * scale}px`;

      if (fontStyle) {
        iframeDocument.documentElement.style.fontFamily = fontStyle.fontFamily;
        iframeDocument.documentElement.style.fontWeight = fontStyle.fontWeight;
      }
    };

    const applyParentStyles = () => {
      const styleTag = iframeDocument.createElement('style');
      Array.from(document.styleSheets).forEach((sheet) => {
        try {
          if (sheet.cssRules) {
            Array.from(sheet.cssRules).forEach((rule) => {
              styleTag.appendChild(iframeDocument.createTextNode(rule.cssText));
            });
          } else if (sheet.href) {
            const linkTag = iframeDocument.createElement('link');
            linkTag.rel = 'stylesheet';
            linkTag.href = sheet.href;
            iframeDocument.head.appendChild(linkTag);
          }
        } catch (error) {
          console.warn('Unable to apply stylesheet:', error);
        }
      });
      iframeDocument.head.appendChild(styleTag);
    };

    const initializeIframeContent = () => {
      iframeDocument.open();
      iframeDocument.write(`
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          h5 { font-size: 16px !important; color: #353A54 !important; font-weight: bold !important; }
          p { font-size: 14px !important; font-weight: 300 !important; }
          a { font-weight: bolder !important; color: #2671D1 !important; margin-left: 3px !important; }
        </style>
        ${html}
      `);
      iframeDocument.close();
      applyParentStyles();
      applyScaleAndHeight();
    };

    const observer = new MutationObserver(() => {
      applyScaleAndHeight();
    });

    observer.observe(iframeDocument, { childList: true, subtree: true });

    initializeIframeContent();

    window.addEventListener('resize', applyScaleAndHeight);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', applyScaleAndHeight);
    };
  }, [html, fontStyle]);

  return (
    <div ref={divRef} style={{ width: '100%', overflow: 'hidden' }}>
      <iframe
        title="html post"
        ref={iframeRef}
        width="100%"
        style={{ border: 'none', transformOrigin: '0 0' }}
        scrolling="no"
      />
    </div>
  );
};
