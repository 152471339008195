export const kasconnect = {
  apiKey: 'AIzaSyCBe_sf-MZ9s_jWM2QI0wUnJgIyMdxtl94',
  authDomain: 'kasconnect-bf0ba.firebaseapp.com',
  databaseURL: 'https://kasconnect-bf0ba-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'kasconnect-bf0ba',
  storageBucket: 'kasconnect-bf0ba.appspot.com',
  messagingSenderId: '531387806759',
  appId: '1:531387806759:web:e158adf999703b5c530a02',
  measurementId: 'G-P9ZQ0L6J34',
};

export const landfrauen = {
  apiKey: 'AIzaSyA9iklPNJVpOZ-7tZg-hjsCq1mDuaRzVVs',
  authDomain: 'landfrauen-pfalz.firebaseapp.com',
  databaseURL: 'https://landfrauen-pfalz-default-rtdb.firebaseio.com',
  projectId: 'landfrauen-pfalz',
  storageBucket: 'landfrauen-pfalz.appspot.com',
  messagingSenderId: '773738635371',
  appId: '1:773738635371:web:e69007609add82d2fc112e',
};

export const meinp3 = {
  apiKey: 'AIzaSyCrNfjTg_jeIFitGZ6kl9xKs7Yu5rf5Qg0',
  authDomain: 'meinp3-connact.firebaseapp.com',
  databaseURL: 'https://meinp3-connact-default-rtdb.firebaseio.com',
  projectId: 'meinp3-connact',
  storageBucket: 'meinp3-connact.appspot.com',
  messagingSenderId: '705931099196',
  appId: '1:705931099196:web:d29ce38c4cb72851595739',
};

export const weserve = {
  apiKey: 'AIzaSyD6Un7c0PJuqGomHpcdZ3qEV4cO7kSN1u8',
  authDomain: 'we-serve-connact.firebaseapp.com',
  databaseURL: 'https://we-serve-connact-default-rtdb.firebaseio.com',
  projectId: 'we-serve-connact',
  storageBucket: 'we-serve-connact.appspot.com',
  messagingSenderId: '130380718476',
  appId: '1:130380718476:web:dfa67962b428660e1676a9',
};

export const co3 = {
  apiKey: 'AIzaSyBQ30S6YLUulUc8KhKFOJcyc5f9eu-tuks',
  authDomain: 'co3-app.firebaseapp.com',
  databaseURL: 'https://co3-app.firebaseio.com',
  projectId: 'co3-app',
  storageBucket: 'co3-app.appspot.com',
  messagingSenderId: '616031698702',
  appId: '1:616031698702:web:57aebffe2375ef52c4a15f',
};

export const wurtt = {
  apiKey: 'AIzaSyD_95nmQS2_FL-NJliSnkKCWlspui9xIrM',
  authDomain: 'wuerttembergia-app-757a7.firebaseapp.com',
  databaseURL: 'https://wuerttembergia-app-757a7.firebaseio.com',
  projectId: 'wuerttembergia-app-757a7',
  storageBucket: 'wuerttembergia-app-757a7.appspot.com',
  messagingSenderId: '801516211801',
  appId: '1:801516211801:web:426aa1f83b85b700a32b48',
  measurementId: 'G-HFHZLRRQTJ',
};

export const saxonia = {
  apiKey: 'AIzaSyD4JAoUvKbu6R3eGrRKuGeeVaOEKWd9dn4',
  authDomain: 'saxonia-31df9.firebaseapp.com',
  databaseURL: 'https://saxonia-31df9-default-rtdb.europe-west1.firebasedatabase.app/',
  projectId: 'saxonia-31df9',
  storageBucket: 'saxonia-31df9.appspot.com',
  messagingSenderId: '21905857612',
  appId: '1:21905857612:web:0c317533a26a0898d028e9',
};

export const atlas = {
  apiKey: 'AIzaSyAFmij4FppCbK0Gi28Apd6KlKHW97BN2UE',
  authDomain: 'atlas-initiative-9eee7.firebaseapp.com',
  databaseURL: 'https://atlas-initiative-9eee7-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'atlas-initiative-9eee7',
  storageBucket: 'atlas-initiative-9eee7.appspot.com',
  messagingSenderId: '746533334084',
  appId: '1:746533334084:web:4e1442e13808482e03e011',
};

export const kulinaristen = {
  apiKey: 'AIzaSyDTAelbZ72P7V895qXFSW_XWiRfk-yWjKc',
  authDomain: 'kulinaristen-app.firebaseapp.com',
  databaseURL: 'https://kulinaristen-app.firebaseio.com',
  projectId: 'kulinaristen-app',
  storageBucket: 'kulinaristen-app.appspot.com',
  messagingSenderId: '379095208187',
  appId: '1:379095208187:web:788626f22a8bb40c',
};

export const ambassador = {
  apiKey: 'AIzaSyA5kIcy1Q5_s5B2R9NMcS4sMtTbDhfTMGc',
  authDomain: 'ambassador-app-35557.firebaseapp.com',
  databaseURL: 'https://ambassador-app-35557.firebaseio.com',
  projectId: 'ambassador-app-35557',
  storageBucket: 'ambassador-app-35557.appspot.com',
  messagingSenderId: '818455433040',
};

export const yoga = {
  apiKey: 'AIzaSyB4fFjOjXBSpdS34jtfqVlgaXIKdxPK590',
  authDomain: 'bdyoga-b31ed.firebaseapp.com',
  databaseURL: 'https://bdyoga-b31ed-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bdyoga-b31ed',
  storageBucket: 'bdyoga-b31ed.appspot.com',
  messagingSenderId: '560107788481',
  appId: '1:560107788481:web:79f366584a8d65c8575c40',
};

export const limconnect = {
  apiKey: 'AIzaSyCajAf3o0KDGBm1g8RF2FheMJaIsKXfPCY',
  authDomain: 'limconnect-8d596.firebaseapp.com',
  databaseURL: 'https://limconnect-8d596.firebaseio.com',
  projectId: 'limconnect-8d596',
  storageBucket: 'limconnect-8d596.appspot.com',
  messagingSenderId: '103905881453',
  appId: '1:103905881453:web:8c1f2a6859d8196a117e64',
  measurementId: 'G-SL0F665EDN',
};

export const soroptimist = {
  apiKey: 'AIzaSyDCd2AxZvWTqzv8JhBS0TBFCyS5gLUG7rE',
  authDomain: 'soroptimist-netzwerk.firebaseapp.com',
  databaseURL: 'https://soroptimist-netzwerk-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'soroptimist-netzwerk',
  storageBucket: 'soroptimist-netzwerk.appspot.com',
  messagingSenderId: '28432748968',
  appId: '1:28432748968:web:2ddb4941ab779f64538d9d',
  measurementId: 'G-7YE23JGZGH',
};

export const connact = {
  apiKey: 'AIzaSyDb04_rDfdpR3j3yjeA4NpYMHnbD2T-9Fk',
  authDomain: 'corpsconnectdemo.firebaseapp.com',
  databaseURL: 'https://corpsconnectdemo.firebaseio.com',
  projectId: 'corpsconnectdemo',
  storageBucket: 'corpsconnectdemo.appspot.com',
  messagingSenderId: '474817578201',
  appId: '1:474817578201:web:15a4b9bd3c5e3630872ac8',
};

export const corpsconnect = {
  apiKey: 'AIzaSyBNrJ3BC9aui0OG06xy59Zg5HNd1fgO2l4',
  authDomain: 'corpsconnect-5ec9f.firebaseapp.com',
  databaseURL: 'https://corpsconnect-5ec9f.firebaseio.com',
  projectId: 'corpsconnect-5ec9f',
  storageBucket: 'corpsconnect-5ec9f.appspot.com',
  messagingSenderId: '415632066522',
  appId: '1:415632066522:web:bdb5a848b79ccda9d962e3',
};

export const obotritia = {
  apiKey: 'AIzaSyAj6DMH5egYpKqzTiCVbKo_E-tw9A6A5tU',
  authDomain: 'obotritia-561d1.firebaseapp.com',
  databaseURL: 'https://obotritia-561d1-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'obotritia-561d1',
  storageBucket: 'obotritia-561d1.appspot.com',
  messagingSenderId: '623530494576',
  appId: '1:623530494576:web:b4bcb8f0cd7058dcea42b5',
};

export const bvs = {
  apiKey: 'AIzaSyDPVRg9J7EHyDHF8hJRpwebvWv-2ignGhQ',
  authDomain: 'bvs-app-1d4b9.firebaseapp.com',
  databaseURL: 'https://bvs-app-1d4b9-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bvs-app-1d4b9',
  storageBucket: 'bvs-app-1d4b9.appspot.com',
  messagingSenderId: '786642374906',
  appId: '1:786642374906:web:fd6035a911712d3b9e3943',
};

export const asbh = {
  apiKey: 'AIzaSyBFh4zE7OnPHFAgciQtP2r8VUgZQLCH8k4',
  authDomain: 'asbh-a17a4.firebaseapp.com',
  databaseURL: 'https://asbh-a17a4-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'asbh-a17a4',
  storageBucket: 'asbh-a17a4.appspot.com',
  messagingSenderId: '972472476544',
  appId: '1:972472476544:web:72b0ba252c267b1bb65765',
};

export const korponnect = {
  apiKey: 'AIzaSyDlKTv6yhhABbaAp_ORXOh1T7yvTOXpbFI',
  authDomain: 'tramizu-eaa7e.firebaseapp.com',
  databaseURL: 'https://tramizu-eaa7e.firebaseio.com',
  projectId: 'tramizu-eaa7e',
  storageBucket: 'tramizu-eaa7e.appspot.com',
  messagingSenderId: '514472298216',
  appId: '1:514472298216:web:08aaf1a079e8cf771c2b5d',
  measurementId: 'G-C9ME07Z37T',
};

export const metallverband = {
  apiKey: 'AIzaSyAtSVphqEWIWMfzA_2lQu9XRdrrJC3yiOQ',
  authDomain: 'metallverband-rlp.firebaseapp.com',
  databaseURL: 'https://metallverband-rlp-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'metallverband-rlp',
  storageBucket: 'metallverband-rlp.appspot.com',
  messagingSenderId: '511796847187',
  appId: '1:511796847187:web:285f8c55ab7f2d05871ca6',
  measurementId: 'G-H6T8DS0JS0',
};

export const keb = {
  apiKey: 'AIzaSyB6Nh2qN_BTNPuwJ1y_jxSl4ILEsbCY88k',
  authDomain: 'keb-app.firebaseapp.com',
  databaseURL: 'https://keb-app-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'keb-app',
  storageBucket: 'keb-app.appspot.com',
  messagingSenderId: '607107879854',
  appId: '1:607107879854:web:bafca7f1f70b660e24ee3f',
};

export const awo = {
  apiKey: 'AIzaSyBr1YHxw3H_asE0fPylUfQ77105nKjtSt4',
  authDomain: 'awo-pfalz.firebaseapp.com',
  databaseURL: 'https://awo-pfalz-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'awo-pfalz',
  storageBucket: 'awo-pfalz.appspot.com',
  messagingSenderId: '992828879478',
  appId: '1:992828879478:web:828bfad6ea25d9ef746557',
};

export const vbrk = {
  apiKey: 'AIzaSyA9km138i_rjKp4gq6YyX4XUMYFvAWrkH4',
  authDomain: 'vbrk-app.firebaseapp.com',
  databaseURL: 'https://vbrk-app-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'vbrk-app',
  storageBucket: 'vbrk-app.appspot.com',
  messagingSenderId: '842809280081',
  appId: '1:842809280081:web:b25ad97906366b2141da70',
};

export const bayern = {
  apiKey: 'AIzaSyBw-WxHokoveWnqE1OysMZ46Txwz9MkbCA',
  authDomain: 'bayern-ec4ae.firebaseapp.com',
  databaseURL: 'https://bayern-ec4ae-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bayern-ec4ae',
  storageBucket: 'bayern-ec4ae.appspot.com',
  messagingSenderId: '92604842357',
  appId: '1:92604842357:web:0dfb7fbce2bad248b6133c',
};

export const albertina = {
  apiKey: 'AIzaSyCovLRqQeqUJpO2kWg4ogsEf19bbJkGi54',
  authDomain: 'corps-albertina.firebaseapp.com',
  databaseURL: 'https://corps-albertina-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'corps-albertina',
  storageBucket: 'corps-albertina.appspot.com',
  messagingSenderId: '287932655164',
  appId: '1:287932655164:web:b04b0e537f206571fb0539',
};

export const aps = {
  apiKey: 'AIzaSyBXgXb6ip64NaX7q1uKSzqQnT-plfzUmpA',
  authDomain: 'aktionsbundnis-patient.firebaseapp.com',
  databaseURL: 'https://aktionsbundnis-patient-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'aktionsbundnis-patient',
  storageBucket: 'aktionsbundnis-patient.appspot.com',
  messagingSenderId: '134013920404',
  appId: '1:134013920404:web:0af27243c0c9cfcd31c081',
};

export const csb = {
  apiKey: 'AIzaSyDht_VuX0ks-qv9gQKMqL7uq6Z3pnou_Sg',
  authDomain: 'corps-saxo-borussia.firebaseapp.com',
  databaseURL: 'https://corps-saxo-borussia-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'corps-saxo-borussia',
  storageBucket: 'corps-saxo-borussia.appspot.com',
  messagingSenderId: '113076503161',
  appId: '1:113076503161:web:b26986250f6c8e1ea00aa8',
};

export const bdvb = {
  apiKey: 'AIzaSyB_7bF_8KWL7NSQteL-yibclqOAzq10jS0',
  authDomain: 'bdvb-def33.firebaseapp.com',
  databaseURL: 'https://bdvb-def33-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bdvb-def33',
  storageBucket: 'bdvb-def33.appspot.com',
  messagingSenderId: '434219558134',
  appId: '1:434219558134:web:0c1c3948086c2cbdb938d7',
};

export const thuringia = {
  apiKey: 'AIzaSyApWZ7pYP0TyuqIT1MH8DSiEu6zLkFBBcA',
  authDomain: 'corps-thuringia.firebaseapp.com',
  databaseURL: 'https://corps-thuringia-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'corps-thuringia',
  storageBucket: 'corps-thuringia.appspot.com',
  messagingSenderId: '865669739451',
  appId: '1:865669739451:web:19558a559bf580afa50a9e',
};

export const kiwanis = {
  apiKey: 'AIzaSyAnfyNpVgYfJ7_rDvavh5n6mUxX5SmuqJ0',
  authDomain: 'kiwanis-deutschland.firebaseapp.com',
  databaseURL: 'https://kiwanis-deutschland-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'kiwanis-deutschland',
  storageBucket: 'kiwanis-deutschland.appspot.com',
  messagingSenderId: '1052402613962',
  appId: '1:1052402613962:web:1ca75cf875c94810357e33',
};

export const gdl = {
  apiKey: 'AIzaSyAjCqOMaPIyr0q6Vm41W_g4geYp6-JOvlY',
  authDomain: 'gdl-app-4bfa8.firebaseapp.com',
  databaseURL: 'https://gdl-app-4bfa8-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'gdl-app-4bfa8',
  storageBucket: 'gdl-app-4bfa8.appspot.com',
  messagingSenderId: '324128959840',
  appId: '1:324128959840:web:3fc34454534941bbf58205',
};

export const cbs = {
  apiKey: 'AIzaSyCCuXTLUxKyWpR9diqMsK4dafmUMxFz3ZE',
  authDomain: 'cbs-app-19cf6.firebaseapp.com',
  databaseURL: 'https://cbs-app-19cf6-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'cbs-app-19cf6',
  storageBucket: 'cbs-app-19cf6.appspot.com',
  messagingSenderId: '512942291761',
  appId: '1:512942291761:web:1351bc7cdde9c8ee6c8e10',
};

export const uhh = {
  apiKey: 'AIzaSyDVRHom3DkYEdJUPyYrS6f1w3KmucQl1LE',
  authDomain: 'alumni-uhh.firebaseapp.com',
  projectId: 'alumni-uhh',
  databaseURL: 'https://alumni-uhh-default-rtdb.europe-west1.firebasedatabase.app',
  storageBucket: 'alumni-uhh.appspot.com',
  messagingSenderId: '298728883843',
  appId: '1:298728883843:web:9e49c282e535c8fe727db6',
};

export const eventus = {
  apiKey: 'AIzaSyDRTNJs3GTr-2k4JwSmJCp2n8W55oGTER4',
  authDomain: 'eventus-club.firebaseapp.com',
  databaseURL: 'https://eventus-club-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'eventus-club',
  storageBucket: 'eventus-club.appspot.com',
  messagingSenderId: '937045098930',
  appId: '1:937045098930:web:d82f06add58ada1cb324ab',
};

export const werteunion = {
  apiKey: 'AIzaSyDzfA8ieZTMUFq7VXZHU4E_uLYWCEPYq64',
  authDomain: 'werteunion-5dec8.firebaseapp.com',
  databaseURL: 'https://werteunion-5dec8-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'werteunion-5dec8',
  storageBucket: 'werteunion-5dec8.appspot.com',
  messagingSenderId: '488118900684',
  appId: '1:488118900684:web:35086e7a986de9165faf69',
};

export const unisono = {
  apiKey: 'AIzaSyBWoSmN_kLqCX_BW4hKRIMlgiW0Q3JMZNk',
  authDomain: 'unisono-699fa.firebaseapp.com',
  databaseURL: 'https://unisono-699fa-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'unisono-699fa',
  storageBucket: 'unisono-699fa.appspot.com',
  messagingSenderId: '386365319586',
  appId: '1:386365319586:web:d989d662f578bd6acaaafb',
};

export const wilkar = {
  apiKey: 'AIzaSyAB6GM99GL4fUi4IZvgGUAO61bRcG5nxI0',
  authDomain: 'wilkar-app.firebaseapp.com',
  databaseURL: 'https://wilkar-app-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'wilkar-app',
  storageBucket: 'wilkar-app.appspot.com',
  messagingSenderId: '272848375129',
  appId: '1:272848375129:web:08078e2cdede7b8b671048',
};

export const epoch = {
  apiKey: 'AIzaSyDSjeqmZKk2hoKVP3Vr9XwKsrDNoqEI2Gc',
  authDomain: 'epoch-times-community.firebaseapp.com',
  databaseURL: 'https://epoch-times-community-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'epoch-times-community',
  storageBucket: 'epoch-times-community.appspot.com',
  messagingSenderId: '276829722637',
  appId: '1:276829722637:web:36ea47437cbaa695db1d44',
};

export const gga = {
  apiKey: 'AIzaSyDqkPZ0Q6A05a7JlAZJDjskCwuubrbZk3w',
  authDomain: 'global-golf-association.firebaseapp.com',
  databaseURL: 'https://global-golf-association-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'global-golf-association',
  storageBucket: 'global-golf-association.appspot.com',
  messagingSenderId: '739848701175',
  appId: '1:739848701175:web:48907972e9d1d831b4bff2',
};

export const fib = {
  apiKey: 'AIzaSyBq-KR-FAV4ZHFYqg_yMKGwBoOF5NxSf5Q',
  authDomain: 'fibr-3b160.firebaseapp.com',
  databaseURL: 'https://fibr-3b160-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'fibr-3b160',
  storageBucket: 'fibr-3b160.appspot.com',
  messagingSenderId: '287814538367',
  appId: '1:287814538367:web:7bcc1848e7307bada61b4a',
};

export const tlv = {
  apiKey: 'AIzaSyBv5Zd0E1rd_RqKaOBsMK7ok-Nyp4uJgk4',
  authDomain: 'tlv-app-960a2.firebaseapp.com',
  databaseURL: 'https://tlv-app-960a2-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'tlv-app-960a2',
  storageBucket: 'tlv-app-960a2.appspot.com',
  messagingSenderId: '475500295073',
  appId: '1:475500295073:web:8011ec43401b26186251e0',
};

export const jmg = {
  apiKey: 'AIzaSyCBGB_bjk9_5Lei4q1npHHfTMuE98ogmFA',
  authDomain: 'marbella-golf.firebaseapp.com',
  databaseURL: 'https://marbella-golf-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'marbella-golf',
  storageBucket: 'marbella-golf.appspot.com',
  messagingSenderId: '98891616376',
  appId: '1:98891616376:web:e06df7e05320f1080c1d8d',
};

export const cfa = {
  apiKey: 'AIzaSyDQdYHCZ5Vsm_-174URz-XCbITYInNhkjU',
  authDomain: 'cfa-society.firebaseapp.com',
  databaseURL: 'https://cfa-society-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'cfa-society',
  storageBucket: 'cfa-society.appspot.com',
  messagingSenderId: '851020140748',
  appId: '1:851020140748:web:7fb16dc4188fd295fe7269',
};

export const bvsbayern = {
  apiKey: 'AIzaSyB-hQ0mjl8EcSAqbt2dwQ4RQPXwrUx7OAU',
  authDomain: 'bvsbayern-93e52.firebaseapp.com',
  databaseURL: 'https://bvsbayern-93e52-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bvsbayern-93e52',
  storageBucket: 'bvsbayern-93e52.appspot.com',
  messagingSenderId: '725830313707',
  appId: '1:725830313707:web:7ad24732f582bf13a540ae',
};

export const soroptimistat = {
  apiKey: 'AIzaSyB0mRD1cRNcd2mZd-oyoFrbwYKABJZOl9s',
  authDomain: 'soroptimist-osterreich.firebaseapp.com',
  databaseURL: 'https://soroptimist-osterreich-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'soroptimist-osterreich',
  storageBucket: 'soroptimist-osterreich.appspot.com',
  messagingSenderId: '833601336232',
  appId: '1:833601336232:web:70ea161f6948ff3e45a39d',
};

export const ihk = {
  apiKey: 'AIzaSyBC52LwULcV-HDyY5aokqzix2QRhOEnH8I',
  authDomain: 'ihk-lahn-dill.firebaseapp.com',
  databaseURL: 'https://ihk-lahn-dill-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'ihk-lahn-dill',
  storageBucket: 'ihk-lahn-dill.appspot.com',
  messagingSenderId: '275527269432',
  appId: '1:275527269432:web:42419b3f21d13ffd54facd',
};

export const dphv = {
  apiKey: 'AIzaSyCG6lnBqqyNu0sGAOSfciGDhJBqux1dlDo',
  authDomain: 'dphv-netzwerk.firebaseapp.com',
  databaseURL: 'https://dphv-netzwerk-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'dphv-netzwerk',
  storageBucket: 'dphv-netzwerk.appspot.com',
  messagingSenderId: '826581705817',
  appId: '1:826581705817:web:9f511649b1d79c5e867d48',
};

export const dbfk = {
  apiKey: 'AIzaSyCmsSOYxDtD6K6FsZppVSd8xHXJA0vUusc',
  authDomain: 'dbfk-db0a3.firebaseapp.com',
  databaseURL: 'https://dbfk-db0a3-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'dbfk-db0a3',
  storageBucket: 'dbfk-db0a3.appspot.com',
  messagingSenderId: '509851334477',
  appId: '1:509851334477:web:96c46f5c91d8cc2ad7a904',
};

export const rhenania = {
  apiKey: 'AIzaSyDyNcFs8GxEFJDY6zAcrlb4bBd2aYAt7_8',
  authDomain: 'corps-rhenania.firebaseapp.com',
  databaseURL: 'https://corps-rhenania-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'corps-rhenania',
  storageBucket: 'corps-rhenania.appspot.com',
  messagingSenderId: '608508132198',
  appId: '1:608508132198:web:c245394eec5e94b02426a7',
};

export const bsb = {
  apiKey: 'AIzaSyCymuyCbfyTMEaPfJDaIHNabxf_M2dfuOU',
  authDomain: 'bsb-app-a78fe.firebaseapp.com',
  databaseURL: 'https://bsb-app-a78fe-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bsb-app-a78fe',
  storageBucket: 'bsb-app-a78fe.appspot.com',
  messagingSenderId: '497994685194',
  appId: '1:497994685194:web:5d5adce0e2db65411ef8f3',
};

export const rlp = {
  apiKey: 'AIzaSyCIdqJqJqBclaqTkHebe_3nRcPe3d9qVsI',
  authDomain: 'chorverband-rlp.firebaseapp.com',
  databaseURL: 'https://chorverband-rlp-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'chorverband-rlp',
  storageBucket: 'chorverband-rlp.appspot.com',
  messagingSenderId: '367036398745',
  appId: '1:367036398745:web:04cda162a9d76d7f67cc08',
};

export const hda = {
  apiKey: 'AIzaSyB4bqYRpwVTzH6TU9jzPTrK0ag8MI3YdzQ',
  authDomain: 'hda-alumni.firebaseapp.com',
  databaseURL: 'https://hda-alumni-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'hda-alumni',
  storageBucket: 'hda-alumni.appspot.com',
  messagingSenderId: '682558035648',
  appId: '1:682558035648:web:c71c533fd036698bf7ff25',
};

export const kiwanisnl = {
  apiKey: 'AIzaSyD8mIiY5lzwamRerA1BTBICMnKpNFRVr_E',
  authDomain: 'kiwanis-nl.firebaseapp.com',
  databaseURL: 'https://kiwanis-nl-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'kiwanis-nl',
  storageBucket: 'kiwanis-nl.appspot.com',
  messagingSenderId: '35322001272',
  appId: '1:35322001272:web:2650d853f227d6d8caba8d',
};

export const antonius = {
  apiKey: 'AIzaSyC838AWCi2fL5rm2CTu8FxIgmhUBZhkV7E',
  authDomain: 'antonius-767fc.firebaseapp.com',
  databaseURL: 'https://antonius-767fc-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'antonius-767fc',
  storageBucket: 'antonius-767fc.appspot.com',
  messagingSenderId: '278944136539',
  appId: '1:278944136539:web:2da88011b69db09cc0ae7e',
};

export const mofa = {
  apiKey: 'AIzaSyAvVrTUSmfw6jkN-16Ia8ar-H129QF3J3U',
  authDomain: 'mofa-61965.firebaseapp.com',
  databaseURL: 'https://mofa-61965-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'mofa-61965',
  storageBucket: 'mofa-61965.appspot.com',
  messagingSenderId: '288360243576',
  appId: '1:288360243576:web:439771464f0fed3c90288d',
};

export const badw = {
  apiKey: 'AIzaSyB4xpNYBAI6ln9sXE9IXqH0quJ9eAUd5IU',
  authDomain: 'badw-f7f54.firebaseapp.com',
  databaseURL: 'https://badw-f7f54-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'badw-f7f54',
  storageBucket: 'badw-f7f54.appspot.com',
  messagingSenderId: '196751580080',
  appId: '1:196751580080:web:66fda8dc2fe75b33845475',
};

export const bsm = {
  apiKey: 'AIzaSyCWUupm-nc_AmoBbyQs-jS76Ush5jroWvM',
  authDomain: 'bsm-ev.firebaseapp.com',
  databaseURL: 'https://bsm-ev-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bsm-ev',
  storageBucket: 'bsm-ev.appspot.com',
  messagingSenderId: '553386428229',
  appId: '1:553386428229:web:185ad90e3969be5e44602c',
};
export const dbv = {
  apiKey: 'AIzaSyCUjYk9wUMjyxhoe-B-6KaxCRn1A9hjIPA',
  authDomain: 'dbv-app-56ed6.firebaseapp.com',
  databaseURL: 'https://dbv-app-56ed6-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'dbv-app-56ed6',
  storageBucket: 'dbv-app-56ed6.appspot.com',
  messagingSenderId: '633275167290',
  appId: '1:633275167290:web:1afa954f9afcfb12107b03',
};
export const ihkfrankfurt = {
  apiKey: 'AIzaSyD91DaMrKRNn8i7GXCdVcIuZAqdP4srTPg',
  authDomain: 'ihk-frankfurt.firebaseapp.com',
  databaseURL: 'https://ihk-frankfurt-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'ihk-frankfurt',
  storageBucket: 'ihk-frankfurt.appspot.com',
  messagingSenderId: '684951774238',
  appId: '1:684951774238:web:838bcfdb9bb285dfc63a0f',
};
export const bawu = {
  apiKey: 'AIzaSyDTnoYNiQHWdR_rdzTbocHvdGNwZieQMJQ',
  authDomain: 'landesjagdverband-bawu.firebaseapp.com',
  databaseURL: 'https://landesjagdverband-bawu-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'landesjagdverband-bawu',
  storageBucket: 'landesjagdverband-bawu.appspot.com',
  messagingSenderId: '149279084895',
  appId: '1:149279084895:web:3a32398f46bb6bda3c26ea',
};
export const lgf = {
  apiKey: 'AIzaSyCN4BHiOeajC5yRD2snedarFt0z3FSbmPQ',
  authDomain: 'learn-german-fast-249db.firebaseapp.com',
  databaseURL: 'https://learn-german-fast-249db-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'learn-german-fast-249db',
  storageBucket: 'learn-german-fast-249db.appspot.com',
  messagingSenderId: '434357429472',
  appId: '1:434357429472:web:4ee0eb4f5755aafde41c85',
};
export const mlu = {
  apiKey: 'AIzaSyCLRyDZNcxuL22wMF8l0_xVbMFa2QLUwNY',
  authDomain: 'alumni-halenses.firebaseapp.com',
  databaseURL: 'https://alumni-halenses-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'alumni-halenses',
  storageBucket: 'alumni-halenses.appspot.com',
  messagingSenderId: '570388653398',
  appId: '1:570388653398:web:2a123aa7576990175a62f7',
};
export const nrw = {
  apiKey: 'AIzaSyC325S9q11Q87rLx02yOnssa4iBXnbP_Io',
  authDomain: 'landesjagdverband-nrw.firebaseapp.com',
  databaseURL: 'https://landesjagdverband-nrw-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'landesjagdverband-nrw',
  storageBucket: 'landesjagdverband-nrw.appspot.com',
  messagingSenderId: '204211114790',
  appId: '1:204211114790:web:47bfba072983327f2be7e7',
};

export const dbp = {
  apiKey: 'AIzaSyBAu1bUCdBOJmnpnTAfHZ2QKlQB0F3DJls',
  authDomain: 'bdp-app-4f972.firebaseapp.com',
  databaseURL: 'https://bdp-app-4f972-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'bdp-app-4f972',
  storageBucket: 'bdp-app-4f972.appspot.com',
  messagingSenderId: '743638094393',
  appId: '1:743638094393:web:3f623d91228876e8f0b3e8',
};

export const sherides = {
  apiKey: 'AIzaSyB98vbMSn2OV_VXLeHIGemm1l_hhd4NW5U',
  authDomain: 'sherides-2d1b3.firebaseapp.com',
  databaseURL: 'https://sherides-2d1b3-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'sherides-2d1b3',
  storageBucket: 'sherides-2d1b3.appspot.com',
  messagingSenderId: '66999152846',
  appId: '1:66999152846:web:6a5a11697f7fe2c532209f',
};

export const dve = {
  apiKey: 'AIzaSyDdbJ8yjqOu0w-bOqw60E5kPlLTm_YG-bA',
  authDomain: 'dve-app.firebaseapp.com',
  databaseURL: 'https://dve-app-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'dve-app',
  storageBucket: 'dve-app.appspot.com',
  messagingSenderId: '302326111645',
  appId: '1:302326111645:web:36195f2e9c0a8b5338a374',
};

export const muettergenesungswerk = {
  apiKey: 'AIzaSyCiZXkx8oLXQcR-oJw9Mh0c4Gr-1kGzDUw',
  authDomain: 'muettergenesungswerk-8bb5a.firebaseapp.com',
  databaseURL: 'https://muettergenesungswerk-8bb5a-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'muettergenesungswerk-8bb5a',
  storageBucket: 'muettergenesungswerk-8bb5a.appspot.com',
  messagingSenderId: '491398206594',
  appId: '1:491398206594:web:9d3732521ca45e445ad09b',
};

export const mpg = {
  apiKey: 'AIzaSyBjy9dTaYYQ4DSMoWYFK4Q-3QHvZYyeydU',
  authDomain: 'mpg-app-3f3c5.firebaseapp.com',
  databaseURL: 'https://mpg-app-3f3c5-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'mpg-app-3f3c5',
  storageBucket: 'mpg-app-3f3c5.appspot.com',
  messagingSenderId: '196134095933',
  appId: '1:196134095933:web:dacb53ba039b6601e0ae54',
};

export const ljn = {
  apiKey: 'AIzaSyBlTPnCSy70_bxyr6lF701qKkg5oHTVZ4g',
  authDomain: 'landesjagerschaft.firebaseapp.com',
  databaseURL: 'https://landesjagerschaft-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'landesjagerschaft',
  storageBucket: 'landesjagerschaft.appspot.com',
  messagingSenderId: '1069345701465',
  appId: '1:1069345701465:web:4bf441f3a4d6db78c0bb48',
};

export const wallpen = {
  apiKey: 'AIzaSyB49lhd-WQAF8CG7EoJM-8b2wJJaP4F9XE',
  authDomain: 'wallpen-hub.firebaseapp.com',
  databaseURL: 'https://wallpen-hub-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'wallpen-hub',
  storageBucket: 'wallpen-hub.appspot.com',
  messagingSenderId: '538898725124',
  appId: '1:538898725124:web:be475f8580baa2ec6e3851',
};

export const teutonia = {
  apiKey: 'AIzaSyC-hq9Sz5qYmeWf92mZzUtEZwb7S-2HaKI',
  authDomain: 'fb--teutonia.firebaseapp.com',
  databaseURL: 'https://fb--teutonia-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'fb--teutonia',
  storageBucket: 'fb--teutonia.appspot.com',
  messagingSenderId: '806588268162',
  appId: '1:806588268162:web:07ac1fd0009bf7ba20be9b',
};

export const begabungslust = {
  apiKey: 'AIzaSyAjUdQfjBTDw92xLUFjvaIeq7H-iqQClO4',
  authDomain: 'begabungslust.firebaseapp.com',
  databaseURL: 'https://begabungslust-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'begabungslust',
  storageBucket: 'begabungslust.appspot.com',
  messagingSenderId: '97956342035',
  appId: '1:97956342035:web:ccc9318903f3b5b56412e6',
};

export const hmv = {
  apiKey: 'AIzaSyAD7nSFK5zCXoj04o1Ggx5UE71yK0aAkMI',
  authDomain: 'hmv-app-2a528.firebaseapp.com',
  databaseURL: 'https://hmv-app-2a528-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'hmv-app-2a528',
  storageBucket: 'hmv-app-2a528.appspot.com',
  messagingSenderId: '62089989629',
  appId: '1:62089989629:web:8f7b09606253835a2edd94',
};

export const staging = {
  apiKey: 'AIzaSyDcAGF4SROcytfoj7XNHAAbKUjAZ0p5Jn8',
  authDomain: 'staging-32a3a.firebaseapp.com',
  databaseURL: 'https://staging-32a3a-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'staging-32a3a',
  storageBucket: 'staging-32a3a.firebasestorage.app',
  messagingSenderId: '234895730534',
  appId: '1:234895730534:web:dc3eef139c02b4c7e9caa7',
};

export const dasrad = {
  apiKey: 'AIzaSyDTzOzkBbdG7qELNd8H8LBZGj8SrWcjjrA',
  authDomain: 'das-rad-e3bca.firebaseapp.com',
  databaseURL: 'https://das-rad-e3bca-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'das-rad-e3bca',
  storageBucket: 'das-rad-e3bca.firebasestorage.app',
  messagingSenderId: '542392248978',
  appId: '1:542392248978:web:af5c6b01ebe3af50c1544b',
};
