import React, { FC, useEffect, useState } from 'react';
import { getConfig } from '../../../config/config';
import { useLanguage } from '../../../languages/languageContext';
import { becomeMentee, becomeMentor, mentoringDeleteProfile, mentoringUpdateProfile } from '../../../api/mentoring';
import Loader from '../../../components/UI/Loader';
import { useAppDispatch, useAppSelector } from '../../../store';
import { showResponse } from '../../../store/actions/response';
import { getOwnerProfileRequest } from '../../../store/actions/users';
import { MentorBranches } from '../components/MentorBranches';
import { SelectMentoringState } from '../../../store/reducers/mentoring';
import TouchableInput from 'components/UI/TouchableInput';
import { Delete } from '@material-ui/icons';
import { CheckboxUI } from 'components/UI/CheckboxUI';
import { Box, ButtonBase, Switch } from '@material-ui/core';
import Button from 'components/UI/Button';
import SelectModal from 'components/Shared/SelectModal';
import { mediaActions, selectMediaState } from 'store/reducers/mediaSlice';
import { UploadMedia } from 'components/Shared/UploadMedia';
import AlertModal from 'components/Shared/AlertModal';
import { useHistory } from 'react-router';
import { MultipleSelectModal } from 'components/Shared/MultipleSelectModal';
import { Dropdown } from 'components/UI/Dropdown';

type modalType = 'isRegionsModalOpen' | 'isDepartmentModalOpen' | 'isTopicsModalOpen';
type itemTypes = 'mentoringRegions' | 'topics' | 'departments';

const rulesUri = 'https://files.connact.app/Werteverstaendnis_Mentoring_KASconnect.pdf';

export const MentoringProfileScreen: FC = () => {
  const history = useHistory();
  const { documents } = useAppSelector(selectMediaState);
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.users.user);
  const userIsLoading = useAppSelector((state) => state.users.userIsLoading);
  const { allow_mentoring_edit } = useAppSelector((state) => state.common);
  const { btn, mentoring, logoutAlert, mentoringScreen } = useLanguage();
  const { profile } = mentoring;
  const [state, setState] = useState({
    mentoringRegions: user.mentoringRegions || [],
    topics: user.topics || [],
    departments: user.departments || [],
    mentoringPolicyAccepted: config.BVS_MENTORING ? true : user.mentoringPolicyAccepted || false,
    mentoringActive: user.mentoringActive || false,
    isMentor: user.isMentor || false,
    mentorPdf: user.mentorPdf || '',
  });
  const [modalState, setModalState] = useState({
    isRegionsModalOpen: false,
    isTopicsModalOpen: false,
    isDepartmentModalOpen: false,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);

  const loading = stateLoading || userIsLoading;

  const {
    topics: topicsData,
    departments: departmentsData,
    mentoringRegions: regionsData,
  } = useAppSelector(SelectMentoringState).data;
  const { isMentor } = state;

  const disableTheme = config.BVS_MENTORING ? false : isMentor && !state.topics.length;
  const submitDisabled = !(!state.mentoringRegions.length || disableTheme || !state.mentoringPolicyAccepted);
  const disableMentoringPolicy = user.mentoringPolicyAccepted && user.mentoringRegions.length;

  useEffect(() => {
    setState({
      mentoringRegions: user.mentoringRegions || [],
      topics: user.topics || [],
      departments: user.departments || [],
      mentoringPolicyAccepted: config.BVS_MENTORING ? true : user.mentoringPolicyAccepted || false,
      mentoringActive: user.mentoringActive || false,
      isMentor: user.isMentor || false,
      mentorPdf: user.mentorPdf || '',
    });
  }, [user]);

  useEffect(() => {
    uploadDocument();
  }, [documents]);

  const updateMentorPdf = async (mentorPdf: string = '') => {
    setStateLoading(true);
    const { isMentor, mentoringRegions, departments, topics, mentoringActive, mentoringPolicyAccepted } = user;
    const body = {
      isMentor,
      mentoringRegions,
      departments,
      topics,
      mentoringActive,
      mentorPdf,
      mentoringPolicyAccepted,
    };
    const res: any = await mentoringUpdateProfile(body);
    setState({ ...state, mentorPdf: res.mentorPdf });
    setStateLoading(false);
  };

  const submitProfile = async () => {
    try {
      setStateLoading(true);
      const { isMentor, mentoringRegions, departments, topics, mentoringActive, mentorPdf, mentoringPolicyAccepted } =
        state;
      const body = {
        isMentor,
        mentoringRegions,
        departments,
        topics,
        mentoringActive,
        mentorPdf,
        mentoringPolicyAccepted,
      };
      await mentoringUpdateProfile(body);
      dispatch(getOwnerProfileRequest(user._id));
      setStateLoading(false);
      dispatch(showResponse({ message: mentoringScreen.profileUpdated, title: mentoringScreen.mentoringProfile }));
    } catch (e) {
      console.log('ERROR ON MENTORING submitProfile', e);
      dispatch(showResponse({}));

      setStateLoading(false);
    }
  };

  function modalHandler(modal: modalType) {
    setModalState({ ...modalState, [modal]: !modalState[modal] });
  }

  const showPdfHandler = (uri: string) => {
    window.open(uri);
  };

  const modalSelectItemHandler = (value: string, type: itemTypes) => {
    let newItem = [] as string[];
    if (value === mentoringScreen.all) {
      if (type === 'departments') newItem = departmentsData;
      if (type === 'topics') newItem = topicsData;
      if (type === 'mentoringRegions') newItem = regionsData;
    }
    if (value !== mentoringScreen.all) newItem = [...state[type], value];
    setState({ ...state, [type]: newItem });
  };

  const deleteItemHandler = (value: string, type: itemTypes) => {
    let newItem;
    if (value === mentoringScreen.all) newItem = [];
    if (value !== mentoringScreen.all) newItem = state[type].filter((item) => item !== value);
    setState({ ...state, [type]: newItem });
  };

  const deleteProfile = async () => {
    try {
      setStateLoading(true);
      await mentoringDeleteProfile();
      dispatch(getOwnerProfileRequest(user._id));
      setStateLoading(false);
      setShowDeleteModal(false);
      history.push('/mentoring/info');
    } catch (e) {
      console.log('ERROR ON MENTORING deleteProfile', e);
    }
  };

  const deleteProfileHandler = () => setShowDeleteModal(!showDeleteModal);

  const setMentoring = async (value: 'mentee' | 'mentor') => {
    setStateLoading(true);
    value === 'mentee' && (await becomeMentee());
    value === 'mentor' && (await becomeMentor());
    dispatch(getOwnerProfileRequest(user._id));
    setStateLoading(false);
  };

  const uploadDocument = async () => {
    setStateLoading(true);

    if (documents[0]?.url?.length) {
      updateMentorPdf(documents[0].url);
      dispatch(mediaActions.deleteAllAttachments());
    }
    setStateLoading(false);
  };

  const AcceptRulesComponent = (
    <span>
      {profile.rules1}
      <a href="#" style={{ textDecoration: 'none' }} onClick={() => showPdfHandler(rulesUri)}>
        {profile.rules2}
      </a>
      {profile.rules3}
    </span>
  );

  const RenderItem = (item: string, type: itemTypes) => {
    return (
      <Box style={styles.itemContainer} key={item}>
        <Box style={styles.itemWrapper}>
          <span>{item}</span>
        </Box>
        <ButtonBase onClick={() => deleteItemHandler(item, type)} style={styles.deleteWrapper}>
          <Delete style={{ color: theme.ERROR_PRIMARY }} />
        </ButtonBase>
      </Box>
    );
  };

  const RenderMenteeBranch = ({ type = 'mentoringRegions' }: { type?: itemTypes }) => {
    if (isMentor) return <></>;
    let modalName: modalType = 'isRegionsModalOpen';

    return (
      <Box style={styles.inputContainer}>
        <TouchableInput onClick={() => modalHandler(modalName)} value={undefined} placeholder={profile[type]} />
        {state[type].map((item) => RenderItem(item, type))}
      </Box>
    );
  };

  return (
    <>
      <Box style={styles.container}>
        <span style={{ ...styles.descriptionText, fontWeight: 'bolder' }}>
          {isMentor ? profile.mentorDescription : profile.menteeDescription}
        </span>
        {config.KAS_MENTORING && (
          <Box style={{ left: -10 }}>
            <CheckboxUI
              label={AcceptRulesComponent}
              isChecked={state.mentoringPolicyAccepted}
              setIsChecked={() => setState({ ...state, mentoringPolicyAccepted: !state.mentoringPolicyAccepted })}
              disabled={disableMentoringPolicy}
            />
          </Box>
        )}
        {isMentor && (
          <Box style={styles.switchContainer}>
            <Switch
              onChange={() => setState({ ...state, mentoringActive: !state.mentoringActive })}
              checked={state.mentoringActive}
              color="primary"
            />

            <span style={styles.switchText}>{state.mentoringActive ? profile.available : profile.unavailable}</span>
          </Box>
        )}

        <div style={{ height: 90, marginTop: 20 }}>
          <Dropdown
            disabled={!allow_mentoring_edit}
            value={isMentor ? mentoringScreen.mentor : mentoringScreen.mentee}
            data={[
              { label: mentoringScreen.mentor, onPress: async () => setMentoring('mentor'), selected: isMentor },
              { label: mentoringScreen.mentee, onPress: async () => setMentoring('mentee'), selected: !isMentor },
            ]}
          />
        </div>

        <RenderMenteeBranch />
        <MentorBranches
          type="mentoringRegions"
          deleteItemHandler={deleteItemHandler}
          state={state}
          modalHandler={modalHandler}
          isMentor={isMentor}
          renderBranch={isMentor}
          isProfile
        />
        {config.KAS_MENTORING && (
          <MentorBranches
            type="topics"
            deleteItemHandler={deleteItemHandler}
            state={state}
            modalHandler={modalHandler}
            isMentor={isMentor}
            renderBranch={isMentor}
            isProfile
          />
        )}
        <MentorBranches
          type="departments"
          deleteItemHandler={deleteItemHandler}
          state={state}
          modalHandler={modalHandler}
          isMentor={isMentor}
          renderBranch={isMentor}
          isProfile
        />
        <Box
          style={{
            width: 300,
            margin: 'auto',
          }}
        >
          {isMentor && (
            <>
              {state.mentorPdf.length ? (
                <Box
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignSelf: 'flex-end',
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Box style={{ width: '75%', maxWidth: 280 }}>
                    <Button label={mentoring.profile.myPdf} onClick={() => showPdfHandler(state.mentorPdf)} />
                  </Box>
                  <ButtonBase
                    onClick={() => updateMentorPdf('')}
                    style={{ ...styles.deleteWrapper, width: 48, height: 48 }}
                  >
                    <Delete style={{ color: theme.ERROR_PRIMARY }} />
                  </ButtonBase>
                </Box>
              ) : (
                <UploadMedia viewType="button" addPictureDisabled />
              )}
            </>
          )}
          <Button label={profile.deleteAccount} onClick={deleteProfileHandler} width={300} />
          <Button label={btn.saveBtn} disabled={submitDisabled} onClick={submitProfile} width={300} />
        </Box>
      </Box>
      <MultipleSelectModal
        showModal={modalState.isRegionsModalOpen}
        modalTitle={profile.mentoringRegions}
        modalHandler={() => modalHandler('isRegionsModalOpen')}
        selectItemHandler={(value: string) => modalSelectItemHandler(value, 'mentoringRegions')}
        deleteItemHandler={(value: string) => deleteItemHandler(value, 'mentoringRegions')}
        data={isMentor ? [mentoringScreen.all, ...regionsData] : regionsData}
        values={state.mentoringRegions}
        disabled={!isMentor ? !!state.mentoringRegions.length : false}
      />
      <MultipleSelectModal
        showModal={modalState.isTopicsModalOpen}
        modalTitle={profile.topics}
        modalHandler={() => modalHandler('isTopicsModalOpen')}
        selectItemHandler={(value: string) => modalSelectItemHandler(value, 'topics')}
        deleteItemHandler={(value: string) => deleteItemHandler(value, 'topics')}
        data={[mentoringScreen.all, ...topicsData]}
        values={state.topics}
      />
      <MultipleSelectModal
        showModal={modalState.isDepartmentModalOpen}
        modalTitle={profile.departments}
        modalHandler={() => modalHandler('isDepartmentModalOpen')}
        selectItemHandler={(value: string) => modalSelectItemHandler(value, 'departments')}
        deleteItemHandler={(value: string) => deleteItemHandler(value, 'departments')}
        data={[mentoringScreen.all, ...departmentsData]}
        values={state.departments}
      />
      <AlertModal
        onClose={deleteProfileHandler}
        showModal={showDeleteModal}
        message={mentoring.profile.deleteAccountDescr}
        title={mentoringScreen.leaveMentoring}
      >
        <Button label={btn.cancelBtn} onClick={deleteProfileHandler} width={140} />
        <Button label={logoutAlert.confirm} onClick={deleteProfile} width={140} />
      </AlertModal>
      <Loader isLoading={loading} />
    </>
  );
};

const { theme, config } = getConfig();
const styles = {
  container: {
    flex: 1,
    marginHorizontal: 2,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: theme.BACKGROUND,
    padding: 15,
    paddingBottom: 100,
  },
  descriptionText: {
    color: theme.TEXT_PRIMARY,
    fontSize: 14,
    marginBottom: 25,
  },
  switchContainer: {
    margin: '15px 0',
  },
  switchText: {
    marginLeft: 12,
  },
  inputContainer: {
    marginBottom: 20,
  },
  inputWrapper: {
    marginTop: 30,
    flexDirection: 'row',
    alignItems: 'center',
  },
  deleteWrapper: {
    backgroundColor: theme.BACKGROUND_PRIMARY,
    borderColor: theme.BACKGROUND_SECONDARY,
    borderWidth: 1,
    padding: 8,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemWrapper: {
    backgroundColor: theme.BACKGROUND_PRIMARY,
    borderRadius: 20,
    border: `1px solid ${theme.BACKGROUND_SECONDARY}`,
    margin: 5,
    padding: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  itemContainer: {
    alignItems: 'center',
    padding: '0 5px',
    display: 'flex',
  },
};
