import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getContactsList, getUserContacts } from "api";
import { deleteContact } from "api/contacts";
import { UserType } from "store/types/usersTypes";



export const getUserContactsRequest = createAsyncThunk(
  'contacts/getUserContactsRequest',
  async ({userId, page, limit, searchkey}: { userId: string; page: number; limit: number, searchkey?: string }) =>
    await getUserContacts(userId, page, limit, searchkey)
);

export const getContactsListRequest = createAsyncThunk(
  'contacts/getAllContacts',
  async ({userId}: {userId: string}) => await getContactsList(userId)
)

export const deleteContacts = createAsyncThunk(
  'contacts/deleteContacts',
  async ({userId}: { userId: string }) =>{
    await deleteContact(userId)
    return userId;
  }
)


interface State {
  contacts: UserType[];
  contactsIsLoading: boolean;
  contactsCount: number;
  contactsList: any[];
}

const initialState: State = {
  contacts: [],
  contactsCount: null,
  contactsIsLoading: false,
  contactsList: [],
};



const contactsSlice = createSlice({
  name: 'contacts',
  initialState,

  reducers: {
    clearUserContacts: (state) => {
      state.contacts = [];
      state.contactsCount = null;
      state.contactsList = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserContactsRequest.pending, (state) => {
      state.contactsIsLoading = true
    })
    builder.addCase(getUserContactsRequest.fulfilled, (state, {payload}) => {
      state.contactsIsLoading = false;
      state.contactsCount = payload.total;
      state.contacts = payload.page > 1 ? state.contacts.concat(payload.data) : payload.data;
    })
    builder.addCase(getUserContactsRequest.rejected, (state) => {
      state.contactsIsLoading = false
    })
    builder.addCase(getContactsListRequest.pending, (state) => {
      state.contactsIsLoading = true
    })
    builder.addCase(getContactsListRequest.fulfilled, (state, {payload}) => {
      state.contactsIsLoading = false;
      state.contactsList = payload.data;
    })
    builder.addCase(getContactsListRequest.rejected, (state) => {
      state.contactsIsLoading = false
    })
    builder.addCase(deleteContacts.pending, (state) => {
      state.contactsIsLoading = true
    })
    builder.addCase(deleteContacts.fulfilled, (state, {payload}) => {
      state.contactsIsLoading = false;
      state.contacts = state.contacts.filter((contact) => contact._id!== payload);
    })
    builder.addCase(deleteContacts.rejected, (state) => {
      state.contactsIsLoading = false
    })
  }
})

export const contacts = contactsSlice.reducer;

export const {clearUserContacts} = contactsSlice.actions;