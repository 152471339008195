import React, { useState } from 'react';

import Input from '../../../components/UI/Input';
import { withConfig, Theme } from '../../../config';
import Button from '../../../components/UI/Button';
import { Config } from '../../../config/index';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import AlertModal from '../../../components/Shared/AlertModal';
import { ButtonBase, Icon } from '@material-ui/core';
import TouchableInput from '../../../components/UI/TouchableInput';
import { HighlightOff } from '@material-ui/icons';
import AutocompleteInput from '../../../components/UI/AutocompleteInput';
import { educationLevel } from '../../../utilities';
import { useLanguage } from 'languages/languageContext';

const styles = createStyles({
  container: {
    width: '420px',
  },
  updateButtonWraper: {
    marginTop: 20,
  },
  inputsWrapper: {
    padding: '15px 0 40px',
    position: 'relative',
  },
  addButtonContainer: {
    position: 'absolute',
    top: -25,
    right: 0,
    zIndex: 0,
  },
  buttonWrapper: {
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  iconWrapper: {
    width: 30,
    height: 30,
    borderRadius: 30,
    alignItems: 'center',
  },
  buttonsWrapper: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props extends WithStyles<typeof styles> {
  onRemoveItem: () => void;
  onChangeHandler: (e: string, i: number, name: string) => void;
  onSelectItem: () => void;
  modalHandler: (modalName: string) => void;
  elemIndex: number;
  lastElem: boolean;
  theme: Theme;
  hochschule: string;
  studiengang: string;
  abschluss: string;
  config: Config;
}

interface EducationObj {
  [key: string]: any;
  hochschule: boolean;
  studiengang: boolean;
  abschluss: boolean;
}

const EducationItem: React.FC<Props> = ({
  theme,
  config,
  onRemoveItem,
  onChangeHandler,
  modalHandler,
  hochschule,
  studiengang,
  abschluss,
  elemIndex,
  lastElem,
  classes,
}) => {
  const { profileStudy, btn, profileStudiumScreen } = useLanguage();
  const [showModal, setShowModal] = useState(false);
  const [focusObj, setFocusObj] = useState<EducationObj>({
    hochschule: false,
    studiengang: false,
    abschluss: false,
  });

  const focusHandler = (name: string) => {
    setFocusObj({
      ...focusObj,
      [name]: !focusObj[name],
    });
  };

  const firstElem = elemIndex === 0;

  const selectItem = (value: string) => {
    onChangeHandler(value, elemIndex, 'abschluss');
  };

  return (
    <>
      {!firstElem ? (
        <div className={classes.buttonWrapper}>
          <ButtonBase onClick={() => setShowModal(true)} disabled={false}>
            <div className={classes.iconWrapper}>
              {/* <Icon name="ios-close-circle" size={35} color={theme.BUTTON_SECONDARY} /> */}
              <HighlightOff
                style={{
                  color: theme.BUTTON_SECONDARY,
                  width: '100%',
                  height: '100%',
                  transform: 'rotate: 45deg',
                }}
                //   fontSize="inherit"
              />
            </div>
          </ButtonBase>
        </div>
      ) : null}
      <Input
        placeholder={profileStudy.universityLabel}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value, elemIndex, 'hochschule')}
        autoCapitalize="none"
        value={hochschule}
        isFocused={focusObj.hochschule}
        onBlur={() => focusHandler('hochschule')}
        onFocus={() => focusHandler('hochschule')}
      />
      <Input
        placeholder={profileStudy.courseLabel}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value, elemIndex, 'studiengang')}
        autoCapitalize="none"
        value={studiengang}
        isFocused={focusObj.studiengang}
        onBlur={() => focusHandler('studiengang')}
        onFocus={() => focusHandler('studiengang')}
      />

      {config.APP_NAME !== 'wilkar' && (
        <AutocompleteInput
          label={profileStudy.degreeLabel}
          value={abschluss}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeHandler(e.target.value, elemIndex, 'abschluss')}
          onSelect={selectItem}
          isFocused={focusObj.abschluss}
          onBlur={() => focusHandler('abschluss')}
          onFocus={() => focusHandler('abschluss')}
          placeholder={profileStudy.degreeLabel}
          items={educationLevel}
        />
      )}

      <div
        style={{
          backgroundColor: theme.BACKGROUND_SECONDARY,
          height: !lastElem ? 1 : 0,
          marginBottom: 20,
          marginTop: firstElem ? 0 : 20,
        }}
      />
      <AlertModal
        showModal={showModal}
        modalHandler={() => modalHandler('alert')}
        message={profileStudiumScreen.deleteConfirm}
      >
        <div className={classes.buttonsWrapper}>
          <Button label={btn.cancelBtn} background={theme.BUTTON_SECONDARY} onClick={() => setShowModal(false)} />
          <Button
            onClick={() => {
              modalHandler('alert');
              onRemoveItem();
            }}
            label={profileStudiumScreen.ok}
          />
        </div>
      </AlertModal>
    </>
  );
};

export default withStyles(styles)(withConfig(EducationItem));
