import React, { FC, useEffect, useState } from 'react';
import { withConfig, withAuth } from '../../config/';
import { withStyles, WithStyles, createStyles, CircularProgress } from '@material-ui/core';
import { getChatUsersRequest, markAsReadRequest } from '../../store/actions/chat';
import { DialogsProps } from './model';
import Layout from '../../components/UI/Layout';
import ScreenTitle from '../../components/Shared/ScreenTitle';
import EmptyList from '../../components/Shared/EmptyList';
import SearchBar from '../../components/Shared/SearchBar';
import DialogItem from './components/DialogItem';
import { useAppDispatch, useAppSelector } from 'store';
import { getConfig } from 'config/config';
import { useLanguage } from 'languages/languageContext';
import { Link } from 'react-router-dom';
import { IonIcon } from 'components/UI/IonIcon';
import { useCustomHistory } from 'hooks/useCustomHistory';

const { theme } = getConfig();

const styles = createStyles({
  container: {
    width: '100%',
    padding: '30px 0 30px 0',
  },
  inputWrapper: {
    padding: '10px 20px 0',
  },
  dialogsWrapper: {
    padding: '20px 0',
  },
  progressWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px 0',
  },
});

interface Props extends WithStyles<typeof styles>, DialogsProps {}

const Dialogs: FC<Props> = ({ classes }) => {
  const { screenTitles, dialogs } = useLanguage();
  const dispatch = useAppDispatch();
  const { push } = useCustomHistory();
  const getChatUsersIsLoading = useAppSelector((state) => state.chat.getChatUsersIsLoading);
  const chatUsers = useAppSelector((state) => state.chat.chatUsers);

  const [query, setQuery] = useState('');

  const filteredUsers = chatUsers?.filter((item: any) => item.name.toLowerCase().includes(query.toLowerCase()));
  const sortedUsers = filteredUsers?.sort((a: any, b: any) => b.lastMessage?.time - a.lastMessage?.time);

  useEffect(() => {
    dispatch(getChatUsersRequest(true));
  }, []);

  function onChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setQuery(value);
  }

  function clearSearchHandler() {
    setQuery('');
  }

  function markAsReadHandler(receiverUID: string, unreadMessages: Array<any>) {
    dispatch(markAsReadRequest(receiverUID, unreadMessages));
  }

  const usersList =
    sortedUsers?.length > 0 ? (
      sortedUsers.map((item: any, i) => <DialogItem key={item._id + i} user={item} markAsRead={markAsReadHandler} />)
    ) : (
      <EmptyList title={dialogs.emptyTitle} />
    );

  return (
    <Layout>
      <ScreenTitle title={screenTitles.messagesTitle} showAddIcon addEvent={() => push('/create-dialog')} />
      <div className={classes.container}>
        <div className={classes.inputWrapper}>
          <SearchBar query={query} onChange={onChangeHandler} clearSearch={clearSearchHandler} />
        </div>
        {getChatUsersIsLoading && !chatUsers?.length ? (
          <div className={classes.progressWrapper}>
            <CircularProgress style={{ color: theme.BUTTON_SECONDARY }} size={35} />
          </div>
        ) : (
          <div className={classes.dialogsWrapper}>{usersList}</div>
        )}
      </div>
    </Layout>
  );
};

export default withStyles(styles)(withAuth(withConfig(Dialogs)));
