import React, { useEffect, useState } from 'react';
import { MenuItem, Select, makeStyles } from '@material-ui/core';
import Loader from '../../components/UI/Loader';
import Button from '../../components/UI/Button';
import { showResponse } from '../../store/actions/response';
import { updateUserRequest } from '../../store/actions/users';
import { useLanguage } from 'languages/languageContext';
import { useAppDispatch, useAppSelector } from 'store';
import { selectCommonState } from 'store/reducers/common';
import { getConfig } from 'config/config';
import { BootstrapInput } from 'components/UI/MUISelect';

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    width: 420,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 20,
  },
  info: {
    fontSize: 18,
    // fontWeight: 'bolder',
    marginTop: 50,
    marginBottom: 10,
    textAlign: 'center',
  },
  inputsWrapper: {
    position: 'relative',
    marginBottom: 20,
  },
  optionsWrapper: {
    marginBottom: 50,
  },
  buttonWrapper: {},
  button: {
    marginTop: 30,
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      padding: '0 10px',
    },
  },
});

interface Props {}

export const privacyValues: { value: privacyValueTypes }[] = [{ value: 'ask' }, { value: 'yes' }, { value: 'no' }];

export type privacyValueTypes = 'yes' | 'no' | 'ask';

export const ProfilePrivacy: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { user, userIsLoading } = useAppSelector((state) => state.users);
  const {
    btn,
    profilePrivacyScreen,
    privacy: { settings },
  } = useLanguage();
  const classes = useStyles({});
  const initialState = privacyValues.find((el) => el.value === user?.privacy?.showPostsToGuests) || privacyValues[0];
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(initialState);
  }, [user]);

  const updateUserHandler = () => {
    const updatedUser = {
      ...user,
      privacy: {
        showPostsToGuests: state.value,
      },
    };

    if (user && user.name) {
      dispatch(updateUserRequest(updatedUser));
    } else if (user && !user.name) {
      showResponse({
        message: profilePrivacyScreen.fillData,
      });
    }
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <p className={classes.info} style={{ color: theme.TEXT_PRIMARY }}>
          {settings.guestPost.title}
        </p>
        <div className={classes.optionsWrapper}>
          <Select
            input={<BootstrapInput />}
            value={state.value}
            style={{ margin: '10px 0', width: '100%' }}
            onChange={(e: any) => {
              const {
                target: { value },
              } = e;
              setState({
                value,
                // label: privacyValues.find(el => el.value === value).label
              });
            }}
          >
            {privacyValues.map(({ value }) => (
              <MenuItem key={value} value={value}>
                {settings.guestPost[value]}
              </MenuItem>
            ))}
          </Select>
        </div>
        <Button label={btn.saveBtn} onClick={updateUserHandler} />
        <Loader isLoading={userIsLoading} />
      </div>
    </React.Fragment>
  );
};
