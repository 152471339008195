import { Button } from 'components/shadcn/button';
import { Label } from 'components/shadcn/label';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'components/shadcn/sheet';
import { FormInput } from 'components/UI/FormInput';
import { IonIcon } from 'components/UI/IonIcon';
import { useLanguage } from 'languages/languageContext';
import React, { FC, useEffect, useState } from 'react';
import { formatCurrency } from 'utilities/formatString';

interface IEventPriceProps {
  values: any;
  handlePriceBlur;
  idx: number;
  setValues: any;
}

export const EventPrice: FC<IEventPriceProps> = ({ values, handlePriceBlur, idx, setValues }) => {
  const { btn } = useLanguage();

  const [priceList, setPriceList] = useState(values.priceList || []);
  const [open, setOpen] = React.useState(false);

  const handleSubmit = () => {
    setValues({ ...values, priceList });
  };

  const handleChangeValue = (value: string, idx: number, key: 'title' | 'amount') => {
    const newPriceList = [...priceList];
    newPriceList[idx][key] = value;
    setPriceList(newPriceList);
  };

  useEffect(() => {
    console.log(!!values.priceList?.length, values.price);
    !!values.priceList?.length && setPriceList(values.priceList);
  }, [open]);

  const isDisabled = priceList.some((price) => !price.title);

  return (
    <div>
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetTrigger asChild>
          <div className="flex flex-col items-center cursor-pointer">
            <Label className="mb-2 mt-1">Weitere Preise</Label>
            <div
              onClick={() => {
                setPriceList([{ title: 'Standard-Preis', amount: values.price || '0,00', isDefault: true }]);
              }}
            >
              <IonIcon name="add-circle" size={30} />
            </div>
          </div>
        </SheetTrigger>
        <SheetContent style={{ overflowY: 'auto' }}>
          <SheetHeader>
            <SheetTitle>Weitere Preise</SheetTitle>
          </SheetHeader>
          {priceList?.map((_, index) => (
            <>
              {index !== 0 && (
                <div className="flex justify-end">
                  <Button
                    variant="text"
                    size="text"
                    className="text-red-500"
                    onClick={() => {
                      setPriceList(priceList.filter((_, idx) => index !== idx));
                    }}
                  >
                    {btn.clearBtn.toLowerCase()}
                  </Button>
                </div>
              )}

              <FormInput
                type="text"
                name={`parts[${idx}].priceList[${index}].title`}
                placeholder="title"
                onBlur={handlePriceBlur}
                onChange={(e) => handleChangeValue(e.target.value, index, 'title')}
                value={priceList[index].title}
              />
              <FormInput
                type="text"
                name={`parts[${idx}].priceList[${index}].amount`}
                placeholder="amount"
                onBlur={handlePriceBlur}
                onChange={(e) => handleChangeValue(formatCurrency(e.target.value), index, 'amount')}
                value={priceList[index].amount}
              />
              {index !== priceList.length - 1 && <div style={{ border: '0.5px solid lightgray' }} />}
            </>
          ))}
          <div className="flex flex-col items-center cursor-pointer">
            <Label className="mb-2 mt-1">Weitere Preise</Label>
            <div
              onClick={() => {
                setPriceList(
                  priceList.concat({
                    title: '',
                    amount: '0,00',
                    isDefault: false,
                  }),
                );
              }}
            >
              <IonIcon name="add-circle" size={30} />
            </div>
          </div>
          <SheetFooter>
            <SheetClose asChild>
              <Button disabled={isDisabled} onClick={handleSubmit}>
                {btn.saveBtn}
              </Button>
            </SheetClose>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </div>
  );
};
