import { client } from './client';
import { IContactsResponse } from 'store/types/constacts';

// GET USER CONTACTS
export const getUserContacts = async (userId: string, page: number, limit: number, searchkey = ''): Promise<IContactsResponse> => 
  await client.get(`v2/contact/${userId}/${page}/${limit}?searchkey=${encodeURIComponent(searchkey)}`)

// GET CONTACT LIST
export const getContactsList = async (userId: string) => await client.get(`v2/contact/${userId}/contactlist`)

export const deleteContact = async (userId: string) => await client.post(`v2/contact/remove/${userId}`)
