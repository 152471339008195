import React, { FC, useEffect } from 'react';
import Close from '@material-ui/icons/Close';
import { getConfig } from '../../config/config';
import { Box, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CheckboxUI } from 'components/UI/CheckboxUI';
import { useLanguage } from 'languages/languageContext';

const { theme } = getConfig();

type Props = {
  showModal: boolean;
  modalHandler: () => void;
  selectItemHandler: (label: string, modalName?: string) => void;
  deleteItemHandler: (label: string, modalName?: string) => void;
  modalTitle: string;
  data: string[];
  values: string[];
  disabled?: boolean;
};

export const MultipleSelectModal: FC<Props> = (props) => {
  const { multipleSelectModalComponent } = useLanguage();
  const { showModal, modalHandler, modalTitle, data, selectItemHandler, values, deleteItemHandler, disabled } = props;

  const classes = useStyles({});

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [showModal]);

  if (!showModal) {
    return null;
  }

  return (
    <div className={classes.container}>
      <div className={classes.modalWrapper}>
        <div className={classes.heading}>
          <h3 className={classes.title}>{modalTitle}</h3>
          <div className={classes.buttonWrapper}>
            <ButtonBase onClick={modalHandler} style={{ borderRadius: 50 }}>
              <Close style={{ color: theme.BUTTON_SECONDARY }} />
            </ButtonBase>
          </div>
        </div>
        <div className={classes.scrollWrapper}>
          <ul className={classes.scroll}>
            {data.map((item: any, i) => {
              if (!item) return null;
              const value = item.label || item;
              const id = item.id || item;

              let isSelected = !!values.find((item) => item === value);
              const isAllSelected = values.length + 1 === data.length;
              const valueDisabled = disabled && !isSelected;
              if (value === multipleSelectModalComponent.all) isSelected = isAllSelected;

              const handler = () => (isSelected ? deleteItemHandler(value) : selectItemHandler(value));
              // @ts-ignore
              return (
                <CheckboxUI
                  key={value}
                  disabled={valueDisabled}
                  setIsChecked={handler}
                  isChecked={isSelected}
                  label={value}
                  wrapperStyle={{ margin: 5 }}
                />
              );
            })}
          </ul>
        </div>
      </div>
      <div className={classes.backdrop} onClick={modalHandler}></div>
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    background: 'rgba(97, 97, 97, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 20,
  },
  modalWrapper: {
    position: 'relative',
    width: 362,
    paddingBottom: 15,
    borderRadius: 10,
    zIndex: 20,
    background: theme.BACKGROUND,
  },
  heading: {
    width: '100%',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    position: 'relative',
    padding: '20px 0 20px 0',
    background: theme.BACKGROUND_SECONDARY,
  },
  title: {
    textAlign: 'center',
    fontSize: 15,
    fontFamily: 'Poppins',
    margin: 0,
    color: theme.TEXT_PRIMARY,
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  scrollWrapper: {
    height: 400,
    width: '100%',
    overflow: 'auto',
    background: theme.BACKGROUND,
  },
  scroll: {
    padding: '15px 5px',
    margin: 0,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 5,
    outline: 'none',
    border: 'none',
  },
});
