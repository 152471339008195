import React from 'react';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles, Checkbox, TextareaAutosize } from '@material-ui/core';
import { RouteComponentProps } from 'react-router-dom';
import { withConfig, withAuth, Theme } from '../../config/';
import { updateUserRequest } from '../../store/actions/users';
import Layout from '../../components/UI/Layout';
import BackButton from '../../components/Shared/BackButton';
import RequestHeader from '../../components/Shared/RequestHeader';
import Button from '../../components/UI/Button';

import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { sendReportRequest } from 'store/actions/requests';

const styles = createStyles({
  container: {
    width: '100%',
  },
  label: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: 20,
    boxSizing: 'border-box',
  },
  checkboxWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  textAreaWrapper: {
    padding: '0 20px',
    boxSizing: 'border-box',
  },
  input: {
    fontFamily: 'Poppins',
    padding: 15,
    width: 350,
    borderRadius: 5,
    border: '1px solid',
    outline: 'none',
    fontSize: 15,
    lineHeight: '25px',
    boxSizing: 'border-box',
    '&::placeholder': {
      fontSize: 15,
      fontFamily: 'Roboto',
    },
  },
  heading: {
    display: 'block',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    marginBottom: 10,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '20px',
  },
  '@media(max-width: 650px)': {
    checkboxes: {
      padding: '10px',
      width: '100%',
    },
    label: {
      fontSize: 16,
    },
    input: {
      width: '100%',
    },
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  sendReportRequest: (report: object, reportId: string) => void;
  user: any;
  updateUserRequest: (user: any) => void;
}

interface State {
  [key: string]: any;
  first: boolean;
  second: boolean;
  third: boolean;
  fourth: boolean;
  fifth: boolean;
  reasons: Array<any>;
  reasonsdesc: Array<any>;
  message: string;
}

class RequestDetails extends React.Component<Props, State> {
  state: State = {
    first: false,
    second: false,
    third: false,
    fourth: false,
    fifth: false,
    reasons: [],
    reasonsdesc: [],
    message: '',
  };

  onChangeValue = (reason: number, desc: any, key: string) => {
    const reasonExist = this.state.reasons.includes(reason);
    let reasonsArr: Array<any> = [...this.state.reasons];
    let reasonsdescArr: Array<any> = [...this.state.reasonsdesc];

    if (reasonExist) {
      reasonsArr = reasonsArr.filter((item) => item != reason);
      reasonsdescArr = reasonsdescArr.filter((item) => item != desc);
    } else if (!reasonExist) {
      reasonsArr.push(reason);
      reasonsdescArr.push(desc);
    }

    this.setState({
      [key]: !this.state[key],
      reasons: reasonsArr,
      reasonsdesc: reasonsdescArr,
    });
  };

  onChangeHandler = (e) => {
    const { value } = e.target;

    this.setState({
      message: value,
    });
  };

  sendReportHandler = () => {
    const { user } = this.props.history.location.state;
    const { reasons, reasonsdesc, message } = this.state;
    const name = user.name + ' ' + user.lastname;

    const reportEntity = {
      name,
      reasons,
      reasonsdesc,
      message,
    };

    this.props.sendReportRequest(reportEntity, user._id);
  };

  blockUserHandler = () => {
    const { user } = this.props.history.location.state;

    const reporter = this.props.user;

    if (!reporter.muteList) {
      reporter.muteList = [];
    }

    if (reporter && reporter.name && user._id) {
      const isBlocked = reporter && reporter.muteList && reporter.muteList.includes(user._id);

      const userEntity = {
        ...this.props.user,
        muteList: isBlocked
          ? [...this.props.user.muteList.filter((id) => id !== user._id)]
          : [...this.props.user.muteList, user._id],
      };

      this.props.updateUserRequest(userEntity);
    }
  };

  render(): JSX.Element {
    const { user, language } = this.props.history.location.state;
    const { classes, history, theme } = this.props;

    const reporter = this.props.user;
    const isBlocked = reporter && reporter.muteList && reporter.muteList.includes(user._id);

    return (
      <Layout>
        <BackButton title={language.reportScreen.reportUser} onClick={() => history.goBack()} />
        <div className={classes.container}>
          <RequestHeader sender={user} />
          <div className={classes.checkboxes}>
            <div className={classes.checkboxWrapper}>
              <Checkbox
                color="default"
                icon={<CircleUnchecked style={{ color: theme.ICON_BORDER }} />}
                checkedIcon={
                  <CircleChecked
                    style={{
                      color: theme.BUTTON_SECONDARY,
                    }}
                  />
                }
                checked={this.state.first}
                onChange={() => this.onChangeValue(0, language.reportScreen.notMember, 'first')}
              />
              <span className={classes.label} style={{ color: theme.TEXT_PRIMARY }}>
                {language.reportScreen.notMember}
              </span>
            </div>
            <div className={classes.checkboxWrapper}>
              <Checkbox
                color="default"
                icon={<CircleUnchecked style={{ color: theme.ICON_BORDER }} />}
                checkedIcon={
                  <CircleChecked
                    style={{
                      color: theme.BUTTON_SECONDARY,
                    }}
                  />
                }
                checked={this.state.second}
                onChange={() => this.onChangeValue(1, language.reportScreen.fakeProfile, 'second')}
              />
              <span className={classes.label} style={{ color: theme.TEXT_PRIMARY }}>
                {language.reportScreen.fakeProfile}
              </span>
            </div>
            <div className={classes.checkboxWrapper}>
              <Checkbox
                color="default"
                icon={<CircleUnchecked style={{ color: theme.ICON_BORDER }} />}
                checkedIcon={
                  <CircleChecked
                    style={{
                      color: theme.BUTTON_SECONDARY,
                    }}
                  />
                }
                checked={this.state.third}
                onChange={() => this.onChangeValue(2, language.reportScreen.violations1, 'third')}
              />
              <span className={classes.label} style={{ color: theme.TEXT_PRIMARY }}>
                {language.reportScreen.violations1}
              </span>
            </div>
            <div className={classes.checkboxWrapper}>
              <Checkbox
                color="default"
                icon={<CircleUnchecked style={{ color: theme.ICON_BORDER }} />}
                checkedIcon={
                  <CircleChecked
                    style={{
                      color: theme.BUTTON_SECONDARY,
                    }}
                  />
                }
                checked={this.state.fourth}
                onChange={() => this.onChangeValue(3, language.reportScreen.violations2, 'fourth')}
              />
              <span className={classes.label} style={{ color: theme.TEXT_PRIMARY }}>
                {language.reportScreen.violations2}
              </span>
            </div>
            <div className={classes.checkboxWrapper}>
              <Checkbox
                color="default"
                icon={<CircleUnchecked style={{ color: theme.ICON_BORDER }} />}
                checkedIcon={
                  <CircleChecked
                    style={{
                      color: theme.BUTTON_SECONDARY,
                    }}
                  />
                }
                checked={this.state.fifth}
                onChange={() => this.onChangeValue(4, language.reportScreen.violations3, 'fifth')}
              />
              <span className={classes.label} style={{ color: theme.TEXT_PRIMARY }}>
                {language.reportScreen.violations3}
              </span>
            </div>
          </div>
          <div className={classes.textAreaWrapper}>
            <span className={classes.heading} style={{ color: theme.TEXT_PRIMARY }}>
              {language.reportScreen.reportReason}
            </span>
            <TextareaAutosize
              rowsMax={6}
              style={{
                borderColor: theme.BACKGROUND_SECONDARY,
                background: theme.BACKGROUND_PRIMARY,
              }}
              onChange={this.onChangeHandler}
              className={classes.input}
              value={this.state.message}
            />
          </div>
          <div className={classes.buttonsWrapper}>
            <Button
              label={language.reportScreen.sendReport}
              onClick={() => {
                this.sendReportHandler();
                history.goBack();
              }}
              disabled={this.state.reasons.length > 0 && this.state.message.length > 0}
            />
            <Button
              label={language.reportScreen.cancel}
              onClick={() => history.goBack()}
              background={theme.BUTTON_SECONDARY}
            />
            <Button
              label={isBlocked ? language.reportScreen.showContent : language.reportScreen.hideContent}
              onClick={() => {
                this.blockUserHandler();
                history.goBack();
              }}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = ({ users }) => ({
  user: users.user,
  userIsLoading: users.userIsLoading,
  currentUser: users.currentUser,
});

const mapDispatchToProps = {
  sendReportRequest,
  updateUserRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withAuth(withConfig(RequestDetails))));
