import { Box, ButtonBase } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import AlertModal from 'components/Shared/AlertModal';
import Button from 'components/UI/Button';
import TouchableInput from 'components/UI/TouchableInput';
import React, { FC, useState } from 'react';
import { useAppSelector } from 'store';
import { SelectMentoringState } from 'store/reducers/mentoring';
import { getConfig } from '../../../config/config';
import { useLanguage } from '../../../languages/languageContext';

const { config } = getConfig();

type modalType = 'isRegionsModalOpen' | 'isDepartmentModalOpen' | 'isTopicsModalOpen';
type itemTypes = 'mentoringRegions' | 'topics' | 'departments';

type Props = {
  type: itemTypes;
  state: any;
  isMentor?: boolean | true;
  modalHandler: any;
  deleteItemHandler: any;
  renderBranch?: boolean;
  isProfile?: boolean;
};

export const MentorBranches: FC<Props> = ({
  type,
  isMentor = true,
  state,
  modalHandler,
  deleteItemHandler,
  renderBranch = true,
  isProfile = false,
}) => {
  const [maxItemsSelected, setMaxItemsSelected] = useState({
    topics: false,
    mentoringRegions: false,
  });
  const { mentoring, btn, mentoringScreen } = useLanguage();
  const { profile } = mentoring;
  const { data } = useAppSelector(SelectMentoringState);
  if (!renderBranch) return <></>;
  let modalName: modalType;
  if (type === 'mentoringRegions') modalName = 'isRegionsModalOpen';
  if (type === 'departments') modalName = 'isDepartmentModalOpen';
  if (type === 'topics') modalName = 'isTopicsModalOpen';
  const alleIsChoosen = state[type]?.length === data[type].length;

  // const disabled = alleIsChoosen
  const maxSearchItemsCount = type === 'mentoringRegions' ? 1 : type === 'topics' ? 3 : 999;

  const addItemHandler = () => {
    // if (!isProfile && type !== 'departments' && state[type].length >= maxSearchItemsCount) {
    //   setMaxItemsSelected({ ...maxItemsSelected, [type]: true })
    //   return
    // }

    modalHandler(modalName);
  };

  return (
    <>
      <Box style={styles.inputContainer}>
        <TouchableInput
          onClick={addItemHandler}
          value={undefined}
          label={profile[type]}
          placeholder={profile[type]}
          // disabled={disabled}
        />
        {alleIsChoosen && config.APP_NAME === 'kasconnect'
          ? RenderItem(mentoringScreen.all, type, deleteItemHandler)
          : state[type].map((item) => RenderItem(item, type, deleteItemHandler))}
      </Box>
      <AlertModal
        showModal={maxItemsSelected.mentoringRegions || maxItemsSelected.topics}
        message={mentoringScreen.maxMessage(
          maxSearchItemsCount,
          maxItemsSelected.mentoringRegions ? mentoring.profile.reigonsShort : mentoring.profile.topics,
        )}
      >
        <Button onClick={() => setMaxItemsSelected({ topics: false, mentoringRegions: false })} label={btn.cancelBtn} />
      </AlertModal>
    </>
  );
};

const RenderItem = (item: string, type: itemTypes, deleteItemHandler: any) => {
  return (
    <Box style={styles.itemContainer} key={item}>
      <Box style={styles.itemWrapper}>
        <span>{item}</span>
      </Box>
      <ButtonBase onClick={() => deleteItemHandler(item, type)} style={styles.deleteWrapper}>
        <Delete style={{ color: theme.ERROR_PRIMARY }} />
      </ButtonBase>
    </Box>
  );
};

const { theme } = getConfig();
const styles = {
  inputContainer: {
    marginBottom: 20,
  },

  deleteWrapper: {
    backgroundColor: theme.BACKGROUND_PRIMARY,
    border: `1px solid ${theme.BACKGROUND_SECONDARY}`,
    padding: 8,
    borderRadius: 20,
  },
  itemWrapper: {
    backgroundColor: theme.BACKGROUND_PRIMARY,
    borderRadius: 20,
    border: `1px solid ${theme.BACKGROUND_SECONDARY}`,
    margin: 5,
    padding: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    display: 'flex',
  },
  itemContainer: {
    alignItems: 'center',
    paddingHorizontal: 5,
    display: 'flex',
  },
};
