import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { keepPreviousData, useQuery, useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import { CalendarIcon, ListBulletIcon } from '@radix-ui/react-icons';
import {
  getYear,
  getMonth,
  getDate,
  parseISO,
  isSameDay,
  isWithinInterval,
  isBefore,
  addDays,
  isSameMonth,
} from 'date-fns';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';

import { queryClient as qc } from 'App';
import ScreenTitle from 'components/Shared/ScreenTitle';
import Layout from 'components/UI/Layout';
import { parseBoolean } from 'config/setConfig';
import { useCustomHistory } from 'hooks/useCustomHistory';
import { useQuery as useQueryParams } from 'hooks/useQuery';
import { useLanguage } from 'languages/languageContext';
import { getBirthdayEvents, getEvents, getEventsList } from 'api/events';
import { IonIcon } from 'components/UI/IonIcon';
import EventItem from './components/EventItem';
import BirthdayItem from './components/BirthdayItem';
import { getConfig } from 'config/config';
import { useAppDispatch, useAppSelector } from 'store';
import EmptyList from 'components/Shared/EmptyList';
import { cn } from 'utilities/utils';
import { setEvents } from 'store/actions/events';
import './react-big-calendar.css';
import { locales } from 'constants/date-fns-locales';

export type TEventStatus = 'all' | 'invited' | 'confirmed';

export const CalendarScreen = () => {
  const dispatch = useAppDispatch();
  const query = useQueryParams();
  const history = useCustomHistory();
  const { screenTitles, calendar, btn, key2: locale } = useLanguage();
  const [errAuth, setErrAuth] = useState(false);
  const styles = useStyles({});
  const { event_default_tab, show_noninvited_events } = useAppSelector((state) => state.common);

  const qMonth = query.get('month');
  const qYear = query.get('year');
  const qDay = query.get('day');
  const qView = (query.get('view') || 'month') as 'month' | 'agenda';
  const qStatus = (query.get('status') || event_default_tab) as TEventStatus;
  const queryClient = useQueryClient(qc);
  const listSkip = useRef({ recurring: 0, regular: 0 });

  const { events: monthEvents, eventList } = useAppSelector((state) => state.events);

  const setListSkip = (v: { recurring: number; regular: number }) => {
    if (listSkip.current) listSkip.current = v;
  };

  const { data, refetch: refetchCalendar }: { data: any[]; refetch: () => void; isError: boolean } = useQuery({
    queryKey: ['events', qMonth, qYear, qStatus],
    queryFn: () =>
      getEvents(qMonth, qYear, qStatus).catch((err) => {
        if (err?.message) {
          handleFilter('all');
          setErrAuth(true);
        }
      }),
    placeholderData: keepPreviousData,
    enabled: qYear !== null && qMonth !== null && qDay !== null && qView === 'month',
  });

  const { data: birthdayData }: { data: { Data: [] } } = useQuery({
    queryKey: ['birthdayData', qMonth, qYear, qStatus],
    queryFn: () => getBirthdayEvents(+qMonth, +qYear),
    placeholderData: keepPreviousData,
    enabled: qDay !== null && qView === 'month',
  });

  const {
    data: listData,
    isFetchingNextPage,
    fetchNextPage,
    refetch: refetchEventList,
    isLoading: eventListLoading,
  } = useInfiniteQuery({
    queryKey: ['eventList', qStatus],
    queryFn: () => getEventsList({ ...listSkip.current, status: qStatus }),
    initialPageParam: 0,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      const count = allPages?.reduce((acc, page) => [...acc, ...page.Data], []);
      if (count?.length >= lastPage?.total || !lastPage?.Data?.length) {
        return undefined;
      }
      return lastPageParam + 1;
    },
    // placeholderData: keepPreviousData,
    enabled: qView === 'agenda',
  });

  useEffect(() => {
    if (listData?.pages?.length) {
      const eventList = listData?.pages?.reduce((acc, page) => [...acc, ...page.Data], []);
      dispatch(setEvents(eventList, 'eventList'));
    }
  }, [listData]);

  useEffect(() => {
    dispatch(setEvents(data, 'events'));
  }, [data]);

  useEffect(() => {
    const lastItem = listData?.pages[listData?.pages?.length - 1];
    // if (lastItem) setListSkip((c) => ({ ...c, [qStatus]: lastItem.skip }));
    if (lastItem) setListSkip(lastItem.skip);
  }, [listData?.pages]);

  useEffect(() => {
    setListSkip({ recurring: 0, regular: 0 });
    if (qMonth && qYear && qView === 'month') refetchCalendar();
    if (qView === 'agenda') refetchEventList();
  }, []);

  useLayoutEffect(() => {
    const preferableView: 'month' | 'agenda' = (localStorage.getItem('eventView') || 'month') as 'month' | 'agenda';
    handleView(preferableView);
    handleFilter(event_default_tab);
    if (!qMonth || !qDay || !qYear) changeDate(new Date());
  }, [event_default_tab, history.location.search === '']);

  useEffect(() => {
    const divElement = document.getElementById('scrollable-list');
    divElement.addEventListener('scroll', handleScroll);
    return () => {
      divElement.removeEventListener('scroll', handleScroll);
    };
  }, [isFetchingNextPage]);

  const isWidget = parseBoolean(query.get('widget'));

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const formats = {
    weekdayFormat: (date) => format(date, 'eee', { locale: locales[locale] }),
    monthHeaderFormat: (date) => format(date, 'MMMM yyyy', { locale: locales[locale] }),
    dayFormat: (date) => format(date, 'd', { locale: locales[locale] }),
    agendaDateFormat: (date) => format(date, 'eeee, MMM d', { locale: locales[locale] }),
  };

  const currentDate = new Date(Number(qYear), Number(qMonth) - 1, Number(qDay));

  const currentDayEvents = () => {
    let dayData = [];
    if (birthdayData?.Data?.length) dayData = [...birthdayData?.Data];
    if (monthEvents?.length) dayData = [...dayData, ...monthEvents];
    if (!dayData.length) return [];

    const todayEvents = dayData.filter((event) => {
      const sDate = event.startDate ? parseISO(event.startDate) : undefined;
      const eDate = event.hideEndDate === true && event.endDate ? parseISO(event.endDate) : undefined;
      const bday = event.birthday ? parseISO(event.birthday) : undefined;

      if (bday && isSameDay(bday, currentDate)) return event;
      if (sDate && isSameDay(sDate, currentDate)) return event;
      if (eDate && isSameDay(eDate, currentDate)) return event;
      if (eDate && sDate && isWithinInterval(currentDate, { start: sDate, end: eDate })) return event;
    });

    return todayEvents;
  };

  const currentMonthEvents = useMemo(() => {
    if (!eventList?.length) return [];
    const shallowList = [...eventList];
    const result = [];
    let currentSeparator = null;

    shallowList.forEach((event) => {
      const sDate = parseISO(event.startDate);
      const eDate = event.hideEndDate === true && event.endDate ? parseISO(event.endDate) : undefined;

      if (!currentSeparator || !isSameDay(sDate, currentSeparator)) {
        currentSeparator = sDate;
        result.push({ type: 'separator', startDate: currentSeparator });
      }

      if (eDate && isBefore(sDate, eDate)) {
        const newStartDate = addDays(sDate, 1);
        const idxToInsert = shallowList.findIndex((e) => isBefore(newStartDate, parseISO(e.startDate)));

        const newEvent = {
          ...event,
          startDate: newStartDate.toISOString(),
        };

        if (idxToInsert === -1) shallowList.push(newEvent);
        else shallowList.splice(idxToInsert, 0, newEvent);
      }

      result.push(event);
    });

    return result;
  }, [eventList, qView]);

  const handleScroll = () => {
    if (qView === 'month') return;
    if (isFetchingNextPage) return;

    const scrollableDiv = document.getElementById('scrollable-list');
    const scrollTop = scrollableDiv.scrollTop;
    const clientHeight = scrollableDiv.clientHeight;
    const scrollHeight = scrollableDiv.scrollHeight;

    if (scrollTop + clientHeight >= scrollHeight - 100) {
      fetchNextPage();
    }
  };

  const changeDate = (date: Date) => {
    const newDate = new Date(date);

    resetParams('date');

    query.append('year', getYear(newDate).toString());
    query.append('month', (getMonth(newDate) + 1).toString());
    query.append('day', getDate(newDate).toString());

    history.replace({ search: query.toString() });
  };

  const resetParams = (type: 'date' | 'view') => {
    if (type === 'date') {
      query.delete('day');
      query.delete('month');
      query.delete('year');
    }
    if (type === 'view') {
      query.delete('view');
    }
  };

  const handleView = (c: 'month' | 'agenda') => {
    setListSkip({ recurring: 0, regular: 0 });
    queryClient.setQueryData(['eventList', qStatus], () => ({
      pages: [],
      pageParams: 0,
    }));
    localStorage.setItem('eventView', c);
    query.delete('view');
    query.append('view', c);
    history.replace({ search: query.toString() });
  };

  const handleFilter = (c: TEventStatus) => {
    setListSkip({ recurring: 0, regular: 0 });
    queryClient.setQueryData(['eventList'], () => ({
      pages: [],
      pageParams: 0,
    }));
    query.delete('status');
    query.append('status', c);
    history.replace({ search: query.toString() });
  };

  return (
    <Layout>
      <div className="h-[94vh] overflow-hidden">
        <ScreenTitle
          hideMenuBtn={isWidget}
          title={screenTitles.eventTitle}
          showAddIcon={!isWidget}
          addEvent={() => history.push('/create-event')}
        />
        <div className="flex flex-row w-full justify-between my-3 text-lg">
          {show_noninvited_events && (
            <button className={`flex flex-1 justify-center`} onClick={() => handleFilter('all')}>
              {calendar.filter.all}
            </button>
          )}
          <button
            disabled={errAuth}
            style={{ color: errAuth && theme.BACKGROUND_SECONDARY }}
            className={`flex flex-1 justify-center`}
            onClick={() => handleFilter('invited')}
          >
            {calendar.filter.invited}
          </button>
          <button
            disabled={errAuth}
            className={`flex flex-1 justify-center`}
            style={{ color: errAuth && theme.BACKGROUND_SECONDARY }}
            onClick={() => handleFilter('confirmed')}
          >
            {calendar.filter.confirmed}
          </button>
          <div
            className={cn(
              'absolute mx-[3%] h-10 transition duration-200 ease-in-out rounded-full flex items-end flex-col',

              !show_noninvited_events ? 'w-[46%]' : 'w-[32%]',

              qStatus === 'invited' && !show_noninvited_events && 'translate-x-[-2%]',
              qStatus === 'confirmed' && !show_noninvited_events && 'translate-x-[106%]',

              qStatus === 'invited' && show_noninvited_events && 'translate-x-[98%]',
              qStatus === 'confirmed' && show_noninvited_events && 'translate-x-[200%]',
              qStatus === 'all' && show_noninvited_events && 'translate-x-[-6%]',
            )}
          >
            <div
              className={`opacity-20 h-full w-full rounded-tl-md rounded-tr-md overflow-hidden`}
              style={{ backgroundColor: theme.ACTIVE_INPUT }}
            />
            <div
              className="h-2 w-full opacity-100 overflow-hidden rounded-bl-md rounded-br-md"
              style={{ backgroundColor: theme.ACTIVE_INPUT }}
            />
          </div>
        </div>
        <Calendar
          formats={formats}
          className={`${qView === 'agenda' ? 'hidden' : ''}`}
          onSelectSlot={({ start }) => changeDate(start)}
          localizer={localizer}
          events={[]}
          startAccessor="startDate"
          endAccessor="endDate"
          selectable={true}
          views={['month']}
          defaultView="month"
          date={new Date(Number(qYear), Number(qMonth) - 1, Number(qDay))}
          style={{
            height: qView === 'agenda' ? 'unset' : '44vh',
            maxHeight: 400,
            width: '100%',
            border: '1px solid #f1f1f1f',
          }}
          onNavigate={(nav) => changeDate(nav)}
          eventPropGetter={() => ({ style: { display: 'none ' } })}
          components={{
            toolbar: ({ onNavigate, label }) => {
              return (
                <div className="flex flex-col">
                  <div className="flex flex-row h-14">
                    <div className="flex-1" />
                    <div className="flex flex-row w-96 justify-between items-center">
                      <div
                        className="flex items-center self-center cursor-pointer hover:bg-slate-200 rounded-full p-2"
                        onClick={() => onNavigate('PREV')}
                      >
                        <IonIcon name="chevron-back" size={32} />
                      </div>
                      <div className="text-2xl text">{label}</div>
                      <div
                        className="flex items-center self-center cursor-pointer hover:bg-slate-200 rounded-full p-2"
                        onClick={() => onNavigate('NEXT')}
                      >
                        <IonIcon name="chevron-forward" size={32} />
                      </div>
                    </div>
                    <div className="flex-1" />
                  </div>
                </div>
              );
            },
            dateCellWrapper: (c) => {
              let eventsCount = 0;
              let birthdayCount = 0;
              let dayData = [];
              const day = new Date(c.value);

              if (monthEvents?.length) dayData = [...monthEvents];
              if (birthdayData?.Data?.length) dayData = [...dayData, ...birthdayData?.Data];

              dayData?.forEach((event) => {
                const startDate = event.startDate ? parseISO(event.startDate) : null;
                const endDate = event.endDate && event.hideEndDate ? parseISO(event.endDate) : null;
                const birthday = event.birthday ? parseISO(event.birthday) : null;

                if (!event.birthday) {
                  if (startDate && isSameDay(day, startDate)) {
                    eventsCount++;
                  } else if (endDate && isSameDay(day, endDate)) {
                    eventsCount++;
                  } else if (startDate && endDate && isWithinInterval(day, { start: startDate, end: endDate })) {
                    eventsCount++;
                  }
                }

                if (birthday && isSameDay(day, birthday)) {
                  birthdayCount++;
                }
              });

              const hasEvents = !!(eventsCount || birthdayCount);

              const backgroundColor = isSameDay(day, new Date()) // Check if today
                ? theme.ACTIVE_INPUT
                : isSameMonth(day, currentDate) && hasEvents // Check if same month and has events
                ? '#90cce7'
                : 'transparent';

              const selectedStyles = isSameDay(day, currentDate) // Check if selected date
                ? `border-2 scale-110 shadow-lg shadow-gray-200`
                : '';

              return (
                <div className="flex flex-1 p-1 items-center justify-center">
                  <div
                    className={cn(
                      `rounded-full h-8 w-8 flex justify-center items-center text-md`,
                      'text-white',
                      backgroundColor === 'transparent' && 'text-black',
                      !isSameMonth(day, currentDate) && 'text-slate-300',
                      selectedStyles,
                    )}
                    style={{ backgroundColor, borderColor: theme.COLOR_SECONDARY }}
                  >
                    {getDate(c.value)}
                  </div>
                  {!!birthdayCount && (
                    <span className="text-sm text-white absolute -translate-x-3 -translate-y-3 w-4 h-4 bg-gray-400 rounded-full items-center flex justify-center">
                      {birthdayCount}
                    </span>
                  )}
                  {!!eventsCount && (
                    <span className="text-sm text-white absolute translate-x-3 -translate-y-3 w-4 h-4 bg-red-500 rounded-full items-center flex justify-center">
                      {eventsCount}
                    </span>
                  )}
                </div>
              );
            },
          }}
        />
        <div
          id="scrollable-list"
          className={`${styles.scrollable} p-2 overflow-y-auto h-[82vh] sm:h-[87vh] ${
            qView === 'month' ? 'hidden' : ''
          }`}
        >
          {currentMonthEvents.map((event) =>
            event.type === 'separator' ? (
              <div
                className="w-fit my-2 px-2 rounded-md flex flex-col justify-center items-center text-white"
                style={{ backgroundColor: theme.ACTIVE_INPUT }}
                key={event.startDate}
              >
                {format(new Date(event.startDate), 'dd. MMM', { locale: locales[locale] })}
              </div>
            ) : event.birthday ? (
              <BirthdayItem event={event} key={event._id} />
            ) : (
              <EventItem event={event} key={event._id + event.startDate} />
            ),
          )}

          {(isFetchingNextPage || eventListLoading) && (
            <div className="w-full h-96 flex self-end items-center justify-center">
              <CircularProgress size={36} style={{ color: theme.ACTIVE_INPUT }} />
            </div>
          )}
          {!eventList?.length && (
            <div className="mt-72">
              <EmptyList />
            </div>
          )}
        </div>
        {
          <div
            className={`${styles.scrollable} p-2 overflow-y-auto h-[37vh] md:lg-[40vh] lg:h-[44vh] ${
              qView === 'agenda' ? 'hidden' : ''
            }`}
          >
            {currentDayEvents().map((event) =>
              event?.birthday ? (
                <BirthdayItem event={event} key={event._id} />
              ) : (
                <EventItem event={event} key={event._id} />
              ),
            )}
          </div>
        }
        <div className="flex flex-row text-lg absolute bottom-1 justify-center w-full">
          <button className={`flex justify-center w-10 h-10 items-center`} onClick={() => handleView('month')}>
            <CalendarIcon className="w-5 h-5" />
          </button>
          <button className={`flex justify-center w-10 h-10 items-center`} onClick={() => handleView('agenda')}>
            <ListBulletIcon className="w-5 h-5" />
          </button>
          <div
            className={`absolute  h-10 w-10 transition duration-200 ease-in-out ${
              qView === 'agenda' ? 'translate-x-[50%]' : 'translate-x-[-50%]'
            } rounded-full flex items-end flex-col`}
          >
            <div
              className={`opacity-20 h-full w-full rounded-tl-md rounded-tr-md overflow-hidden`}
              style={{ backgroundColor: theme.ACTIVE_INPUT }}
            />
            <div
              className="h-2 w-full opacity-100 overflow-hidden rounded-bl-md rounded-br-md"
              style={{ backgroundColor: theme.ACTIVE_INPUT }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const { theme } = getConfig();

const useStyles = makeStyles({
  scrollable: {
    '&::-webkit-scrollbar': {
      width: 5,
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      margin: '5px 0',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
});
