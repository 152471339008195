import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Loader from '../../components/UI/Loader';
import Button from '../../components/UI/Button';
import BenachrichtigungenItem from './components/BenachrichtigungenItem';
import { useLanguage } from 'languages/languageContext';
import { useAppDispatch, useAppSelector } from 'store';
import { selectCommonState } from 'store/reducers/common';
import { getConfig } from 'config/config';
import { showResponse } from 'store/actions/response';
import { updateUserNotificationsRequest } from 'store/actions/users';
import DefaultModal from 'components/Shared/DefaultModal';

const { theme } = getConfig();
const useStyles = makeStyles({
  container: {
    width: 420,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 20,
    marginTop: 50,
  },
  infoTitle: {
    fontSize: 16,
    marginBottom: 10,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
  },
  info: {
    fontSize: 14,
    marginBottom: 30,
  },
  inputsWrapper: {
    position: 'relative',
    marginBottom: 20,
  },
  optionsWrapper: {
    marginBottom: 50,
  },
  buttonWrapper: {},
  button: {
    marginTop: 30,
  },
  '@media(max-width: 650px)': {
    container: {
      width: '100%',
      padding: '0 10px',
    },
  },
});

interface Props {}

const ProfileBenachrichtigungen: React.FC<Props> = () => {
  const { notifications_config: notificationsConfig } = useAppSelector(selectCommonState);
  const [state, setState] = React.useState({
    notifications: {
      clubMember: {
        enabled: true,
      },
      comments: {
        enabled: true,
      },
      contactRequests: {
        enabled: true,
      },
      events: {
        enabled: true,
      },
      messages: {
        enabled: true,
      },
      posts: {
        enabled: true,
      },
      ownPosts: {
        enabled: notificationsConfig?.own_post_notify || false,
      },
    },
    all: {
      enabled: true,
    },
    showModal: false,
    notificationModal: false,
    // messagesModal: false,
    // commentsModal: false,
  });

  const dispatch = useAppDispatch();
  const { user, userIsLoading } = useAppSelector((state) => state.users);
  const language = useLanguage();
  const classes = useStyles({});
  const [notificationOptionName, setNotificationOptionName] = useState('');
  const [infoModal, setInfoModal] = useState({
    show: false,
    message: '',
  });

  React.useEffect(() => {
    if (user && user.mailingNotifyOptions) {
      const { mailingNotifyOptions } = user;
      const allEnabled = Object.values(mailingNotifyOptions);
      const result = allEnabled.every((option: any) => option.enabled);

      setState({
        ...state,
        notifications: {
          ...mailingNotifyOptions,
          events: {
            enabled: true,
          },
          posts: {
            enabled: true,
          },
        },
        all: {
          enabled: !!result,
        },
      });
    }
  }, []);

  React.useEffect(() => {
    if (user && user.mailingNotifyOptions) {
      const { mailingNotifyOptions } = user;

      const allEnabled = Object.values(mailingNotifyOptions);
      const result = allEnabled.every((option: any) => option?.enabled);

      setState({
        ...state,
        notifications: {
          ...mailingNotifyOptions,
          events: {
            enabled: true,
          },
          posts: {
            enabled: true,
          },
        },
        all: {
          enabled: !!result,
        },
      });
    }
  }, [user.mailingNotifyOptions]);

  // componentDidMount() {
  //   const { user } = props;

  //   if (user && user.mailingNotifyOptions) {
  //     const { mailingNotifyOptions } = user;
  //     const allEnabled = Object.values(mailingNotifyOptions);
  //     const result = allEnabled.every((option: any) => option.enabled);

  //     setState({
  //       notifications: {
  //         ...mailingNotifyOptions,
  //       },
  //       all: {
  //         enabled: !!result,
  //       },
  //     });
  //   }
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   const { user } = props;

  //   if (prevProps && user && user.mailingNotifyOptions) {
  //     const { mailingNotifyOptions } = user;

  //     if (JSON.stringify(prevProps.user.mailingNotifyOptions) !== JSON.stringify(mailingNotifyOptions)) {
  //       const allEnabled = Object.values(mailingNotifyOptions);
  //       const result = allEnabled.every((option: any) => option.enabled);

  //       setState({
  //         notifications: {
  //           ...mailingNotifyOptions,
  //         },
  //         all: {
  //           enabled: !!result,
  //         },
  //       });
  //     }
  //   }
  // }

  const notificationHandler = (optionName: keyof typeof state, value: any) => {
    if (user && user.name) {
      if (optionName === 'all') {
        setState({
          ...state,
          all: value,
          notifications: {
            clubMember: value,
            comments: value,
            contactRequests: value,
            events: value,
            messages: value,
            posts: value,
            ownPosts: value,
          },
        });
      } else {
        const allEnabled = Object.values(state.notifications);
        const result = allEnabled.every((option) => option?.enabled);

        setState({
          ...state,
          notifications: {
            ...state.notifications,
            [optionName]: value,
          },
          all: {
            enabled: !!result,
          },
        });
      }
    } else if (user && !user.name) {
      dispatch(
        showResponse({
          message: language.profileBenachrichtigungenScreen.fillData,
        }),
      );
    }
  };

  const updateUserHandler = () => {
    if (user && user.name) {
      dispatch(updateUserNotificationsRequest({ ...state.notifications }));
    } else if (user && !user.name) {
      dispatch(
        showResponse({
          message: language.profileBenachrichtigungenScreen.fillData,
        }),
      );
    }
  };

  const handleSwichNotification = (optionName, value) => {
    setNotificationOptionName(optionName);
    if (!value?.enabled) {
      setState((prev) => ({ ...prev, notificationModal: true }));
    } else {
      notificationHandler(optionName, value);
    }
  };

  const { profileNotifications, btn } = language;
  const titlesArray = [
    {
      optionName: 'contactRequests',
      title: profileNotifications.contactRequestsLabel,
    },
    {
      optionName: 'messages',
      title: profileNotifications.messagesLabel,
      onPress: handleSwichNotification,
    },
    {
      optionName: 'comments',
      title: profileNotifications.commentsLabel,
      onPress: handleSwichNotification,
    },
    {
      optionName: 'ownPosts',
      title: profileNotifications.ownPosts,
    },
    // {
    //   optionName: 'events',
    //   title: profileNotifications.eventsLabel,
    //   onPress: () => {
    //     dispatch(showResponse({ message:  language.profileBenachrichtigungenScreen.changesInChat }));
    //   },
    //   disabled: true,
    // },
    {
      optionName: 'posts',
      title: profileNotifications.postsLabel,
      onPress: () => {
        dispatch(showResponse({ message: language.profileBenachrichtigungenScreen.changesInChat }));
      },
      disabled: true,
    },
  ];

  const modalMessages =
    notificationOptionName === 'comments'
      ? language.profileBenachrichtigungenScreen.commentsWarning1
      : language.profileBenachrichtigungenScreen.commentsWarning2;

  const handleOpenInfoModal = () => {
    const message =
      notificationOptionName === 'messages'
        ? language.profileBenachrichtigungenScreen.messagesWarning1
        : language.profileBenachrichtigungenScreen.messagesWarning2;

    setInfoModal((prev) => ({ ...prev, show: true, message }));
  };

  return (
    <React.Fragment>
      <div className={classes.container}>
        <p className={classes.infoTitle} style={{ color: theme.TEXT_PRIMARY }}>
          {profileNotifications.notificationsTitle}
        </p>
        <p className={classes.info} style={{ color: theme.TEXT_PRIMARY }}>
          {profileNotifications.notificationsDescr0} {profileNotifications.notificationsDescr1}{' '}
          {profileNotifications.notificationsDescr2}
        </p>

        <p className={classes.infoTitle} style={{ color: theme.TEXT_PRIMARY }}>
          {profileNotifications.mailingTitle}
        </p>
        <p className={classes.info} style={{ color: theme.TEXT_PRIMARY }}>
          {profileNotifications.mailingDescr}
        </p>
        <div className={classes.optionsWrapper}>
          {titlesArray.map((option) => (
            <BenachrichtigungenItem
              key={option.optionName}
              title={option.title}
              optionName={option.optionName}
              notificationHandler={option?.onPress || notificationHandler}
              mailingNotifyOptionSettings={state.notifications[option.optionName]}
              disabled={option.disabled}
              handleOpenInfoModal={handleOpenInfoModal}
            />
          ))}
        </div>
        <Button label={btn.saveBtn} onClick={updateUserHandler} />
        <Loader isLoading={userIsLoading} />
        <DefaultModal
          showModal={state.notificationModal}
          modalTitle={language.profileBenachrichtigungenScreen.areYouSure}
          message={modalMessages}
          onClose={() => setState((prev) => ({ ...prev, notificationModal: false }))}
        >
          <Button
            label={language.profileBenachrichtigungenScreen.turnOff}
            onClick={() => {
              //@ts-ignore
              notificationHandler(notificationOptionName, { enabled: false });
              setState((prev) => ({ ...prev, notificationModal: false }));
            }}
            background={theme.BUTTON_SECONDARY}
          />
          <Button
            label={language.profileBenachrichtigungenScreen.maybeLater}
            labelStyle={{ fontWeight: 'bolder' }}
            onClick={() => setState((prev) => ({ ...prev, notificationModal: false }))}
          />
        </DefaultModal>
        <DefaultModal
          showModal={infoModal.show}
          modalTitle={language.profileBenachrichtigungenScreen.notRecommended}
          message={infoModal.message}
          onClose={() => setInfoModal((prev) => ({ ...prev, show: false }))}
        >
          <Button
            label={language.profileBenachrichtigungenScreen.ok}
            onClick={() => setInfoModal((prev) => ({ ...prev, show: false }))}
          />
        </DefaultModal>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  showResponse,
  updateUserNotificationsRequest,
};

export default ProfileBenachrichtigungen;
