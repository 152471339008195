export const attemptOpenMobileAppLink = ({
  scheme = 'connactApp',
  mobileScreenLink,
}: {
  scheme?: string | 'connactApp';
  mobileScreenLink: string;
}) => {
  if (!scheme || !mobileScreenLink) {
    console.error('Invalid scheme or link provided');
    return;
  }

  const userAgent = navigator.userAgent.toLowerCase();
  const isMobileDevice = /iphone|ipad|android/.test(userAgent);

  if (!isMobileDevice) return;

  try {
    window.location.href = `${scheme}://${mobileScreenLink}`;
  } catch (error) {
    console.error('Error occurred while attempting to open mobile app', error);
  }
};
