import React, { useRef, useState, useEffect } from 'react';
import { Link, Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { withStyles, WithStyles, createStyles, Tab, Tabs } from '@material-ui/core';

import Layout from 'components/UI/Layout';
import ScreenTitle from 'components/Shared/ScreenTitle';

import { useLanguage } from 'languages/languageContext';
import { getConfig } from 'config/config';
import { MentoringInfoScreen } from './MentoringInfoScreen';
import { MentoringPostsScreen } from './MentoringPostsScreen';
import { MentoringProfileScreen } from './Profile/MentoringProfileScreen';
import { MentoringSearchScreen } from './MentoringSearchScreen';
import { useAppDispatch, useAppSelector } from 'store';
import { SelectMentoringState, getMentoringData } from 'store/reducers/mentoring';
import { MentoringRequestsScreen } from './MentoringRequestsScreen';
import { MentoringRequestDetailsScreen } from './components/MentoringRequestDetailsScreen';
import { MentroingProfileDetailsScreen } from './Profile/MentoringProfileDetailsScreen';

const borderColor = process.env.REACT_APP_BUTTON_PRIMARY;

const { theme } = getConfig();
const styles = createStyles({
  container: {
    flexGrow: 1,
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  // title: {
  //     textAlign: 'center'
  // },
  wrapper: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  tabsWrapper: {
    position: 'fixed',
    top: 50,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingBottom: 10,
    zIndex: 10,
    boxShadow: '0 2px 5px 0 #ccc',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  indicator: {
    background: borderColor,
    height: 4,
  },
  link: {
    padding: '10px 30px',
    textDecoration: 'none',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: 20,
  },
  '@media(max-width: 650px)': {
    link: {
      padding: '10px 15px',
      fontSize: 14,
    },
  },
});

function tabProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {}

const Mentoring: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const {
    mentoring: { tabNames },
    mentoringScreen,
  } = useLanguage();
  const container = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [value, setValue] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const { classes, location, history } = props;
  const { path, params } = props.match;
  const { pathname } = location;
  const {
    requests: { incomingRequests, outgoingRequests, mentors },
  } = useAppSelector(SelectMentoringState);
  const uncheckedMentoringRequest = mentors?.filter((el) => !el.isInformed);
  const requestsBadge = incomingRequests?.length || outgoingRequests?.length || uncheckedMentoringRequest.length;

  useEffect(() => {
    dispatch(getMentoringData());
  }, []);

  useEffect(() => {
    if (pathname.includes('/info')) setValue(0);
    if (pathname.includes('/news')) setValue(1);
    if (pathname.includes('/profile')) setValue(2);
    if (pathname.includes('/search')) setValue(3);
    if (pathname.includes('/requests')) setValue(4);
    if (pathname.includes('/mentoring-request')) setValue(5);
    if (pathname.includes('/mentoring-profile')) setValue(5);
  }, [pathname]);

  const setContainerWidthHandler = () => {
    window.addEventListener('resize', setContainerWidthHandler, { passive: false });
    if (container && container.current) {
      setContainerWidth(container.current.offsetWidth);
    }
    return () => window.removeEventListener('resize', setContainerWidthHandler);
  };

  const handleChange = (e: any, value: any) => {
    setValue(value);
  };

  const linkComponent = (route: string, title: string) => (
    <Link to={`${path}${route}`} className={classes.link} style={{ color: theme.TEXT_PRIMARY }}>
      {title}
    </Link>
  );

  useEffect(() => {
    setContainerWidthHandler();
  }, []);

  return (
    <Layout>
      <ScreenTitle title={mentoringScreen.mentoringTitle} />
      <div>
        <div
          style={{
            paddingTop: 80,
            background: theme.BACKGROUND_SECONDARY,
          }}
        >
          <Switch>
            <Route exact path={`${props.match.path}`} render={() => <Redirect to={`${props.match.path}/info`} />} />
            <Route path={`${path}/info`} component={MentoringInfoScreen} />
            <Route exact path={`${path}/news`} component={MentoringPostsScreen} />
            <Route exact path={`${path}/news/:kreiseId`} component={MentoringPostsScreen} />
            <Route path={`${path}/profile`} component={MentoringProfileScreen} />
            <Route path={`${path}/search`} component={MentoringSearchScreen} />
            <Route path={`${path}/requests`} component={MentoringRequestsScreen} />
            <Route path={`${path}/mentoring-request`} component={MentoringRequestDetailsScreen} />
            <Route path={`${path}/mentoring-profile`} component={MentroingProfileDetailsScreen} />
          </Switch>
        </div>
      </div>
      <div className={classes.container} ref={container}>
        {containerWidth && (
          <div style={{ width: containerWidth }}>
            <div className={classes.tabsWrapper} style={{ background: theme.BACKGROUND, width: containerWidth }}>
              <Tabs
                style={{ padding: '5px 0 0' }}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label={mentoringScreen.scrollAuto}
                classes={{
                  indicator: classes.indicator,
                }}
              >
                <Tab label={linkComponent('/info', tabNames.info)} {...tabProps(0)} />
                <Tab label={linkComponent('/news', tabNames.news)} {...tabProps(1)} />
                <Tab label={linkComponent('/profile', tabNames.profile)} {...tabProps(2)} />
                <Tab label={linkComponent('/search', tabNames.search)} {...tabProps(3)} />
                <Tab
                  label={
                    <>
                      {!!requestsBadge && (
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 20,
                            backgroundColor: theme.ERROR_PRIMARY,
                            alignItems: 'center',
                            justifyContent: 'center',
                            left: 15,
                            top: 5,
                            position: 'absolute',
                            display: 'flex',
                          }}
                        >
                          <span
                            style={{
                              fontSize: 10,
                              color: theme.BACKGROUND,
                              fontFamily: 'Roboto',
                            }}
                          >
                            {requestsBadge}
                          </span>
                        </div>
                      )}
                      {linkComponent('/requests', tabNames.request)}
                    </>
                  }
                  {...tabProps(4)}
                />
                {pathname.includes('/mentoring-request') && (
                  <Tab
                    label={linkComponent('/mentoring-request', tabNames.requestDetails)}
                    {...tabProps(5)}
                    onClick={() => history.push('/mentoring/requests')}
                  />
                )}
                {pathname.includes('/mentoring-profile') && (
                  <Tab
                    label={linkComponent('/mentoring-profile', 'Profil-Details')}
                    {...tabProps(5)}
                    onClick={() => history.push('/mentoring/search')}
                  />
                )}
              </Tabs>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default withStyles(styles)(Mentoring);
