import { getUserProfile } from 'api';
import EmptyList from 'components/Shared/EmptyList';
import ScreenTitle from 'components/Shared/ScreenTitle';
import SearchBar from 'components/Shared/SearchBar';
import { IonIcon } from 'components/UI/IonIcon';
import Layout from 'components/UI/Layout';
import { UserListItem } from 'components/UI/UserListItem';
import { getConfig } from 'config/config';
import { useCustomHistory } from 'hooks/useCustomHistory';
import { useLanguage } from 'languages/languageContext';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useAppDispatch, useAppSelector } from 'store';
import { searchRequest } from 'store/reducers/searchSlice';
import { debounce } from 'utilities';

const { theme } = getConfig();

const CreateDialog: FC = () => {
  const dispatch = useAppDispatch();
  const { push } = useCustomHistory();
  const { data, isLoading, count } = useAppSelector((state) => state.search.name);
  const { messages } = useLanguage();

  const [query, setQuery] = useState('');
  const [pageNumber, setPage] = useState(1);

  useEffect(() => {
    getUsers();
    return () => {
      setPage(1);
      setQuery('');
    };
  }, []);

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setQuery(value);
    setPage(1);
    onChangeDebounce(value);
  };

  const onChangeDebounce = useCallback(
    debounce((value: string) => {
      getUsers({ searchkey: value, clear: true, page: 1 });
    }, 500),
    [],
  );

  const toProfileDetailsHandler = (_id: string | number) => {
    push(`/profile-details/${_id}`, { userId: _id });
  };

  const clearSearchHandler = () => setQuery('');

  const getUsers = ({
    clear = false,
    searchkey,
    page,
  }: { clear?: boolean; searchkey?: string; page?: number } = {}) => {
    let finalSearchkey = searchkey !== undefined ? searchkey : query;
    if (!finalSearchkey.trim().length) finalSearchkey = '';
    if (!!count && count === data.length) return;
    dispatch(searchRequest({ filter: 'name', page: page || pageNumber, clear, limit: 10, searchkey: finalSearchkey }));
    setPage((c) => c + 1);
  };

  const handleCreateDialog = async (user) => {
    getUserProfile(user._id)
      .then(({ Data: currentUser }) => {
        push('/dialogs/' + currentUser._id, { receiver: currentUser });
      })
      .catch((err) => console.error('Error create dialog' + err));
  };

  return (
    <Layout>
      <ScreenTitle title={messages.newConversation} />
      <div className="py-8 px-5 w-100">
        <SearchBar query={query} onChange={onChangeHandler} clearSearch={clearSearchHandler} />
        {data?.length > 0 ? (
          <Virtuoso
            style={{ height: '74vh' }}
            data={data}
            totalCount={count}
            endReached={() => getUsers()}
            itemContent={(index, user) => (
              <UserListItem
                name={`${user?.firstname || user?.name} ${user?.lastname}`}
                image={user?.photo}
                description={user?.wohnort}
                corpName={user?.corp[0]?.corpsname}
                key={index}
                onClick={() => toProfileDetailsHandler(user._id)}
                rightBlock={
                  <div
                    className="rounded-full flex items-center justify-items-center p-[6px] self-center cursor-pointer"
                    style={{ background: theme.BUTTON_PRIMARY }}
                    onClick={() => handleCreateDialog(user)}
                  >
                    <IonIcon name="paper-plane-outline" color="#FFF" size={28} style={{ height: 29, width: 29 }} />
                  </div>
                }
                rightBlockStyle="self-center cursor-pointer"
              />
            )}
          />
        ) : (
          !isLoading && <EmptyList />
        )}
      </div>
    </Layout>
  );
};

export default CreateDialog;
