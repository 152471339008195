import { ButtonBase, makeStyles } from '@material-ui/core';
import DefaultModal from 'components/Shared/DefaultModal';
import React, { FC, useState } from 'react';
import moment, { Moment } from 'moment';
import { getConfig } from 'config/config';
import Button from 'components/UI/Button';
import { useLanguage } from 'languages/languageContext';
import { configRRules, TRRule, weekdayArray } from 'utilities/configRRules';
import DatePicker from '../../../components/Shared/DatePicker/DatePicker';
import { useFormik } from 'formik';
import { IonIcon } from 'components/UI/IonIcon';
import { FormInput } from 'components/UI/FormInput';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'components/shadcn/select';

interface IRecurringModalProps {
  setConfig: (state: TRRule) => void;
  onClose: () => void;
  isOpen: boolean;
  startDate: string | Moment;
}

export const frequency = {
  SINGLE: 8,
  DAILY: 3,
  WEEKLY: 2,
  MONTHLY: 1,
  YEARLY: 0,
  CUSTOM: 9,
};

export const RecurringModal: FC<IRecurringModalProps> = ({ setConfig, isOpen, startDate: propsDate, onClose }) => {
  const styles = useStyles({});
  const startDate = moment(propsDate);
  const startDateWeekday = startDate.weekday();
  const nthOfMonth = Math.ceil(startDate.date() / 7);
  const { rrEventLabels, btn, key2: locale } = useLanguage();

  const [showDate, setShowDate] = useState(false);
  const [customRecurringSettings, setCustomRecurringSettings] = useState(false);

  console.log((rrEventLabels.DAILY as any)?.single, 'single');
  const { values, handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched, resetForm } = useFormik({
    initialValues: {
      interval: 1,
      freq: { value: 3, label: (rrEventLabels.DAILY as any)?.single },
      bysetpos: {
        value: 0,
        label: `${rrEventLabels.everyMonthOn} ${startDate.format('D')}.`,
      },
      count: 1,
      endType: 'none',
      until: undefined,
      byweekday: [weekdayArray[startDateWeekday]],
    },
    onSubmit: (values) => {
      setConfig({
        ...values,
        bysetpos: values.bysetpos.value,
        freq: values.freq.value,
      });
      handleClose();
    },
  });

  console.log(values);
  const freqOptions = Object.entries(frequency).map(([vKey, val]) => ({
    vKey: rrEventLabels[vKey],
    val,
  }));
  const bysetpostOptions = [
    {
      vKey: `${rrEventLabels.everyMonthOn} ${startDate.format('D')}.`,
      val: 0,
    },
    nthOfMonth <= 4 && {
      vKey: rrEventLabels?.repeatedMonthlyBySetPos({
        day: rrEventLabels?.[values.byweekday[0]],
        interval: 1,
        bysetpos: nthOfMonth,
      }),
      val: nthOfMonth,
    },
    nthOfMonth >= 4 && {
      vKey: rrEventLabels?.repeatedMonthlyBySetPos({
        day: rrEventLabels?.[values.byweekday[0]],
        interval: 1,
        bysetpos: -1,
      }),
      val: -1,
    },
  ];

  const handleClose = () => {
    setCustomRecurringSettings(false);
    onClose();
  };

  const handlePressOption = (val: number) => {
    if (val === frequency.CUSTOM) {
      return setCustomRecurringSettings(true);
    }

    const frequencyLabel = freqOptions.find((item) => val === item.val)?.vKey?.single;
    resetForm();
    setFieldValue('freq', { value: val, label: frequencyLabel });
    if (val === frequency.WEEKLY) {
      setFieldValue('byweekday', [weekdayArray[startDateWeekday]]);
    }
    handleSubmit();
    handleClose();
  };

  const RenderOption = ({ vKey, val }: { vKey: string; val: number }) => (
    <ButtonBase className={styles.itemWrapper} onClick={() => handlePressOption(val)}>
      <span>{vKey}</span>
    </ButtonBase>
  );

  const RenderWeekdayOption = ({ val }: { val: string }) => {
    console.log('val', val);
    const selected = values?.byweekday?.find((el) => el === val);

    const onClick = () => {
      setFieldValue(
        'byweekday',
        selected ? values?.byweekday.filter((el) => el !== val) : [val].concat(values?.byweekday),
      );
    };
    return (
      <ButtonBase
        className={styles.weekdayBtn}
        style={{ backgroundColor: selected ? theme.ACTIVE_INPUT : undefined }}
        onClick={onClick}
      >
        <span className={styles.weekdayLabel} style={{ color: selected ? '#fff' : undefined }}>
          {rrEventLabels[val].slice(0, 2).toUpperCase()}
        </span>
      </ButtonBase>
    );
  };

  console.log(values.freq.label);

  return (
    <DefaultModal
      showModal={isOpen}
      onClose={handleClose}
      modalWidth={customRecurringSettings ? 400 : 320}
      modalTitle={rrEventLabels.title}
      leftContent={
        customRecurringSettings && (
          <ButtonBase onClick={() => setCustomRecurringSettings(false)}>
            <IonIcon name="arrow-back-outline" size={25} color={theme.BUTTON_PRIMARY} />
          </ButtonBase>
        )
      }
    >
      <div className={styles.wrapper}>
        {!customRecurringSettings ? (
          freqOptions.map((el) => <RenderOption vKey={el.vKey?.regular} val={el.val} key={el.val} />)
        ) : (
          <div className={styles.wrapper} style={{ flexDirection: 'column', marginTop: 10 }}>
            <FormInput
              name="interval"
              placeholder={rrEventLabels.interval}
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.interval}
              error={errors.interval}
              touched={touched.interval}
            />

            <Select
              onValueChange={(value: any) => {
                setFieldValue('freq', {
                  value: value?.val,
                  label: value?.vKey.single,
                });
              }}
              value={values.freq.label}
            >
              <SelectTrigger className="rounded-full h-10 mb-3">
                <SelectValue>{values.freq.label}</SelectValue>
              </SelectTrigger>
              <SelectContent style={{ zIndex: 61 }}>
                <SelectGroup>
                  {freqOptions
                    .filter((el) => el.val !== frequency.SINGLE && el.val !== frequency.CUSTOM)
                    .map((el) => (
                      <SelectItem key={el.val} value={el as any}>
                        {el.vKey?.single}
                      </SelectItem>
                    ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            {values.freq.value === frequency.MONTHLY && (
              <Select
                onValueChange={(value: any) => {
                  setFieldValue('bysetpos', {
                    value: value.val,
                    label: value.vKey,
                  });
                }}
                value={values.bysetpos.label}
              >
                <SelectTrigger className="rounded-full h-10 mb-3">
                  <SelectValue>{values.bysetpos.label}</SelectValue>
                </SelectTrigger>
                <SelectContent style={{ zIndex: 61 }}>
                  <SelectGroup>
                    {bysetpostOptions.filter(Boolean).map((el, idx) => (
                      <SelectItem key={el.val} value={el as any}>
                        {el.vKey}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: 250,
                margin: '10px 0',
                alignItems: 'center',
              }}
            >
              {values.freq.value === frequency.WEEKLY &&
                weekdayArray.map((el) => <RenderWeekdayOption val={el} key={el} />)}
            </div>
            <span className={styles.weekdayLabel} style={{ marginBottom: 10 }}>
              {/* @ts-ignore */}
              {
                configRRules({
                  rules: { ...values, freq: values.freq.value, bysetpos: values.bysetpos.value },
                  labels: rrEventLabels,
                  locale,
                }).recurringLabel
              }
            </span>
            <div className={styles.endTypeWrapper} onClick={() => setFieldValue('endType', 'none')}>
              <div className={styles.radioOut}>{values.endType === 'none' && <div className={styles.radioIn} />}</div>
              <span>{rrEventLabels.neverEnds}</span>
            </div>
            <div className={styles.endTypeWrapper} onClick={() => setFieldValue('endType', 'count')}>
              <div className={styles.radioOut}>{values.endType === 'count' && <div className={styles.radioIn} />}</div>
              <span>{rrEventLabels.after}</span>
              <input
                className={styles.endTypeInput}
                onChange={(e) => {
                  const input = e.target.value.slice(0, 3);
                  const cleanedInput = input.replace(/^(0)(\D*|$)/, '1').replace(/\D/g, '');
                  setFieldValue('count', cleanedInput);
                }}
                value={values.count?.toString()}
                onFocus={() => setFieldValue('endType', 'count')}
                onBlur={() => {
                  if (values.count < 1) setFieldValue('count', 1);
                }}
              />
              <span>{values.count > 1 ? rrEventLabels.times : rrEventLabels.time}</span>
            </div>
            <div
              className={styles.endTypeWrapper}
              onClick={() => {
                setFieldValue('endType', 'until');
                setShowDate(true);
              }}
            >
              <div className={styles.radioOut}>{values.endType === 'until' && <div className={styles.radioIn} />}</div>
              <span>{rrEventLabels.on[0].toUpperCase() + rrEventLabels.on.slice(1)}</span>
              <input
                className={styles.endTypeInput}
                style={{ width: 120 }}
                disabled={true}
                value={moment(values?.until).format('DD.MM.YYYY')}
              />
            </div>
            <Button label={btn.saveBtn} width={250} onClick={handleSubmit} />
          </div>
        )}
      </div>
      <DatePicker
        dateModalIsOpen={showDate}
        dateHandler={(type, date) => setFieldValue('until', date)}
        closeModalHandler={() => setShowDate(false)}
        minimumDate={startDate}
        date={values?.until}
        inputName="startDate"
      />
    </DefaultModal>
  );
};

const { theme } = getConfig();
const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
    justifyContent: 'flex-start',
  },
  itemWrapper: {
    padding: 7,
    borderRadius: 5,
  },
  weekdayBtn: {
    borderRadius: 30,
    width: 30,
    borderColor: theme.ICON_BORDER,
    height: 30,
    borderWidth: 1,
    border: 'solid',
    alignItems: 'center',
  },
  weekdayLabel: {
    fontSize: 13,
    fontFamily: 'Roboto',
    color: theme.TEXT_PRIMARY,
  },
  endTypeWrapper: {
    flexDirection: 'row',
    width: 250,
    alignItems: 'center',
    margin: '10px 0',
    display: 'flex',
    cursor: 'pointer',
  },
  endTypeInput: {
    width: 50,
    height: 30,
    borderColor: theme.BACKGROUND_SECONDARY,
    borderWidth: 1,
    border: 'solid',
    textAlign: 'center',
    borderRadius: 20,
    backgroundColor: theme.BACKGROUND_PRIMARY,
    color: theme.TEXT_PRIMARY,
    margin: '0 5px',
  },
  radioOut: {
    width: 16,
    height: 16,
    borderRadius: 16,
    borderWidth: 1,
    border: 'solid',
    borderColor: theme.ACTIVE_INPUT,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
    display: 'flex',
  },
  radioIn: {
    width: 10,
    height: 10,
    borderRadius: 10,
    backgroundColor: theme.ACTIVE_INPUT,
    display: 'flex',
  },
});
