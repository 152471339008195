import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { WithStyles, withStyles, createStyles } from '@material-ui/styles';
import { ButtonBase } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import 'react-datepicker/dist/react-datepicker.css';

import { withConfig } from '../../../config';
import { DatePickerProps } from '../../model';
import Button from '../../UI/Button';
import { getConfig } from 'config/config';
import { translationsType, useLanguage } from 'languages/languageContext';
import { locales } from 'constants/date-fns-locales';

const { theme } = getConfig();
const styles = createStyles({
  container: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: 100,
    top: 0,
    left: 0,
    background: 'rgba(97, 97, 97, .5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
  },
  buttonWrapper: {
    position: 'absolute',
    right: 20,
    top: 20,
  },
  wrapper: {
    position: 'relative',
    padding: '60px 50px 30px',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: 10,
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    paddingTop: 20,
  },
  backdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
    outline: 'none',
    border: 'none',
  },
  input: {
    border: `1px solid`,
    borderColor: theme.ACTIVE_INPUT + ' !important',
    borderRadius: 8,
    padding: '0.25rem',
    outline: 'none',
  },
  '@media(max-width: 650px)': {
    wrapper: {},
  },
});

interface Props extends WithStyles<typeof styles>, DatePickerProps {
  dateFormat?: string;
  language: translationsType;
}

const DatePickerModal: React.FC<Props> = ({
  date,
  dateModalIsOpen,
  classes,
  theme,
  closeModalHandler,
  dateHandler,
  pickerType = 'day',
  inputName = 'default',
  autoFocus = false,
  dateFormat,
  ...props
}) => {
  const [startDate, setStartDate] = useState(date || new Date());
  const { datePickerComponent, key2 } = useLanguage();

  const locale = locales[key2] || locales.de;

  useEffect(() => {
    if (dateModalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [dateModalIsOpen]);

  return dateModalIsOpen ? (
    <div className={classes.container}>
      <div className={classes.wrapper} style={{ background: theme.BACKGROUND }}>
        {pickerType !== 'day' && (
          <div className={classes.buttonWrapper}>
            <ButtonBase onClick={closeModalHandler} style={{ borderRadius: 50 }}>
              <Close style={{ color: theme.BUTTON_SECONDARY }} />
            </ButtonBase>
          </div>
        )}
        <DatePicker
          className={classes.input}
          locale={locale}
          popperClassName={classes.container}
          selected={startDate}
          onChange={(date) => {
            setStartDate(date);
          }}
          autoFocus={autoFocus}
          dateFormat={dateFormat || 'dd.MM.yyyy'}
          open={false}
          placeholder="DD.MM.YYYY"
        />
        <div
          className={classes.buttonsWrapper}
          style={{ justifyContent: pickerType === 'day' ? 'space-between' : 'center' }}
        >
          {pickerType === 'day' && (
            <Button
              label={datePickerComponent.cancel}
              onClick={closeModalHandler}
              width={110}
              background={theme.BUTTON_SECONDARY}
            />
          )}
          <Button
            label={datePickerComponent.ok}
            onClick={() => {
              const newDate = new Date(date);
              newDate.setFullYear(startDate.getFullYear());
              newDate.setMonth(startDate.getMonth());
              newDate.setDate(startDate.getDate());
              dateHandler(inputName, newDate);
              closeModalHandler();
            }}
            width={pickerType === 'day' ? 110 : 220}
          />
        </div>
      </div>
      <div onClick={closeModalHandler}>
        <div className={classes.backdrop}></div>
      </div>
    </div>
  ) : null;
};

export default withStyles(styles)(withConfig(DatePickerModal));
