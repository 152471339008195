import { makeStyles, Modal } from '@material-ui/core';
import { getConfig } from 'config/config';
import React, { FC, useEffect, useState } from 'react';
import { globalSearchResponseType } from 'store/reducers/globalSearch';
import PdfFileIcon from 'assets/icons/PdfFileIcon';
import DocumentsGalleryItem from 'scenes/Documents/components/DocumentsGalleryItem';
import LightboxGallery from 'components/Shared/ImageLightBox/ImageLightBox';
import Video from 'react-player';
import defaultVideoIcon from '../../../../../assets/defaultVideoIcon.png';
import defaultImageIcon from '../../../../../assets/defaultImageIcon.png';
import { Folder } from '@material-ui/icons';
import { Link } from 'react-router-dom';

type Props = {
  folder?: globalSearchResponseType['folders']['data'][0];
};

export const FolderItem: FC<Props> = (props) => {
  const classes = useStyles({});
  const { folder } = props;

  return (
    <React.Fragment>
      <Link
        to={{ pathname: '/documents', state: { preopenedFolder: { id: folder._id, title: folder.title } } }}
        className={classes.container}
        style={{ borderColor: theme.BACKGROUND_SECONDARY, marginBottom: 10 }}
      >
        <div className={classes.fileWrapper} style={{ borderColor: theme.BACKGROUND_SECONDARY }}>
          <div className={classes.fileWrapper} style={{ height: '100%' }}>
            <div className={classes.imageWrapper}>
              <div className={classes.iconWrapper}>
                <Folder style={{ color: theme.ICON_BORDER, width: 36, height: 36 }} />
              </div>
            </div>
            <div className={classes.title}>
              <span className={classes.name}>{folder.title}</span>
            </div>
          </div>
        </div>
      </Link>
    </React.Fragment>
  );
};

const { theme, config } = getConfig();
const useStyles = makeStyles({
  container: {
    display: 'flex',
    minHeight: 80,
    backgroundColor: theme.BACKGROUND_SECONDARY,
    padding: 10,
    margin: 7,
    alignItems: 'center',
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    textDecoration: 'none',
  },
  imageWrapper: {
    width: 60,
    height: 60,
    borderRadius: 60,
    background: theme.ICON_BACKGROUND,
    borderColor: theme.ICON_BORDER,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 0 20px',
    alignItems: 'flex-start',
    flex: 1,
  },
  name: {
    fontFamily: 'Poppins',
    fontSize: 18,
    fontWeight: 'bold',
    wordBreak: 'break-word',
    textAlign: 'left',
    color: theme.TEXT_PRIMARY,
    marginLeft: 20,
  },
  fileWrapper: {
    width: '100%',
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  iconWrapper: {
    // marginRight: 20,
  },
  title: {
    flex: 5,
    marginRight: 25,
  },
  uploaderName: {
    fontSize: 14,
    fontFamily: 'Roboto',
  },
  date: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontStyle: 'italic',
  },
  fileName: {
    fontSize: 14,
    fontFamily: 'Roboto',
  },
});
