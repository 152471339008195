import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { withStyles, WithStyles, createStyles, ButtonBase } from '@material-ui/core';
import { withConfig, Theme } from '../../../config/';
import { imagePath } from '../../../utilities/';
import { ReactComponent as DeleteIcon } from '../../../assets/delete_button.svg';
import { useAppSelector } from 'store';
import { cn } from 'utilities/utils';
const defaultImg = require('../../../assets/default_avatar.png');

const styles = createStyles({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '10px',
    borderRadius: 10,
    border: '1px solid',
    boxSizing: 'border-box',
    marginTop: 10,
    marginRight: 10,
    cursor: 'pointer',
    zIndex: 0,
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 15,
    border: '1px solid',
    overflow: 'hidden',
  },
  name: {
    fontSize: 16,
    fontFamily: 'Roboto',
  },
  infoWrapper: {},
  nameWrapper: {},
  infoVal: {
    display: 'block',
    fontSize: 16,
    fontFamily: 'Roboto',
  },
  deleteButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: 10,
  },
  buttonWrapper: {
    padding: 10,
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 9999,
    borderRadius: '50%',
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
  theme: Theme;
  member: any;
  onRemoveMember: (c: any) => void;
}

const MemberItem: React.FC<Props> = ({ classes, theme, member, onRemoveMember }) => {
  const { _id } = useAppSelector((state) => state.users.user);
  let image;
  if (member) {
    image = imagePath(null, member.photo);
  }

  return member && member.name ? (
    <div className={classes.container} style={{ borderColor: theme.BACKGROUND_SECONDARY }}>
      {member._id !== _id && (
        <ButtonBase
          onClick={() => onRemoveMember(member)}
          className={cn(classes.buttonWrapper, 'hover:scale-105 duration-100')}
        >
          <div className={classes.deleteButton} style={{ background: theme.ERROR_PRIMARY }}>
            <DeleteIcon />
          </div>
        </ButtonBase>
      )}
      <div className={classes.imageWrapper} style={{ borderColor: theme.BACKGROUND_SECONDARY }}>
        <img src={image || defaultImg} alt=" " style={{ width: '100%' }} />
      </div>
      <div className={classes.infoWrapper}>
        <div className={classes.nameWrapper}>
          <span className={classes.name} style={{ color: theme.BUTTON_PRIMARY }}>
            {member.name} {member.lastname}
          </span>
          {member.status ? (
            <span className={classes.name} style={{ color: theme.BUTTON_PRIMARY }}>
              ({member.status}){' '}
            </span>
          ) : null}
        </div>
        <span className={classes.infoVal} style={{ color: theme.TEXT_SECONDARY }}>
          {member.wohnort}
        </span>
        {member?.corp && (
          <span className={classes.infoVal} style={{ color: theme.TEXT_SECONDARY }}>
            {member.corp[0]?.corpsname}
          </span>
        )}
      </div>
    </div>
  ) : null;
};

export default withStyles(styles)(withRouter(withConfig(MemberItem)));
