import { Box, ButtonBase } from '@material-ui/core';
import moment from 'moment';
import Video from 'react-player';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';

import { getConfig } from 'config/config';
import { AttachmentImage } from 'components/Shared/AttachmentImage';
import { ProgressLoader } from 'components/UI/ProgressLoader';
import { IonIcon } from 'components/UI/IonIcon';
import { useAppDispatch } from 'store';
import { attachmentType, fileType } from 'store/reducers/attachments';
import { mediaActions } from 'store/reducers/mediaSlice';
import { useLanguage } from 'languages/languageContext';
import { ReactComponent as CloseIcon } from '../../../assets/close.svg';

type columnProps = {
  viewType: 'column';
  survey?: {
    showModal: () => void;
    deleteSurvey: () => void;
    surveyAdded: boolean;
  };
};
type rowProps = {
  viewType: 'row';
};

type basicProps = {
  currentAttachments: { documents: fileType[]; images: fileType[]; videos: fileType[] };
  // delete me
  deleteAttachment?: (type: attachmentType, idx: number) => void;
};

type viewProps = columnProps | rowProps;

export const RenderStateAttachments = (props: viewProps & basicProps) => {
  const { currentAttachments, viewType = 'row' } = props;

  const deleteAttachment = (type: 'documents' | 'images' | 'videos', id: number) => {
    dispatch(mediaActions.deleteAttachment({ type, id }));
  };

  const { surveys } = useLanguage();

  const dispatch = useAppDispatch();

  const onSortEnd = (type: attachmentType, oldIndex: number, newIndex: number) => {
    const array = currentAttachments[type];
    if (!array) return;

    dispatch(
      mediaActions.setAttachments({
        type,
        order: arrayMoveImmutable(array, oldIndex, newIndex),
      }),
    );
  };

  const Survey = () =>
    props.viewType === 'column' &&
    !!props?.survey?.surveyAdded && (
      <Box
        style={{
          ...styles.icon,
          marginBottom: 30,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: 100,
        }}
        key={'survey'}
      >
        <Box style={{ ...styles.deleteButtonWrapper, position: 'absolute', left: 60 }}>
          <ButtonBase onClick={props.survey.deleteSurvey}>
            <Box style={{ ...styles.delWrapper, position: 'relative' }}>
              <Box style={{ ...styles.delImageWrapper }}>
                <CloseIcon />
              </Box>
            </Box>
          </ButtonBase>
        </Box>
        <ButtonBase
          onClick={props.survey.showModal}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IonIcon name="podium-outline" size={40} color={theme.ACTIVE_INPUT} style={{ rotate: '90deg' }} />
          <span style={{ color: theme.ACTIVE_INPUT, fontWeight: 'bolder' }}>{surveys.createModalTitle}</span>
        </ButtonBase>
      </Box>
    );

  const DeleteButton = ({ type, idx }: { type: attachmentType; idx: number }) => (
    <button
      onClick={() => deleteAttachment(type, idx)}
      className="absolute z-20 w-6 h-6 flex items-center justify-center bg-gray-500/50 rounded-md top-0 right-[15px] hover:bg-gray-400 hover:scale-105 duration-75 backdrop-blur-sm"
    >
      <IonIcon name="trash" color={theme.ERROR_PRIMARY} />
    </button>
  );

  const MediaDragAndDrop = ({
    items,
    type,
    renderItem,
  }: {
    items: fileType[];
    type: attachmentType;
    renderItem: (file: fileType, idx: number) => JSX.Element;
  }) => (
    <SortableList
      onSortEnd={(oldIndex: number, newIndex: number) => {
        onSortEnd(type, oldIndex, newIndex);
      }}
      className="grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-6 mb-4"
    >
      {items?.map((file, idx) => (
        <SortableItem key={`${type}-${idx}`}>
          <div className="relative select-none cursor-grab opacity-100 z-10">
            <DeleteButton type={type} idx={idx} />
            {renderItem(file, idx)}
            <ProgressLoader loading={!!file.progress} progress={file.progress} />
          </div>
        </SortableItem>
      ))}
    </SortableList>
  );

  const renderDragAndDropDocument = (file: fileType, i: number) => (
    <>
      <AttachmentImage file={file} type={file?.mimetype?.includes('pdf') ? 'document' : 'file'} viewType={viewType} />
      <p style={{ ...styles.name }} className="text-center text-xs break-words">
        {file.fileName}
      </p>
      <div className="absolute bottom-4 right-4 w-4 h-4 rounded-md text-xs bg-slate-500/50 flex items-center justify-center backdrop-blur-sm">
        {i + 1}
      </div>
    </>
  );

  const renderDragAndDropImage = (file: fileType, i: number) => (
    <>
      <AttachmentImage file={file} type="image" viewType={viewType} />
      <div className="absolute bottom-4 right-4 w-4 h-4 rounded-md text-xs bg-slate-500/50 flex items-center justify-center backdrop-blur-sm">
        {i + 1}
      </div>
    </>
  );

  const renderDragAndDropVideo = (file: fileType, i: number) => (
    <>
      <Video
        width={140}
        height={80}
        url={file.renderUrl || file.url}
        style={styles.image}
        controls={false}
        paused={true}
        onDuration={(duration) =>
          dispatch(mediaActions.setUploadingProgress({ type: 'videos', idx: i, file: { duration } }))
        }
      />
      <span className="absolute bottom-4 right-4 text-xs text-white bg-primary-btn rounded-md p-1">
        {moment(file?.duration * 1000 || 0).format('mm:ss')}
      </span>
      <div className="absolute bottom-4 right-4 w-4 h-4 rounded-md text-xs bg-slate-500/50 flex items-center justify-center backdrop-blur-sm">
        {i + 1}
      </div>
    </>
  );

  const RowItem = ({
    file,
    idx,
    type,
    renderMedia,
  }: {
    file: fileType;
    idx: number;
    type: attachmentType;
    renderMedia: () => JSX.Element;
  }) => (
    <Box style={{ ...styles.iconRow, flexDirection: 'row', display: 'flex' }} key={`${type}-${idx}`}>
      <Box style={{ flexDirection: 'row', display: 'flex' }}>
        {renderMedia()}
        <span style={{ ...styles.nameRow, wordWrap: 'break-word', maxWidth: 350 }}>{file.fileName}</span>
      </Box>
      <Box position="relative" top={type === 'videos' ? -8 : 20} left={type === 'documents' ? 7 : 0}>
        <ProgressLoader loading={!!file.progress} progress={file.progress} />
      </Box>
      <ButtonBase style={{ alignSelf: 'center' }} onClick={() => deleteAttachment(type, idx)}>
        <Box style={styles.delImageWrapper}>
          <CloseIcon />
        </Box>
      </ButtonBase>
    </Box>
  );

  const renderRowDocument = (file: fileType) => (
    <AttachmentImage viewType={viewType} type={file?.mimetype?.includes('pdf') ? 'document' : 'file'} file={file} />
  );

  const renderRowImage = (file: fileType) => <AttachmentImage viewType={viewType} type="image" file={file} />;

  const renderRowVideo = (file: fileType, idx: number) => (
    <>
      <Video
        width={40}
        height={40}
        url={file.renderUrl || file.url}
        style={{ ...styles.image, position: 'relative' }}
        controls={false}
        paused={true}
        onDuration={(duration) =>
          dispatch(mediaActions.setUploadingProgress({ type: 'videos', idx, file: { duration } }))
        }
      />
      <span
        style={{
          height: 14,
          fontSize: 11,
          marginTop: 8,
          color: '#fff',
          position: 'relative',
          bottom: -14,
          right: 20,
          backgroundColor: theme.BUTTON_ICON,
          padding: 2,
          borderRadius: 5,
          fontWeight: 'bolder',
        }}
      >
        {moment(file?.duration * 1000 || 0).format('mm:ss')}
      </span>
    </>
  );

  return (
    <Box style={{ ...styles.imagesWrapper, display: 'flex', flexWrap: 'wrap', flexDirection: 'row', width: '100%' }}>
      <Survey />
      {viewType === 'column' ? (
        <>
          <MediaDragAndDrop
            items={currentAttachments?.documents}
            type="documents"
            renderItem={renderDragAndDropDocument}
          />
          <MediaDragAndDrop items={currentAttachments?.images} type="images" renderItem={renderDragAndDropImage} />
          <MediaDragAndDrop items={currentAttachments?.videos} type="videos" renderItem={renderDragAndDropVideo} />
        </>
      ) : (
        <>
          {currentAttachments?.documents?.map((file, i) =>
            RowItem({ file, idx: i, type: 'documents', renderMedia: () => renderRowDocument(file) }),
          )}
          {currentAttachments?.images?.map((file, i) =>
            RowItem({ file, idx: i, type: 'images', renderMedia: () => renderRowImage(file) }),
          )}
          {currentAttachments?.videos?.map((file, i) =>
            RowItem({ file, idx: i, type: 'videos', renderMedia: () => renderRowVideo(file, i) }),
          )}
        </>
      )}
    </Box>
  );
};

const { theme } = getConfig();
const styles = {
  imagesWrapper: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginHorizontal: 2,
  },

  deleteButtonWrapper: {
    zIndex: 1,
    top: -15,
    right: 0,
  },
  delWrapper: {
    padding: 5,
    zIndex: 1,
  },
  delImageWrapper: {
    width: 25,
    height: 25,
    borderRadius: 12.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.BACKGROUND_SECONDARY,
  },
  name: {
    marginTop: 10,
    color: theme.TEXT_PRIMARY,
    maxWidth: 140,
  },
  nameRow: {
    color: theme.TEXT_PRIMARY,
    fontSize: 12,
    alignSelf: 'center',
    marginLeft: 5,
  },
  icon: {
    alignItems: 'center',
    marginHorizontal: '1.6%',
    height: 80,
    marginRight: 15,
  },
  iconRow: {
    width: '100%',
    alignSelf: 'center',
    alignItems: 'flex-start',
    marginHorizontal: '1.6%',
    height: 40,
    marginBottom: 15,
    justifyContent: 'space-between',
  },
  image: {
    backgroundColor: '#ccc',
    width: 140,
    height: 80,
    marginBottom: 15,
    zIndex: 0,
    borderRadius: 5,
  },
};
