import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import it from 'date-fns/locale/it';
import fr from 'date-fns/locale/fr';
import hu from 'date-fns/locale/hu';
import es from 'date-fns/locale/es';
import ko from 'date-fns/locale/ko';
import ja from 'date-fns/locale/ja';
import zh from 'date-fns/locale/zh-CN';
import pl from 'date-fns/locale/pl';
import ru from 'date-fns/locale/ru';

export const locales = {
  de,
  en,
  it,
  fr,
  hu,
  es,
  ko,
  ja,
  zh,
  pl,
  ru,
};
