import { joinEventPart, leaveEventPart, unvoteEventPart, voteEventPart } from 'api/events';
import { IonIcon } from 'components/UI/IonIcon';
import { useCustomHistory } from 'hooks/useCustomHistory';
import { useLanguage } from 'languages/languageContext';
import moment from 'moment';
import { FC, Fragment, useCallback, useState } from 'react';
import { TEventPart } from 'scenes/CreateEvent/components/EventParts/EventPart';
import { useAppDispatch, useAppSelector } from 'store';
import { getEventByIdSuccess } from 'store/actions/events';
import { hideResponse, showResponse } from 'store/actions/response';
import { cn } from 'utilities/utils';
import FreeTextInput from './FreeTextInput';
import { ReactComponent as ClockIcon } from '../../../assets/clock.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/calendar.svg';
import { getConfig } from 'config/config';
import { Button } from 'components/shadcn/button';
import AlertModal from 'components/Shared/AlertModal';
import { FormInput } from 'components/UI/FormInput';
import DefaultModal from 'components/Shared/DefaultModal';
import { Checkbox } from 'components/shadcn/checkbox';
import { Label } from '@radix-ui/react-label';

const { theme } = getConfig();

const EventPart: FC<
  TEventPart & { _id: string; members: { id: string; _id: string; acceptedAt: string }[]; isCreator: boolean }
> = (props) => {
  const { title, start, end, location, description, _id, members, waitList, questions, price, maxMembers, isCreator } =
    props;
  const {
    eventLabels,
    btn,
    global,
    privacy: { restrictions },
  } = useLanguage();
  const dispatch = useAppDispatch();
  const { push } = useCustomHistory();

  const { user } = useAppSelector((state) => state.users);
  const userId = user._id;
  const event = useAppSelector((state) => state.events.currentEvent);
  const [showEventPartGuestModal, setShowEventPartGuestModal] = useState(false);
  // const [guestForEventPart, setGuestForEvent] = useState({
  //   name: '',
  //   lastname: '',
  //   email: '',
  //   price: {}
  // });

  const isJoined = members.some((member) => member.id._id === userId);
  const isOnWaitList = waitList.some((member) => member.user._id === userId);
  const maxParticipantsAchieved = maxMembers !== 0 && maxMembers <= members?.length;

  const acceptEventBtn = useCallback(() => {
    if (isOnWaitList) {
      return eventLabels.removeFromWaitList;
    }

    if (event?.registration?.type !== 'app') {
      return isJoined ? eventLabels.notInterested : eventLabels.interested;
    }
    if (isJoined) {
      return btn.cancelEventBtn;
    }

    return btn.acceptBtn;
  }, [isOnWaitList, isJoined]);

  const sendJoinRequest = async () => {
    let res;
    if (!(isJoined || isOnWaitList)) res = await joinEventPart(_id);
    else res = await leaveEventPart(_id);

    delete res?.organizers;
    await dispatch(getEventByIdSuccess({ ...event, ...res }));
  };

  const handleJoin = async () => {
    if (maxParticipantsAchieved && !(isOnWaitList || isJoined)) {
      dispatch(
        showResponse({
          title: eventLabels.waitlist,
          message: eventLabels.limitAchieved(maxMembers),
          cancelBtnLabel: btn.ok,
        }),
      );
    }

    if (maxParticipantsAchieved && (isOnWaitList || isJoined)) {
      dispatch(
        showResponse({
          title: eventLabels.leaveWaitlist,
          message: eventLabels.leaveWaitlistDesc,
          options: [
            <Button
              className="w-[220px] bg-primary-btn self-center mb-2"
              onClick={async () => {
                await sendJoinRequest();
                dispatch(hideResponse());
              }}
            >
              {btn.yesUnderstood}
            </Button>,
          ],
        }),
      );
      return;
    }

    await sendJoinRequest();
  };

  return (
    <div className={cn('flex flex-row rounded-sm')}>
      <div className={cn('rounded-l-sm w-2 bg-slate-200 duration-200', isJoined && 'bg-primary-btn/80')} />
      <div className="w-full pl-2 flex flex-col">
        <div className="flex flex-row">
          <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.titleLabel}:</div>
          <span className="text-primary-txt font-semibold">{title}</span>
        </div>
        <div className="flex flex-row">
          <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.descLabel}:</div>
          <span className="text-primary-txt font-medium">{description}</span>
        </div>
        <div className="flex flex-row">
          <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.locationLabel}:</div>
          <span className="text-primary-txt font-medium">{location}</span>
        </div>
        <div className="flex flex-row items-center">
          <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.eventStartLabel}:</div>
          <CalendarIcon />
          <span className="text-primary-txt font-medium ml-1 mr-5">{moment(start).format('DD.MM.YYYY')}</span>
          <ClockIcon />
          <span className="text-primary-txt font-medium ml-1">{moment(start).format('HH:mm')}</span>
        </div>
        <div className="flex flex-row items-center">
          <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.eventEndLabel}:</div>
          <CalendarIcon />
          <span className="text-primary-txt font-medium ml-1 mr-5">{moment(end).format('DD.MM.YYYY')}</span>
          <ClockIcon />
          <span className="text-primary-txt font-medium ml-1">{moment(end).format('HH:mm')}</span>
        </div>
        {!!maxMembers && (
          <div className="flex flex-row">
            <div className="text-secondary-txt w-[125px] mb-1">{eventLabels.participantLabel}:</div>{' '}
            <span className="text-primary-txt font-medium">
              {members.length} / {maxMembers}
            </span>
          </div>
        )}
        {!!price && (
          <div className="flex flex-row">
            <div className="text-secondary-txt w-[125px] mb-1">{global.price}</div>
            <span className="text-primary-txt font-medium">
              {price} {global.eur}
            </span>
          </div>
        )}
        {questions?.map((question) => {
          // used onlu when type === 'single'
          const votedAny =
            question.type === 'single' && question.options.find((el) => el.voters.some((item) => item.user === userId));

          const myFreeAnswer =
            question.type === 'freeText' && question.options.find((opt) => opt.voters[0]?.user === userId);

          const freeOpt =
            question.type === 'freeText'
              ? {
                  questionId: question._id,
                  answerId: myFreeAnswer?._id,
                  value: myFreeAnswer?.title,
                }
              : {};

          return (
            <div className="my-2" key={question._id}>
              <span className="font-semibold block">{question.text}</span>
              <span className="text-txt-secondary text-sm">{eventLabels.parts[question.type]}</span>
              <div className="mt-2">
                {question.type === 'freeText' && (
                  <>
                    <FreeTextInput
                      key={freeOpt.answerId}
                      partId={_id}
                      answerId={freeOpt.answerId}
                      event={event}
                      value={freeOpt.value}
                    />
                    {isCreator && (
                      <div>
                        <div className="flex flex-row items-center">
                          <h4 className="font-medium inline mr-2">{eventLabels.parts.allAnswers}</h4>
                          <IonIcon name="information-circle-outline" color={theme.TEXT_SECONDARY} />
                          <span className="inline text-xs text-secondary-txt w-fit">
                            {eventLabels.parts.visibleForCreators}
                          </span>
                        </div>
                        <ul>
                          {question?.options?.map((opt) => {
                            return (
                              <li key={opt._id} className="text-sm text-secondary-txt">
                                {opt.title}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </>
                )}
                {question.options.map((option) => {
                  if (question.type === 'freeText') return <></>;
                  const checked = option.voters.some((el) => el.user === userId);
                  return (
                    <Fragment key={option._id}>
                      <button
                        onClick={async () => {
                          let res;
                          if (votedAny?._id && !checked) {
                            await unvoteEventPart(event._id, votedAny._id);
                            res = await voteEventPart(event._id, option._id);
                          } else if (checked) {
                            res = await unvoteEventPart(event._id, option._id);
                          } else if (!checked) {
                            res = await voteEventPart(event._id, option._id);
                          }
                          delete res?.organizers;
                          dispatch(getEventByIdSuccess({ ...event, ...res }));
                        }}
                        className="mb-2 w-full text-left hover:opacity-50 flex flex-row flex-1"
                      >
                        <div
                          className={cn(
                            'w-[24px] h-[24px] bg-slate-200 rounded-full mr-2 self-end border border-slate-300 items-center justify-center flex',
                            checked && 'bg-primary-btn',
                          )}
                        >
                          {checked && <IonIcon name="checkmark" color="white" size={20} />}
                        </div>

                        <span>{option.title}</span>
                      </button>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          );
        })}
        <div>
          <button
            className={cn(
              'w-fit px-3 py-1 rounded-md bg-primary-btn text-white hover:opacity-50 mt-4 duration-200',
              (isJoined || isOnWaitList) && 'bg-red-500',
            )}
            onClick={handleJoin}
          >
            {acceptEventBtn()}
          </button>
          {/* {!!props?.priceList.length && (
            <button
              className={cn(
                'w-fit px-3 py-1 ml-2 rounded-md bg-primary-btn text-white hover:opacity-50 mt-4 duration-200',
                (isJoined || isOnWaitList) && 'bg-red-500',
              )}
              onClick={() => setShowEventPartGuestModal(true)}
            >
              {eventLabels.linkLabel}
            </button>
          )} */}
          {isCreator && (
            <button
              className={cn('w-fit px-3 py-1 rounded-md text-secondary-btn  mt-4 ml-4 duration-200 hover:bg-slate-100')}
              onClick={() => {
                if (user?.isGuest) {
                  dispatch(
                    showResponse({
                      message: restrictions.participantsList,
                    }),
                  );
                  return;
                }
                push('/parts-participants/' + event._id, { event, preunfolded: _id });
              }}
            >
              {eventLabels.parts.showMembers} ({members?.length || 0})
            </button>
          )}
        </div>
      </div>
      <DefaultModal
        showModal={showEventPartGuestModal}
        onClose={() => setShowEventPartGuestModal(false)}
        modalTitle={'title'}
      >
        <div className="px-6 py-2">
          <FormInput name={`first name`} placeholder={`first name`} onChange={console.log} value={title} />
          <FormInput name={`last name`} placeholder={`last name`} onChange={console.log} value={title} />
          <FormInput name={`email`} placeholder={`email`} onChange={console.log} value={title} />
        </div>
      </DefaultModal>
    </div>
  );
};

export default EventPart;
