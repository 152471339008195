export function generateStrongPassword(length = 12): string {
	// Define character sets
	const lowercaseLetters = 'abcdefghijklmnopqrstuvwxyz'
	const uppercaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
	const numbers = '0123456789'
	const symbols = '!@#$%^&*()_+~`|}{[]:;?><,./-='

	// Combine character sets
	const charset = lowercaseLetters + uppercaseLetters + numbers + symbols

	// Generate at least one of each character type
	let password = ''
	password += getRandomChar(lowercaseLetters)
	password += getRandomChar(uppercaseLetters)
	password += getRandomChar(numbers)
	password += getRandomChar(symbols)

	// Fill up the rest of the password
	for (let i = password.length; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * charset.length)
		password += charset.charAt(randomIndex)
	}

	return password
}

function getRandomChar(charset: string) {
	const randomIndex = Math.floor(Math.random() * charset.length)
	return charset.charAt(randomIndex)
}
